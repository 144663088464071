<template>
  <div class="question-container">
    <div>
      <div
        class="title"
      >
        {{ question }}
      </div>
      <div
        class="description"
      >
        {{ description }}
      </div>
    </div>
    <b-form-group
      class="question-responses checkboxes"
    >
      <b-form-checkbox-group
        id="checkbox-group-2"
        v-model="mutableSelect"
        name="flavour-2"
        class="checkboxes"
      >
        <div
          v-for="(choice, index) in choices"
          :key="question+''+index"
          :class="mutableSelect && mutableSelect.includes(choice.value) ? 'choice selected' : 'choice'"
        >
          <b-form-checkbox
            v-if="mutableSelect && mutableSelect.includes(choice.value)"
            class="checkbox-tooltip"
            :value="choice.value"
            :disabled="notEnabled"
          >
            {{ choice.label }}
            <div
              v-if="choice.tooltip && choice.tooltip.length > 0"
              class="buttons m-0 p-0"
            >
              <div
                :id="`tooltip-target-${index}`"
                class="button sm outlined no-border no-bg primary m-0 p-0"
              >
                <b-icon icon="info-circle" />
              </div>
            </div>
          </b-form-checkbox>
          <b-form-checkbox
            v-else
            :value="choice.value"
            class="checkbox-tooltip"
            :disabled="notEnabled"
          >
            {{ choice.label }}
            <div
              v-if="choice.tooltip && choice.tooltip.length > 0"
              class="buttons m-0 p-0"
            >
              <div
                :id="`tooltip-target-${index}`"
                class="button sm outlined no-border no-bg m-0 p-0"
              >
                <b-icon icon="info-circle" />
              </div>
            </div>
          </b-form-checkbox>
          <b-tooltip
            v-if="choice.tooltip && choice.tooltip.length > 0"
            :target="`tooltip-target-${index}`"
            triggers="hover"
            variant="secondary"
          >
            <p
              v-for="(desc, k) in choice.tooltip.split('\n')"
              :key="k"
              class="m-2"
            >
              <span
                class="tooltip-color-light"
                v-html="desc"
              />
            </p>
          </b-tooltip>
        </div>
      </b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'Multichoice',
  props: {
    question: String,
    description: String,
    choices: Array,
    onClick: Function,
    selected: Array,
    notEnabled: Boolean,
    responseGetted: Boolean,
  },
  data() {
    return {
      preventPOST: false,
      mutableSelect: this.selected,
    };
  },
  watch: {
    mutableSelect(newValue) {
      if (!this.preventPOST) {
        this.onClick(newValue);
      }
      this.preventPOST = false;
    },
    selected(newValue) {
      this.preventPOST = true;
      this.mutableSelect = newValue;
    },
  },
};
</script>

<style lang="scss">
  .checkbox-tooltip.custom-control.custom-control-inline.custom-checkbox .custom-control-label {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:  space-between;
  }
  .tooltip-color-light a {
    text-decoration: none;
    color: #ffffff !important;
  }
</style>
