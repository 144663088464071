<template>
  <b-container
    class="testimonials-container"
    fluid
  >
    <b-row>
      <b-col>
        <b-container>
          <b-overlay
            :show="!loaded"
            opacity="0"
            blur="none"
            style="min-height: 20vw;"
          >
            <b-row>
              <b-col
                cols="12"
                class="testimonials-title"
              >
                Testemunhos
              </b-col>

              <b-col
                cols="12"
                sm="12"
                md="5"
                class="list mb-5"
              >
                <div
                  v-for="(testimonialItem, i) in testimonials"
                  v-if="i < multiple * 3 && i >= multiple * 3 - 3"
                  :key="i"
                  :class="testimonialItem.id === active && 'active'"
                  class="testimonials-card"
                  @click="setActive(testimonialItem.id)"
                >
                  <div
                    class="author"
                  >
                    <img
                      :src="`${testimonialItem.image}`"
                    >
                    <div class="description">
                      <div class="name">
                        {{ testimonialItem.author }}
                      </div>
                      <div class="company">
                       <!--  <a
                          :href="testimonial.company_link"
                          target="_blanck"
                        > -->{{ testimonialItem.company }}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="(multiple * 3 >= testimonials.length) && multiple > 1 && testimonials.length > 3"
                  class="text-center drop"
                  @click="decreaseMultiple()"
                >
                  <b-icon
                    icon="chevron-right"
                    rotate="-90"
                    scale="2"
                  />
                </div>
                <div
                  v-if="!(multiple * 3 >= testimonials.length)"
                  class="text-center drop"
                  @click="increaseMultiple()"
                >
                  <b-icon
                    icon="chevron-right"
                    rotate="90"
                    scale="2"
                  />
                </div>
              </b-col>
              <b-col
                v-if="testimonial"
                cols="12"
                sm="12"
                md="7"
              >
                <div class="testimonials-emphasis">
                  “{{ testimonial.emphasis }}”
                </div>
                <div class="testimonials-text">
                  <p
                    v-for="(paragraph, k) in testimonial.text.split('\n')"
                    :key="k"
                  >
                    {{ k === 0 ? "“" : "" }}{{ paragraph }}{{ k === testimonial.text.split('\n').length - 1 ? "”" : "" }}
                  </p>
                </div>
                <div class="testimonials-author">
                  {{ testimonial.author }}

                  <a
                    :href="testimonial.company_link"
                    target="_blanck"
                  ><img
                    :src="`${testimonial.company_image}`"
                  ></a>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { get } from '../../services/api';

export default {
  name: 'Testimonials',
  data() {
    return {
      testimonials: [],
      testimonial: null,
      active: -1,
      multiple: 1,
      loaded: false,
    };
  },
  watch: {
    active(value) {
      if (value !== -1) {
        this.testimonial = this.testimonials.find((e) => e.id === parseInt(value, 10));
      }
    },
  },
  async created() {
    await get('testimonials').then((response) => {
      this.testimonials = response.data;
      if (this.testimonials.length > 0) {
        if (this.$route.params.id) {
          this.active = parseInt(this.$route.params.id, 10);
        } else {
          this.active = this.testimonials[0].id;
        }
      }
    });
    this.loaded = true;
  },
  methods: {
    setActive(id) {
      this.active = id;
    },
    increaseMultiple() {
      if (!(this.multiple * 3 >= this.testimonials.length)) {
        this.multiple += 1;
      }
    },
    decreaseMultiple() {
      if ((this.multiple * 3 >= this.testimonials.length) && this.multiple > 1) {
        this.multiple -= 1;
      }
    },
  },
};
</script>
