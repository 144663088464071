import { create } from 'apisauce';
import router from '../../router';
import store from '../../store';

require('dotenv').config();

const api = create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

api.addAsyncResponseTransform(async (response) => {
  if (response.headers.authorization) {
    localStorage.setItem('backend-auth-token', response.headers.authorization.replace('Bearer ', ''));
  }
  if (!response.ok) {
    if (response?.data && response?.data?.message && response.data.message === 'Token has expired') {
      /* const headers = {
        'Content-Type': 'application/json',
      };
      if (localStorage.getItem('backend-auth-token')) {
        headers.Authorization = `Bearer ${localStorage.getItem('backend-auth-token')}`;
      }
      const temp = await api
        .post('auth/refresh', {}, {
          headers,
        })
        .then(async (responseRefresh) => {
          localStorage.setItem('backend-auth-token', responseRefresh.data.access_token);
          const newConfig = response.config;
          newConfig.headers.Authorization = `Bearer ${responseRefresh.data.access_token}`;
          const newResponse = await api.axiosInstance.request(newConfig);
          return newResponse;
        })
        .catch((errorRefresh) => {
          store.commit('logout');
          router.push({ name: 'auth' });
          throw errorRefresh;
        });
      response.status = temp.status;
      response.data = temp.data;
      response.config = temp.config;
      response.headers = temp.headers;
      response.ok = true;
      return temp; */

      store.commit('logout');
      router.push({ name: 'auth' });
    }
    throw response;
  }
});

async function getFile(endpoint, params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (localStorage.getItem('backend-auth-token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('backend-auth-token')}`;
  }
  const request = await api
    .get(endpoint, JSON.stringify(params), {
      responseType: 'blob',
      headers,
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
  return request;
}

async function postFile(endpoint, params) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  if (localStorage.getItem('backend-auth-token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('backend-auth-token')}`;
  }
  const request = await api
    .post(endpoint, params, {
      headers,
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
  return request;
}

async function post(endpoint, params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (localStorage.getItem('backend-auth-token') && !endpoint.includes('reset-password')) {
    headers.Authorization = `Bearer ${localStorage.getItem('backend-auth-token')}`;
  }
  const request = await api
    .post(endpoint, JSON.stringify(params), {
      headers,
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
  return request;
}

async function get(endpoint, params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (localStorage.getItem('backend-auth-token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('backend-auth-token')}`;
  }
  const request = await api
    .get(endpoint, params, {
      headers,
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
  return request;
}

async function put(endpoint, params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (localStorage.getItem('backend-auth-token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('backend-auth-token')}`;
  }
  const request = await api
    .put(endpoint, JSON.stringify(params), {
      headers,
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
  return request;
}

async function remove(endpoint, params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (localStorage.getItem('backend-auth-token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('backend-auth-token')}`;
  }
  const request = await api
    .delete(endpoint, params, {
      headers,
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
  return request;
}

export {
  post, get, put, remove, postFile, getFile,
};
