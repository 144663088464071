<template>
  <div
    class="resource-link"
    :style="thumbnail ? 'padding: 0; background-color: transparent; height: 100%' : ''"
  >
    <div
      class="link-thumbnail"
    >
      <img
        :width="thumbnail ? '100%' : '90%'"
        :height="thumbnail ? '100%' : 'auto'"
        :style="thumbnail ? 'border-radius: 8px;' : ''"
        :alt="resource.label"
        :src="api+'storage/'+resource.thumbnail"
        @click="() => {
          if(resource.link != null) {
            open()
          }
        }"
      >
      <div
        v-if="resource.link != null"
        class="link-button"
        @click="open"
      >
        <b-icon
          icon="link"
          scale="2"
        />
      </div>
    </div>
    <div
      v-if="!thumbnail"
      class="label"
    >
      {{ resource.label }}
    </div>
  </div>
</template>
<script>
import mime from 'mime';
import { post } from '../../../services/api';

export default {
  name: 'Link',
  props: {
    resource: {
      type: Object,
      required: true,
    },
    setLoading: {
      type: Function,
      default: (value) => {
        this.loading = value;
      },
    },
    thumbnail: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      api: process.env.VUE_APP_BACKEND_URL.split('api/')[0],
    };
  },
  methods: {
    getImageFrom(name, base64) {
      return `data:${mime.getType(name)};base64,${base64}`;
    },
    open() {
      post('results/analytics', {
        type: 'resource',
        result: this.resource.resource_type.code,
        event: 'open',
        resource: this.resource.id,
        ip: null,
        user: this.$store.state.auth.loggedIn ? this.$store.state.auth.user.id : null,
      });
      const url = this.resource.link.match(/^https?:/) ? this.resource.link : `//${this.resource.link}`;
      window.open(url, '_blank');
    },
  },
};
</script>
