<template>
  <div class="dashboard">
    <dashboard-header
      :title="links.find(e => e.state === state).label"
    />
    <div
      v-if="links && state"
      class="dashboard-main"
    >
      <c-sidebar
        id="sidebar"
        :state="state"
        :set-state="setState"
        :links="links"
      />
      <c-content
        v-if="loaded"
        :sidebar-height="sidebarHeight"
        :set-state="setState"
        :state="state"
        :links="links"
      />
    </div>
    <b-modal
      id="showModalVATID"
      centered
      hide-footer
      header-class="border-bottom-0"
      footer-class="border-top-0"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
    >
      <div class="title">
        Com o objetivo de melhorar os serviços, necessitamos da indicação do NIF da sua empresa para completar o perfil.
      </div>
      <div
        v-if="error || alreadyExists || success"
        class="feedback-row "
      >
        <div
          v-if="error"
          class="error-danger"
        >
          Ocorreu um erro, por favor tente submeter a alteração mais tarde!
        </div>
        <div
          v-if="alreadyExists"
          class="error-danger"
        >
          Já existe uma empresa registada com este nif!
        </div>

        <div style="display: flex; flex-direction: column;">
          <div
            v-if="success"
            class="error-success"
          >
            O NIF da sua empresa foi atualizado com sucesso
          </div>
          <div class="buttons">
            <div
              v-if="success"
              class="button primary mt-2"
            >
              <a
                @click="$bvModal.hide('showModalVATID')"
              >Fechar</a>
            </div>
          </div>
        </div>
      </div>
      <b-form
        style="text-align: left;"
        @submit="onSubmitVatID"
      >
        <b-form-group
          id="input-group-6"
          label="NIF *"
          class="hide-arrows"
          label-for="input-6"
        >
          <b-form-input
            id="input-6"
            v-model.number="nif"
            required
            placeholder="NIF da empresa"
            :state="vatIDValid"
            :formatter="maxLength"
            number
            @input="value => validateVATID(value)"
          />
        </b-form-group>
        <div>* Campo de preenchimento obrigatório</div>
        <div
          v-if="vatIDValid"
          class="buttons justify-content-start"
        >
          <button
            type="submit"
            variant="primary"
            class="button primary "
          >
            Definir NIF
          </button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from '../../ui/Sidebar.vue';
import Content from '../../ui/Content.vue';
import DashboardHeader from '../../ui/DashboardHeader.vue';
import { get, post, put } from '../../../services/api';

export default {
  name: 'Dasboard',
  components: {
    'c-sidebar': Sidebar,
    'c-content': Content,
    DashboardHeader,
  },
  data() {
    return {
      vatIDValid: null,
      sidebarHeight: 0,
      windowWidth: 0,
      isFacilitator: false,
      loaded: false,
      links: [
        {
          label: 'Dashboard',
          icon: 'grid',
          state: 1,
          component: 'DashboardHome',
          route: 'dashboard',
          condition: true,
        },
        {
          label: 'Questionários',
          icon: 'ui-checks',
          state: 2,
          component: 'DashboardQuizzes',
          condition: true,
          route: 'quizzes',
        },
        {
          label: 'Relatórios',
          icon: 'bar-chart',
          state: 3,
          component: 'DashboardReports',
          condition: true,
          route: 'reports',

        },
        {
          label: 'Minha empresa',
          icon: 'briefcase',
          state: 4,
          component: 'DashboardCompany',
          condition: true,
          route: 'dashboard-company',

        },
        {
          label: 'Meu perfil',
          icon: 'person',
          state: 5,
          component: 'DashboardUser',
          condition: true,
          route: 'profile',
        },
        {
          label: 'Documentos',
          icon: 'files',
          state: 6,
          component: 'DashboardDocuments',
          condition: true,
          route: 'documents',

        },
        {
          label: 'FAQs',
          icon: 'question-circle',
          state: 9,
          component: 'DashboardFaq',
          condition: true,
          route: 'faq',
        },
        {
          label: 'Área de facilitador',
          icon: 'people',
          state: 8,
          component: 'DashboardFacilitator',
          condition: async () => {
            const response = await this.getConditionFacilitator();
            return response;
          },
          route: 'facilitator',
        },
        {
          label: 'Administração',
          icon: 'gear',
          state: 7,
          component: 'DashboardAdmin',
          condition: this.$store.getters.state.user && this.$store.getters.state.user.permission_id === 1,
          route: 'admin',
        },

      ],
      nif: '',
      alreadyExists: false,
      success: false,
      error: false,
    };
  },
  computed: {
    ...mapGetters('dashboard', ['state']),
  },
  /* created() {
    if (!this.$store.getters.state.loggedIn) {
      this.$router.push({ name: 'auth' });
    }
  }, */
  watch: {
    state: {
      handler() {
        this.onResize();
      },
      immediate: true,
    },
    links: {
      handler() {
        this.onResize();
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  created() {
    if (this.$store.getters.user && this.$store.getters.user.permission_id === 1) {
      get(`/company/${this.$store.getters.user.company_id}`).then((response) => {
        if (response.data.company.vatID === null || response.data.company.vatID.trim().length === 0) {
          this.$bvModal.show('showModalVATID');
        }
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    maxLength(value) {
      return value.slice(0, 9);
    },
    validateVATID(value) {
      const nif = typeof value === 'string' ? value : value.toString();
      const validationSets = {
        one: ['1', '2', '3', '5', '6', '8'],
        two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99'],
      };

      const total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
      const modulo11 = (Number(total) % 11);

      const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;
      this.vatIDValid = checkDigit === Number(nif[8]) && nif.length === 9 && validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2));
    },
    onSubmitVatID(evt) {
      evt.preventDefault();
      this.alreadyExists = false;
      this.success = false;
      this.error = false;

      put(`/company/${this.$store.getters.user.company_id}`, { vatID: this.nif.toString() })
        .then((response) => {
          this.alreadyExists = false;
          this.success = true;
          this.error = false;
        })
        .catch((error) => {
          if (error.data.vatID[0] === 'The vat i d has already been taken.') {
            this.alreadyExists = true;
            this.success = false;
            this.error = false;
          } else {
            this.error = true;
          }
        });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      setTimeout(() => {
        if (this.windowWidth < 1200 && document.getElementById('sidebar')) {
          this.sidebarHeight = document.getElementById('sidebar').offsetHeight + 16;
        } else {
          this.sidebarHeight = 0;
        }
        this.loaded = true;
      }, document.getElementById('sidebar') ? 1 : 1000);
    },
    async getConditionFacilitator() {
      if (this.$store.getters.state.user) {
        await get(`facilitator/${this.$store.getters.state.user.id}`).then((response) => {
          if (parseInt(response.data.state, 10) === 2) {
            this.isFacilitator = true;
          }
        });
        return this.isFacilitator;
      } return false;
    },
    setState(state) {
      this.$store.dispatch('dashboard/setState', state);
      const link = this.links.find((el) => el.state === state);
      this.$router.push({ name: link.route });
    },
  },

};
</script>
