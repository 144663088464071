export default {
  state: {
    loggedIn: JSON.parse(localStorage.getItem('loggedIn')) || null,
    user: JSON.parse(localStorage.getItem('user')) || null,
    configs: JSON.parse(localStorage.getItem('configs')) || null,
    authToken: localStorage.getItem('backend-auth-token') || null,
    resetEmail: localStorage.getItem('reset-email') || null,
    ipAddress: null,
  },
  actions: {
    setConfigs({ commit }, state) {
      commit('setConfigs', state);
    },
  },
  mutations: {
    setConfigs(state, data) {
      state.configs = data;

      localStorage.setItem('configs', JSON.stringify(data));
    },
    setIpAddress(state, data) {
      state.ipAddress = data;
    },
    setLoggedIn(state, data) {
      state.authToken = data.token;
      state.user = data.user;
      state.loggedIn = true;
      localStorage.setItem('backend-auth-token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('loggedIn', true);
    },
    setResetEmail(state, data) {
      localStorage.setItem('reset-email', data.resetEmail);
      state.resetEmail = data.resetEmail;
    },
    logout(state) {
      state.authToken = null;
      state.user = null;
      state.loggedIn = null;
      localStorage.setItem('backend-auth-token', null);
      localStorage.setItem('user', null);
      localStorage.setItem('loggedIn', null);
    },
    resetPermissions(state) {
      state.authToken = null;
      state.user = null;
      state.loggedIn = null;
      localStorage.setItem('backend-auth-token', null);
      localStorage.setItem('user', null);
      localStorage.setItem('loggedIn', null);
    },
  },
  getters: {
    state: (state) => ({
      token: state.authToken,
      user: state.user,
      loggedIn: state.loggedIn,
    }),
    resetEmail: (state) => state.resetEmail,
    user: (state) => state.user,
    configs: (state) => state.configs,
    ipAddress: (state) => state.ipAddress,
  },
};
