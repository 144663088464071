<template>
  <div class="question-container">
    <div>
      <div
        class="title"
      >
        {{ question }}
      </div>
      <div
        class="description"
      >
        {{ description }}
      </div>
    </div>
    <b-form-group
      class="question-responses radios"
    >
      <div
        v-for="choice in choices"
        :key="question+''+choice.value"
        :class="parseInt(mutableSelect) === parseInt(choice.value) ? 'choice selected' : 'choice'"
      >
        <b-form-radio
          v-if="parseInt(mutableSelect) === parseInt(choice.value)"
          v-model="mutableSelect"
          :name="choice.label"
          :value="choice.value"
          :disabled="notEnabled"
        >
          {{ choice.label }}
        </b-form-radio>

        <b-form-radio
          v-else
          v-model="mutableSelect"
          :name="choice.label"
          :value="choice.value"
          :disabled="notEnabled"
        >
          {{ choice.label }}
        </b-form-radio>
      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'Choice',
  props: {
    question: String,
    description: String,
    choices: Array,
    onClick: Function,
    selected: String,
    notEnabled: Boolean,
    responseGetted: Boolean,
  },
  data() {
    return {
      preventPOST: false,
      mutableSelect: this.selected,
    };
  },
  watch: {
    mutableSelect(newValue) {
      if (!this.preventPOST) {
        this.onClick(newValue);
      }
      this.preventPOST = false;
    },
    selected(newValue) {
      this.preventPOST = true;
      this.mutableSelect = newValue;
    },
  },

};
</script>
