import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import ElementUI from 'element-ui';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCar, faHeartbeat, faChargingStation, faPiggyBank, faLandmark, faLaptop, faTruck, faIndustry, faKey, faShoppingBasket, faChevronRight, faChevronLeft, faPlus, faMinus, faAngleDown, faAngleUp, faTractor, faHardHat, faFaucet, faHammer, faWrench, faHotel, faTty, faHome, faFlask, faHandsHelping, faShieldAlt, faUniversity, faTaxi, faUsers, faGlobe, faPaintBrush, faUser, faArchive,
} from '@fortawesome/free-solid-svg-icons';
import VueYoutube from 'vue-youtube';
import VueCoreVideoPlayer from 'vue-core-video-player';
import AOS from 'aos';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import VueAnalytics from 'vue-analytics';
import JsonExcel from 'vue-json-excel';
import VueCompositionApi from '@vue/composition-api';
import { Chart, registerables } from 'chart.js';
import App from './App.vue';
import router from './router';
import store from './store';

/* import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'aos/dist/aos.css'; */
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import './styles/main.scss';

library.add(faFaucet, faHammer, faHardHat, faTractor, faCar, faHeartbeat, faChargingStation, faPiggyBank, faLandmark, faLaptop, faTruck, faIndustry, faKey, faShoppingBasket, faChevronRight, faChevronLeft, faPlus, faMinus, faAngleDown, faAngleUp, faWrench, faHotel, faTty, faHome, faFlask, faHandsHelping, faShieldAlt, faUniversity, faTaxi, faUsers, faGlobe, faPaintBrush, faUser, faArchive);

Vue.config.productionTip = false;
Vue.use(VueAnalytics, {
  id: '257822999',
  router,
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ElementUI);
Vue.use(VueCoreVideoPlayer);
Vue.use(VueCompositionApi);
Chart.register(...registerables);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('VueCookieAcceptDecline', VueCookieAcceptDecline);
Vue.component('DownloadExcel', JsonExcel);
Vue.config.productionTip = false;
Vue.use(VueYoutube);

new Vue({
  created() {
    AOS.init();
  },
  router,
  render: (h) => h(App),
  store,
}).$mount('#app');
