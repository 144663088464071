<template>
  <b-navbar
    v-if="
      (!route.includes('diagnostico') && !route.includes('interesse') && !route.includes('coming_soon'))||
        (route.includes('diagnostico') && route.includes('diagnosticoi40')) ||
        route.includes('diagnostico/auth') ||
        (route.includes('diagnostico') && route.includes('quiz'))
    "
    class="navbar"
    :type="!white ? 'dark' : 'light'"
    :class="!white ? 'bg-transparent' : 'bg-white'"
    toggleable="xl"
    fixed="top"
  >
    <b-container>
      <b-navbar-brand
        :to="{name: 'home'}"
      >
        <img
          v-if="route.includes('diagnostico') && route.includes('quiz')"
          :src="require('@/assets/s2f.svg')"
          alt="S2F"
        >
        <img
          v-else
          :src="!white ? require('@/assets/s2f_2.svg') : require('@/assets/s2f.svg') "
          alt="S2F"
        >
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <b-icon
            v-if="expanded"
            icon="list"
            @click="setOpened(expanded)"
          />
          <b-icon
            v-else
            icon="list"
            @click="setOpened(expanded)"
          />
        </template>
      </b-navbar-toggle>

      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <!-- Right aligned nav items -->

        <b-navbar-nav
          v-if="!route.includes('diagnostico') ||
            (route.includes('diagnostico') && route.includes('diagnosticoi40')) ||
            route.includes('diagnostico/auth')"
          class="ml-auto align-items-center"
        >
          <b-nav-item
            :to="{name: 'home'}"
          >
            <div
              class="button"
              :class="[!white ? 'light' : 'dark', route === '/' && 'active']"
            >
              Início
            </div>
          </b-nav-item>
          <b-nav-item
            :to="{name: 'project'}"
          >
            <div
              class="button"
              :class="[!white ? 'light' : 'dark', route.includes('projeto') && 'active']"
            >
              O Projeto
            </div>
          </b-nav-item>
          <b-nav-item
            v-if="canViewResults"
            :to="{name: 'results'}"
          >
            <div
              class="button"
              :class="[!white ? 'light' : 'dark', route.includes('resultados') && 'active']"
            >
              Resultados
            </div>
          </b-nav-item>
          <b-nav-item

            :to="{name: 'industry'}"
          >
            <div
              class="button"
              :class="[!white ? 'light' : 'dark', route.includes('industria') && 'active']"
            >
              Indústria 4.0
            </div>
          </b-nav-item>
          <b-nav-item
            :to="{name: 'incentives'}"
          >
            <div
              class="button"
              :class="[!white ? 'light' : 'dark', route.includes('apoios') && 'active']"
            >
              Apoios
            </div>
          </b-nav-item>
          <b-nav-item
            :to="{name: 'employability'}"
          >
            <div
              class="button"
              :class="[!white ? 'light' : 'dark', route.includes('empregabilidade') && 'active']"
            >
              Empregabilidade
            </div>
          </b-nav-item>
          <b-nav-item
            :to="{name: 'diagnostici40'}"
          >
            <div
              class="button"
              :class="[!white ? 'outlined light' : 'outlined', {'outlined': route.includes('diagnosticoi40')}]"
            >
              Diagnóstico i4.0
            </div>
          </b-nav-item>
          <!-- <div
              class="button"
              :class="[!white ? 'light' : 'dark']"
            >
              <b-icon
                icon="search"
              />
            </div> -->
          <div
            class="button"
            :class="[!white ? 'light' : 'dark']"
          >
            <b-dropdown
              v-if="$store.state.auth.loggedIn"
              id="dropdown-profile"
              class="navbar-person"
              @click="$router.push({name: 'dashboard'})"
            >
              <template #button-content>
                <b-icon
                  icon="person-circle"
                />
                <div>{{ $store.state.auth.user.name && `Olá, ${$store.state.auth.user.name.split(' ')[0]}!` }}</div>
              </template>
              <b-dropdown-item
                @click="$router.push({name: 'dashboard'})"
              >
                <b-icon
                  class="mr-2"
                  icon="building"
                />Área privada
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$store.state.auth.loggedIn"
                class="logout"
                @click="$store.commit('logout')"
              >
                <b-icon
                  icon="box-arrow-in-right"
                  class="mr-2"
                />Terminar sessão
              </b-dropdown-item>
            </b-dropdown>
            <b-nav-item
              v-else-if="$route.name === 'auth'"
              v-b-modal.modal-1
              class="navbar-person"
              :to="{name: 'diagnostic'}"
            >
              <b-icon
                icon="person-circle"
              />
            </b-nav-item>
            <b-nav-item
              v-else
              :to="{name: 'diagnostic'}"
            >
              <b-icon
                icon="person-circle"
              />
            </b-nav-item>
          </div>
        </b-navbar-nav>
        <b-navbar-nav
          v-else
          class="ml-auto align-items-center quiz"
        >
          <b-nav-item
            v-if="!route.includes('auth')"
            :to="{name: 'diagnostic'}"
          >
            <div
              class="button dark"
            >
              <b-icon
                icon="building"
                class="mr-2"
                scale="0.8"
              />Área privada
            </div>
          </b-nav-item>
          <b-nav-item
            v-if="route.includes('quiz/company')"
            :to="{name: 'quiz'}"
          >
            <div
              class="button dark"
            >
              <b-icon
                icon="ui-checks"
                class="mr-2"
                scale="0.8"
              />Aceder ao questionário
            </div>
          </b-nav-item>
          <!--   <div class="diagnostic-label">
              Diagnóstico i4.0
            </div> -->
          <div
            v-if="route == '/diagnostico/quiz' || route == '/diagnostico/quiz/'"
            class="counter"
          >
            <div class="current">
              {{ currentQuestion }}
            </div>
            <div>
              /{{ totalQuestions }}
            </div>
          </div>
          <!--  <div
              v-if="$store.state.auth.loggedIn"
              class="logout"
              @click="$store.commit('logout')"
            >
              Terminar sessão
            </div> -->
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    logo: String,
    scroll: Number,
  },
  data() {
    return {
      route: null,
      totalQuestions: this.$store.getters.totalQuestions,
      currentQuestion: this.$store.getters.currentQuestion,
      windowTop: null,
      opened: false,
    };
  },
  computed: {
    canViewResults() {
      const item = this.$store.getters.configs && this.$store.getters.configs.find((el) => el.code === 'show_results_tab');

      return (item && item.value === '1') || (this.$store.getters.user && this.$store.getters.user.permission_id === 1);
    },
    white() {
      if (this.route.includes('diagnostico/auth')
        || this.route.includes('projeto/shiftToI4.0')
        || this.route.includes('testemunhos')
        || this.route.includes('reset-password')
        || this.route.includes('resultados')
        || this.route.includes('roadmap/recurso/download')
      ) {
        return true;
      }
      if (this.scroll === 0 && !this.opened) {
        return false;
      }

      return true;
    },
  },
  watch: {
    '$store.state.quiz.totalQuestions': function () {
      this.totalQuestions = this.$store.state.quiz.totalQuestions;
    },
    '$store.state.quiz.currentQuestion': function () {
      this.currentQuestion = this.$store.state.quiz.currentQuestion;
    },
    '$store.state.auth.loggedIn': function () {
      if (!this.$store.state.auth.loggedIn) {
        this.$router.push({ name: 'auth' });
      }
    },
    $route(to) {
      this.route = to.path;
      this.opened = false;
    },
  },
  async created() {
    this.route = this.$router.currentRoute.path;
    if (this.$store.state.auth.user === null) {
      this.$store.commit('logout');
    }
  },
  methods: {
    home() {
      if (this.route.includes('diagnostico') && !this.route.includes('diagnosticoi40')) {
        this.$router.push({ name: 'home' });
      } else {
        this.$router.push({ name: 'home' });
      }
    },
    setOpened(value) {
      this.opened = !value;
    },
  },
};
</script>
