<template>
  <b-container
    class="project-container"
    fluid
  >
    <b-row class="project-header">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              cols="12"
              class=" text-left"
            >
              <div class="project-title">
                SHIFT2Future
              </div>
              <div class="project-subtitle">
                Apoiar as PME na transição para a economia 4.0
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="project-banner">
      <b-container>
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            class="description"
          >
            <p>
              A iniciativa SHIFT2Future visa dar continuidade ao
              <router-link :to="{ name: 'shiftToi40' }">
                projeto piloto SHIFTo4.0
              </router-link>
              com o objetivo de apoiar e acelerar a transição das empresas para
              a economia 4.0, capacitando os empresários com conhecimento e
              ferramentas úteis, que lhes permitam enfrentar e ultrapassar os
              novos desafios da digitalização.
            </p>

            <p>
              Através do seu registo na
              <router-link :to="{ name: 'diagnostic' }">
                área reservada
              </router-link>
              é possível ter acesso a referenciais de orientação, “Case
              Studies”, “Boas Práticas” e outras ferramentas auxiliares que
              aumentem o conhecimento das empresas e as ajudem a definir um
              plano de ação e investimentos a realizar para aumentar a sua
              maturidade digital e consequentemente a sua eficiência e
              competitividade.
            </p>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            class="device-youtube"
          >
            <div class="video-wrapper">
              <youtube
                :ref="`youtube-${videoId}`"
                :video-id="videoId"
                :fit-parent="fitParent"
                :resize="resize"
                :resize-delay="resizeDelay"
                @playing="playing"
                @ended="() => ended(`youtube-${videoId}`)"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <b-row class="project-prepare">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="project-tabs">
              <div
                class="tab"
                :class="state === 1 && 'active'"
                @click="setState(1)"
              >
                Ações
              </div>
              <div
                class="tab"
                :class="state === 2 && 'active'"
                @click="setState(2)"
              >
                Vídeos
              </div>
              <div
                class="tab"
                :class="state === 3 && 'active'"
                @click="setState(3)"
              >
                FAQs
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="state === 1"
          class="prepare"
        >
          <b-col
            cols="12"
            sm="12"
            md="4"
            class="prepare-title"
          >
            Ações para preparar as PME para a economia digital
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="8"
            class="prepare-icons"
          >
            <div>
              <img src="@/assets/Elementos/1 Home/sensibilizacao.svg">
              <div class="prepare-icons-title">
                Sensibilização, Formação & Capacitação
              </div>
              <div class="prepare-icons-description">
                Sensibilizar e capacitar as PME através da realização de
                seminários e workshops sobre metodologias e ferramentas de apoio
                ao aumento da sua maturidade i4.0 e à transição digital.
              </div>
            </div>
            <div>
              <img src="@/assets/Elementos/1 Home/diagnosticos.svg">
              <div class="prepare-icons-title">
                Diagnósticos SHIFTo4.0
              </div>
              <div class="prepare-icons-description">
                Realizar diagnósticos assistidos SHIFTo4.0 às PME, incrementar a
                base de dados da SHIFTo4.0, elaborar estudos sobre a maturidade
                digital das PME a partir da informação recolhida, elaboração de
                mapeamentos setoriais e regionais e estudos de benchmarking.
              </div>
            </div>
            <div>
              <img src="@/assets/Elementos/1 Home/roadmaps.svg">
              <div class="prepare-icons-title">
                Boas Práticas & Benchmarking
              </div>
              <div class="prepare-icons-description">
                Identificar boas práticas, desafios e constrangimentos
                relacionados com a implementação e adoção dos princípios i4.0,
                com o objetivo de incrementar o desempenho e a competitividade
                das PME, nos diversos setores de atividade em análise. Conversão
                das boas práticas em ferramentas multimédia.
              </div>
            </div>
            <div>
              <img src="@/assets/Elementos/1 Home/boaspraticas.svg">
              <div class="prepare-icons-title">
                Roadmaps & Outras Ferramentas de Apoio
              </div>
              <div class="prepare-icons-description">
                A partir dos resultados das atividades indicadas acima,
                desenvolver roadmaps setoriais que orientem as PME na
                implementação de soluções i4.0, ou seja, soluções tecnológicas,
                organizacionais e estratégicas.
              </div>
            </div>
            <div>
              <img src="@/assets/Elementos/1 Home/comunicacao.svg">
              <div class="prepare-icons-title">
                Comunicação & Disseminação do Projeto
              </div>
              <div class="prepare-icons-description">
                Comunicação e informação às PME das potencialidades e benefícios
                da economia 4.0. Criação de uma plataforma de conhecimento i4.0,
                com resultados do projeto e demais informação de relevância
                sobre a temática.
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-else-if="state === 2">
          <b-col v-if="videos.length === 0">
            <div style="font-size: 18px;">
              Não existem vídeos
            </div>
          </b-col>
          <b-col v-else>
            <b-row style="justify-content: space-evenly">
              <b-col
                v-for="(video, i) in videos"
                :key="i"
                sm="12"
                md="12"
                lg="5"
                xl="5"
                style="margin-bottom: 50px;"
              >
                <div
                  v-if="video.link"
                  class="video-wrapper border-raidus"
                >
                  <youtube
                    :ref="`youtube-${video.link}`"
                    :video-id="video.link"
                    :fit-parent="fitParent"
                    :resize="resize"
                    :resize-delay="resizeDelay"
                    @playing="playing"
                    @ended="() => ended(`youtube-${video.link}`)"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-else-if="state === 3">
          <b-col v-if="faqs.length === 0">
            <div style="font-size: 18px;">
              Não existem FAQs
            </div>
          </b-col>
          <b-col
            v-else
          >
            <div
              class="faqs-container"
            >
              <b-row
                class="faq-question"
              >
                <b-col
                  cols="12"
                  class="faqs-list"
                >
                  <div
                    v-for="(item, i) in faqs"
                    :key="i"
                    class="faq-item"
                    style="background-color: #f1f2f4;"
                  >
                    <div class="title">
                      {{ item.question }}
                    </div>
                    <div class="icon">
                      <b-icon
                        :icon="open.includes( item.id) ? 'slash-circle-fill' : 'plus-circle-fill'"
                        :rotate="open.includes( item.id) ? '45' : 0"
                        @click="toggleOpen(item.id)"
                      />
                    </div>
                    <div
                      v-if="open.includes(item.id)"
                      class="description"
                    >
                      <p
                        v-for="(desc, k) in item.answer.split('\n')"
                        :key="k"
                      >
                        <span v-html="desc" />
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <b-row class="project-diagnostic">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              cols="12"
              sm="12"
              lg="4"
            >
              <div class="project-diagnostic-title">
                Diagnóstico i4.0
              </div>
              <div class="project-diagnostic-description">
                Ferramenta de medição da maturidade digital.
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              lg="8"
              class="buttons"
            >
              <div
                class="button outlined light"
                @click="$router.push({ name: 'dashboard' })"
              >
                Começar agora <b-icon icon="chevron-right" />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { get } from '../../services/api';

export default {
  name: 'Project',
  data() {
    return {
      display: 1,
      videoId: 'GRnaJ_RkCW0',
      resize: true,
      resizeDelay: 0,
      fitParent: true,
      state: 1,
      videos: [],
      faqs: null,
      open: [],
      loading: true,
    };
  },

  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  async created() {
    await get('videos').then((response) => {
      this.videos = response.data;
    });
    await get('faqs/site').then(
      (response) => {
        this.faqs = response.data;
        if (response.data.length > 0) {
          this.open = [response.data[0].id];
        }
      },
    );
  },
  methods: {
    setState(state) {
      this.state = state;
    },
    setDisplay(value) {
      this.display = value;
    },
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      // console.log('\o/ we are watching!!!');
    },
    ended(ref) {
      try {
        this.$refs[ref][0].player.playVideo();
      } catch (err) {
        this.$refs[ref].player.playVideo();
      }
    },
    toggleOpen(open) {
      if (this.open.includes(open)) {
        this.open = this.open.filter((el) => el !== open);
      } else {
        this.open.push(open);
      }
    },
    toggleAll() {
      if (this.open.length !== Object.keys(this.faqs).length) {
        this.open = [];
        Object.keys(this.faqs).forEach((el, i) => {
          this.open.push(this.faqs[i].id);
        });
      } else {
        this.open = [];
      }
    },
  },
};
</script>
