<template>
  <b-container
    fluid
    class="resources-container"
  >
    <resources
      :resources="resources.filter(el => el.type === 'guidelines')"
      :set-loading="setLoading"
      :show-sectors="true"
    />
  </b-container>
</template>
<script>
import { get } from '../../../services/api';
import Resources from './Resources.vue';

export default {
  name: 'CaseStudies',
  components: {
    Resources,
  },
  props: {
    setLoading: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      resources: [],
    };
  },
  async created() {
    this.setLoading(true);
    await Promise.all([
      this.getResources(),
    ]);
    this.setLoading(false);
  },
  methods: {
    async getResources() {
      await get('results/resources?appendThumbnail=true&appendDocument=true&types=guidelines', { all: 0 }).then((response) => {
        this.resources = response.data.map((el) => (
          {
            ...el,
            industries: el.industries.map((industry) => industry.roadmap),
          }
        ));
      });
    },
  },
};
</script>
