export default {
  label: 'Continente',
  viewBox: '0 0 12969 27050',
  locations: [
    {
      id: 'R-23',
      path: 'M8265 25662l-288 144-104 52-195 196-262 130-96 78-230 184-98 98-196 130h-327v-65l-129-84-156-101-270-174-130-66-294-97-114 12-213 53-163 32-96-77-67-53-98-33-132 44-162 54-207-69-185-61-130-66h-131l-229 33-98 65-30 70-35-70-98 98-163 65-131 33-228 33-98 98h-131l-72 103-118 32-169-70v-163l131-98v-131l98-163 65-196-33-131 98-32 98-196 33-131-66-228 98-163 66-131 130-229v-130l126 82 33 98 198 65 99 65 66-65h132l197-65 99 32 99 65 231 33h131l99-33v-130l99-65 99-65 99 65 99-33h131l99 131-33 65 99 32 297 261 98 32h396l91-60 74-102 65-98 33-98 99-65 99 33 231-98 66-98 98-32 99-33 99-33 99-65 99-65 99-32 98-33 99-33h132l99 33 99-33 99-65 197-65 156 15 98 98 65 98 33 163 65 163 32 131v326l23 138 42 150-32 137 65 98 15 93 33 219-178-51-229 65z',
    },
    {
      id: 'R-19',
      path: 'M10518 20242l-69 157-61 72v119l-50 204-86 355-99 97-99 65-189-187-98 33-32 130-131 98-98-33h-131l-130 33v131l-33 98v130l-32 98-67 134-64 193-98 130-98 66-65 97-196 131-53 151-45 143-63 123 30 105-54 85-76 111-33 98-56 85-42 111v131l33 98-156-15-197 65-99 65-99 33-99-33h-132l-99 33-98 33-99 32-99 65-99 65-99 33-99 33-98 32-66 98-231 98-99-33-99 65-33 98-65 98-66 97-99 65h-396l-98-32-297-261-99-32 33-65-99-131h-131l-99 33-99-65-33-98v-293l198-261 33-130-66-326 33-97-99-33h-132l-66-98-99-32-33-130 99-33 132-195-66-98 33-98-66-260h99l99 32 99 65v-130l98-195v-131l66-130v-130l-99-130-98-33c-141-46-174-114-297-195l99-294 99 33 99-228 98 65 99 33v-131l66-97 165-33 329-163 99-65v-130l-66-228 33-65 99-33 132-32h132l66 97 98 33 99-65 99 32 132 65 99 33 99 65 98 65c144 36 345 60 462 98l66 98 296 97 330 33 329-33 99-65 33-97 99-66 33-97 99-65 32-98 99-33 99-65 99-32 99-66 99 66 33 97 33 98 65 98 99 32 99 33-33 98 66 97 66 98 66 65 132-65 128-26 45 65 65-98h163l196-131h196z',
    },
    {
      id: 'R-18',
      path: 'M4585 24210l33 98 -99 65 -99 65 0 130 -99 33 -131 0 -231 -33 -99 -65 -99 -32 -197 65 -132 0 -66 65 -99 -65 -198 -65 -33 -98 -126 -82 33 -98 0 -131 33 -98 0 -130 -66 -229 -65 -130 98 -294 0 -425 -33 -130 0 -164 33 -195 33 -98 -33 -262 -33 -97 -130 -66 -196 -65 98 -229 120 -228 43 -131 98 -294 65 -326 0 -425 -27 -239 -5 -87 -66 -229 -32 -130 -98 -163 -261 -262 181 59 205 102 131 -33 99 -163 -22 -49 55 -16 330 -195 131 0 297 97 198 0 230 66 99 -33 66 -98 99 33 230 391 -32 97 98 33 33 98 -131 195 131 33 66 97 264 33 99 98 197 65 -33 65 66 228 0 130 -99 65 -329 163 -165 33 -66 97 0 131 -99 -33 -98 -65 -99 228 -99 -33 -99 294c123,81 156,149 297,195l98 33 99 130 0 130 -66 130 0 131 -98 195 0 130 -99 -65 -99 -32 -99 0 66 260 -33 98 66 98 -132 195 -99 33 33 130 99 32 66 98 132 0 99 33 -33 97 66 326 -33 130 -198 261 0 293z',
    },
    {
      id: 'R-22',
      path: 'M8572 20009l-99 65-33 97-99 66-33 97-99 65-329 33-330-33c-99-41-194-64-296-97l-66-98c-117-38-318-62-462-98l-98-65-99-65-99-33-132-65-99-32-99 65-98-33-66-97h-132l-132 32-99 33-197-65-99-98-264-33-66-97-131-33 131-195-33-98-98-33 32-97-230-391-99-33-66 98-99 33-230-66h-198l-165-65-132-32h-131l-33-131-33-97 33-98 66-98 65-65 99-32 132-131 99-65 66-98 66-97 33-131-99-65-115-17 181-178h197l231-130 33-98 264-65 98-33 99 98 33 98 66 32 99-32 99 65 33 97 197 66 66-98 66-98h-99l-98 33-66-98-33-98 99-65-99-65-99 33-66-98-99-33-99-65 99-65 66-195-33-98h99l132-195 197-33 99 33 231 65 165 163-33 97 98 33 132-65 99-65 99 32 33 65 66 98 66 130 99 33h131l99 32 132-32 66-98 99-32 99 32 131-32 99 65 33-98 66-98 99-32 99-33 99-65-66-98 66-65 98 65 99 33 99 65 33 98-33 65v65l99 33 99 97-33 98 33 98 33 97 66 98 33 98 98-33 99 65 99-65 33-97h132l66-98 99 32 98 33-33 98-33 195 80 97-130 65-66 196-32 294 98 65-66 196-81 162-49 165-66 163-32 98 15 160 148 101 65 163 98 131 196 196 65 163 66 98 98 33 32 195 184 262-128 26-132 65-66-65-66-98-66-97 33-98-99-33-99-32-65-98-33-98-33-97-99-66-99 66-99 32-99 65-99 33-32 98z',
    },
    {
      id: 'R-21',
      path: 'M8514 13589l45 91 98 65 131 0 130 98 -32 229 -75 186 42 42 0 163 65 49 66 49 65 98 131 98 -66 131 33 98 0 130 65 66 65 65 66 0 184 137 33 97 -20 61 64 51 98 79 261 -98 196 98 98 163 0 130 65 98 -130 229 -131 196 -33 98 -22 113 -10 50 0 131 -98 32 -74 198 -57 31 -130 0 -131 196 -98 32 -98 131 -47 -32 -33 -65 33 -195 33 -98 -98 -33 -99 -32 -66 98 -132 0 -33 97 -99 65 -99 -65 -98 33 -33 -98 -66 -98 -33 -97 0 0 -33 -98 33 -98 -99 -97 -99 -33 0 -65 33 -65 -33 -98 -99 -65 -99 -33 -98 -65 -66 65 66 98 -99 65 -99 33 -99 32 -66 98 -33 98 -99 -65 -131 32 -99 -32 -99 32 -66 98 -132 32 -99 -32 -131 0 -99 -33 -66 -130 -66 -98 -33 -65 -99 -32 -99 65 -132 65 -98 -33 33 -97 -165 -163 -231 -65 -99 -33 -197 33 -132 195 -99 0 -99 -228 0 -130 -99 -65 -33 -98 -98 -65 -95 -39 62 -92 66 -97 32 -98 99 -65 99 -33 99 -32 66 -98 99 -65 66 -98 66 -163 65 -97 99 32 132 0 33 -97 99 -66 33 -97 66 -98 -33 -98 132 0 65 -97 66 32 99 -163 -66 -97 -99 -66 -98 -97 -33 -65 33 -131 98 -65c33,-98 66,-195 99,-293l99 65 99 33 99 65 66 98 66 32 65 -98 132 -65 66 -97 99 -66 33 -97 99 -33 33 -98 99 -130 98 -32 33 97 99 33 99 -98c136,-90 189,-95 346,-139l75 225 65 98 131 65 65 131 163 98 53 105z',
    },
    {
      id: 'R-20',
      path: 'M4881 17012l-98 33-264 65-33 98-231 130h-197l-66 65-115 113-215-15-33-98v-130l-98-33-33 65-99-32-132-98-99 98-33 97-66 98v130l-66 98h-131l-99 33v-98l-99-65-132 65-99 33-99-33-32-98 16-146-49-95-33-149 33-98 197-391 99-65-33-98 99-195-33-98-99-33v98l-99 33-33-98-65 33-66-66-33-97 66-163-33-98 98-32-65-196-132-65-132-163 66-293 99-32-66-98-33-98v-130l-33-65v-359l99 33 99 33 66-163 65-131 99-130 99-32h297l131 32 99 65 66 98-165 65v130l198-65 99 33 99-33 99-32 66-65 164 97 99 65h66l99-32 66-98 99-98 164-17 157-24 74-56 132-33 33 98 66 163 9 116 56 112 24 101 75 127 33 97 66 98 33 98 297 195-66 98-99 65-66 98-99 32-99 33-99 65-32 98-66 97-62 92 95 39 98 65 33 98 99 65v130l99 228 33 98-66 195-99 65 99 65 99 33 66 98 99-33 99 65-99 65 33 98 66 98 98-33h99l-66 98-66 98-197-66-33-97-99-65-99 32-66-32-33-98-99-98z',
    },
    {
      id: 'R-17',
      path: 'M3113 18852l22 49-99 163-131 33-205-102-181-59-131-32h-131l-98 65-65 98-98 65-98 33h-130l-98-33-91 64-138 2-98 65-131 33 98-229 66-196v-163l-36-165-128-292-32-98-66-98-16-49 196-33 229-65-17 82 71 23 125 42 98-65 65-98 66-164 98-65v-131l32-65 98-98v-32l-163-294-65 98-98 65-98 98v228l-33 131-98 98-359 98-65-33h-131l-163 66-131 32-130-65-98-65-98 32H266l-131-65 65-131 163-98-163 33-65-98v-163l65-98 33-98 65-131 33-163 41-108 57-153-33-98 33-98-33-98 33-130h65l-65-98 169 37 33 97 66 33 66 98 66-98 99 33 99 65 98-33 66 33 33 97-131 131 98 65 99-33 99 33 165 98 104 47 28-80-66-98 99-32 98 32 99-65v-228l99 65 99-65 99 33 99-65 65-33 33 98 99-33v-98l99 33 33 98-99 195 33 98-99 65-197 391-33 98 33 149 49 95-16 146 32 98 99 33 99-33 132-65 99 65v98l99-33h131l66-98v-130l66-98 33-97 99-98 132 98 99 32 33-65 98 33v130l33 98 215 15 115 17 99 65-33 131-66 97-66 98-99 65-132 131-99 32-65 65-66 98-33 98 33 97 33 131-330 195-55 16z',
    },
    {
      id: 'R-9',
      path: 'M1916 16263l0 228 -99 65 -98 -32 -99 32 66 98 -28 80 -104 -47 -165 -98 -99 -33 -99 33 -98 -65 131 -131 -33 -97 -66 -33 -98 33 -99 -65 -99 -33 -66 98 -66 -98 -66 -33 -33 -97 -169 -37 0 -164 98 -195 65 -164 65 -130 98 -262 -32 -97 32 -98 0 -53 0 -78 -32 -98 27 -36 -93 -62 0 -131 -130 -32 65 -98 98 65 65 -98 131 0 131 -98 195 -196 164 -261 130 -98 98 -98 164 -261 65 -98 -65 -130 97 -229 74 -258 76 75 99 33 192 97 38 131 27 180 -27 145 66 196 99 -66 33 131 -66 97 0 131 -33 97 33 98 -99 130 -65 131 -66 163 -99 -33 -99 -33 0 359 33 65 0 130 33 98 66 98 -99 32 -66 293 132 163 132 65 65 196 -98 32 33 98 -66 163 33 97 66 66 -99 65 -99 -33 -99 65 -99 -65zm-1824 -2214c120,0 120,191 0,191 -120,0 -120,-191 0,-191z',
    },
    {
      id: 'R-14',
      path: 'M7287 13006c-103,135 -86,91 -132,228l-99 33 -33 97 -99 66 -66 97 -132 65 33 -293 -65 -97 -33 -98 -99 -65 -66 -98 -264 -65 0 -261 -66 -97 -98 32 -99 -65 -33 11 33 -76 197 -98 33 -97 99 -33 36 -106 30 -89 -33 -98 -99 -33 -99 -32 -65 -98 -99 -65 -132 -33 -99 -65 -99 -98 132 -65 99 -32 132 -65 98 -66 99 -32 99 -33 99 -32 0 -196 198 -97 99 65 98 65 99 32 99 66 99 -33 132 0 33 -98 66 -97 197 -131 231 66 198 -66 98 196 0 130 33 98 103 45 62 -110 132 -33 99 33 33 97 98 65 0 -65 0 -130 33 -98 99 -65 66 -97 -33 -131 99 -130 0 -130 -33 -98 33 -33 132 -195 -99 -33 33 -97 -33 -131 165 -32 66 -130 98 32 99 65 99 -65 33 -163 132 33 33 130 99 33 99 -33 98 -65 99 65 7 78 -97 196 -33 131 33 97 32 131 33 98 261 33 47 88 47 158 102 15 65 196 0 130 -32 98 0 131 -66 196 0 130 -32 164 -98 65 -33 98 -98 33 -65 130 65 131 -65 98 32 130 -98 131 -32 163 -196 65 -98 -32 -131 0 -98 65 -391 -65 -164 32 -130 66 -196 0 -131 32 -98 -32 -98 -66 -199 0 -127 0 23 69c-157,44 -210,49 -346,139l-99 98 -99 -33 -33 -97 -98 32z',
    },
    {
      id: 'R-15',
      path: 'M4947 14635l-75-127-24-101-56-112-9-116-66-163-33-98-132 33-74 56-157 24-164 17-99 98-66 98-99 32h-66l-99-65-164-97-66 65-99 32-99 33-99-33-198 65v-130l165-65-66-98-99-65 99-98-33-97 198-131-33-97 66-98v-195l-66-98 66-98 99-32 99-196-198-130 33-98-33-97 33-98 198-65 98 32 198-65 66-98 198-97 66 97 33 228 32 98 99 37 99-102h132l99-65 33-163h197l33-163c148-49 86-33 195-81 156-67 62-1 234-114 131-87 85-37 131-163l67-166 98-30 99 98 99 65 132 33 99 65 65 98 198 65 33 98-30 89-36 106-99 33-33 97-197 98-32 73 32-8 99 65 98-32c83 122 66 68 66 228v130l264 65 66 98 99 65 33 98 65 97-33 293-65 98-66-32-66-98-99-65-99-33-99-65c-33 98-66 195-99 293l-98 65-33 131 33 65 98 97c113 92 50-8 165 163l-99 163-66-32-65 97h-132l33 98-66 98-33 97-99 66-33 97c-151 0-100 11-231-32-91 136-66 99-131 260l-297-195-33-98-66-98-33-97z',
    },
    {
      id: 'R-12',
      path: 'M3234 13495l33 97 -198 131 33 97 -99 98 -131 -32 -297 0 -99 32 -33 -98 33 -97 0 -131 66 -97 -33 -131 -99 66 -66 -196 27 -145 -27 -180 -38 -131 -192 -97 -99 -33 -76 -75 57 -199 221 -588 73 -196 60 -197 122 -397 136 89 132 0 99 32 66 -98 98 -32 165 65 102 74 129 -9 66 98 99 32 98 -195 165 0 0 358 99 98 0 -130 33 -98 198 -98 230 -32 33 97 66 98 -33 98 0 130 99 -32 99 -66 33 -97 66 -98 131 -65 -33 -98c78,-114 57,-69 99,-195 127,-42 72,-39 231,0l132 -196 99 66 33 97 -33 98 65 195 66 98 33 130 -99 261c-46,126 0,76 -131,163 -130,85 -82,58 -234,114 -19,21 -160,70 -195,81l-33 163 -197 0 -33 163 -99 65 -132 0 -99 102 -99 -37 -32 -98 -33 -228 -66 -97 -198 97 -66 98 -198 65 -98 -32 -198 65 -33 98 33 97 -33 98 198 130 -99 196 -99 32 -66 98 66 98 0 195 -66 98z',
    },
    {
      id: 'R-16',
      path: 'M7616 8186c33-98 66-195 99-293l33-228-33-65 165-33 131-32 33-98 33-98 99-32 33-163-132-131 66-32 66-98 99-163v-97l-99-65 33-66 33-97 187 57 101-169 8-139-32-42-99-66-33-97h132l98-33h231l99 33 33-98 99-33 99 33 32 98-32 97 65 98-65 196 178 46 52-144 461-163-34-151 100-175 165 65 72-29 32 98 131 98 33 98 98 130 32 164 66 97 65 229v163l-33 131v163l57 71 41 223-1 91-129 431v131l130 65 65 229-97 98-61 120-37 76 65 261 98 98 98 65-33 196-183 89-111 107 33 98-98 98h-196l-131 98-98-33-7-78-99-65-98 65-99 33-99-33-33-130-132-33-33 163-99 65-99-65-98-32-66 130-165 32 33 131-33 97 99 33-132 195-33 33 33 98v130l-99 130 33 131-66 97-99 65-33 98v195l-98-65-33-97-99-33-132 33-62 110-103-45-33-98v-130l-98-196-198 66-231-66-197 131-66 97-33 98h-132l-99 33-99-66-99-32 33-65-33-98 33-98 66 98 99-65 66-98 33-97-33-98-66 32-66-97-99-65-33-98 33-98 66-163-99-130-98-33 65-130 66-98 99-32 33-98-99-32-66-98 33-98-33-98-98-32-66-98 66-98 98-65-66-97-65-98 33-98 98 33 99-65 99-65h132l33-98 99-33 98-32 99-33 165-65z',
    },
    {
      id: 'R-11',
      path: 'M5870 9196l395-196v11c2-25 9-37 33-109 51-151 4-70 165-162 128-74 26-41 198-98l131 195c-122 81-83 42-164 163l66 98 98 32c41 122 41 74 0 196 81 120 41 89 165 130l-33 98-99 32c-25 20-114 193-131 228l98 33 99 130-66 163-33 98 33 98 99 65 66 97 66-32 33 98-33 97-66 98-99 65-66-98c-41 122-41 74 0 196l-33 65-197-130-198 97v196l-297 97-98 66-132 65-99 32c-108 44-70 45-231 98l33-98-33-130-66-98-32-97-33-98 33-98-33-97-99-66-132 196-132-33-99 33-33 97-66 98 33 98-131 65-66 98-33 97-99 66-99 32v-130l33-98-66-98-33-97-230 32-198 98-33 98v130l-99-98v-358h-165l-98 195-99-32-66-98-129 9-102-74-165-65-98 32-66 98-99-32h-132l-136-89 177-581-33-98-130-131 33-163 63 13 100-470 98-392 147-608 241 239 99 130 99 65h132v-130l66-163 197 98-33 97-33 131 99 97 99-32 165-33 296 98 264-65 33-98 99-65-66-195 66-98 98-65 107 9 190-9 99 195 99 33 33 97-33 131 33 97-132 294h132l118 32 211-163 165-97z',
    },
    {
      id: 'R-13',
      path: 'M5376 8902l-33-97-99-33-99-195-190 9 25-74-99-65-33-98-32-98v-130l98-33 66-97-99-33-65-98 32-97-32-131-99-65-33-98 33-97 99-33 98-65 66-163 33-65v-130l33-163 99 32 99 33 132-33 98 33 99-33-66-130-33-97 33-98-33-65-32-33 65-98 66-130h231l132 98 99-65 98-33 33-65-66-65 132-65 99-33 99 33-33 97 99 33 66 65 33 33 66 97 98-65 99-32 99 32v131h198l33 97-66 131 33 97 33 130 99-32 98-65 99 32 132 65 66-97 165-33 66 98 98 65 66 65 66 98-66 32 132 131-33 163-99 32-33 98-33 98-131 32-165 33 33 65-24 162-9 66c-33 98-66 195-99 293l-165 65-99 33-98 32-99 33-33 98h-132l-99 65-99 65-98-33-33 98-99 33-99 65-132 65-33 97-33 98-197 98-198 98-165 97-211 163c-150-41-92-32-250-32l132-294-33-97 33-131z',
    },
    {
      id: 'R-10',
      path: 'M3498 8870l0 130 -132 0 -99 -65 -99 -130 -241 -239 84 -347 30 -122 65 -294 46 -252 52 -270 68 -283 95 -403 61 -282 168 -114 0 131 33 97 66 98 -33 98 66 97 99 -32 66 0 -33 97 -33 98 33 98 -33 98 66 97 33 98 165 -33 99 -32 98 32 66 0 66 -130 132 0 187 72 109 -7 66 98 -98 65 -99 33 -33 97 33 98 99 65 32 131 -32 97 65 98 99 33 -66 97 -98 33 0 130 32 98 33 98 99 65 -25 74 -107 -9 -98 65 -66 98 66 195 -99 65 -33 98 -264 65c-99,-33 -197,-65 -296,-98l-165 33 -99 32 -99 -97 33 -131 33 -97 -197 -98 -66 163z',
    },
    {
      id: 'R-7',
      path: 'M10420 5746l-196-65-32 98-72 29-165-65-100 175 34 151-461 163-52 144-178-46 65-196-65-98 32-97-32-98-99-33-99 33-33 98-99-33h-231l-98 33h-132l33 97 99 66 32 42-8 139-101 169-187-57-33 97-33 66 99 65v97l-99 163-66 98-66-98-66-65-98-65-66-98-165 33-66 97-132-65-99-32-98 65-99 32-33-130-33-97 66-131-33-97h-198v-131l-99-32-99 32-98 65-66-97-33-33-66-65-99-33 33-97-99-33 33-130 66-98 33-98 66-97-51-152-114 54-66-33 66-195 33-98-33-97 33-98 68-46-68-84-66-98-33-163 99-65 198-130 66-98v-163l65-32 99-66 99 33 66-98 99 65 99 33 132 33 164 32 99-65 33-98 33-97h132l99-33 98-65 33-98 33-65 99-98 99-65 33 65 66 98-66 98 99 65 99 32-33 131-33 97 66 98-33 98 66 98 98 32 99 33 99 65v130l132 33v130l99 195 165-130 98-33 99 33h132v-98l33-97 66-98 99 65 66 98 197 130 198 33 99 97 99-32 98 32 33 98h132l99-33 33-97 66-98 99 33 66 97 63 66-261 262 33 130-33 98-65 131-98 130-98 66-131 32z',
    },
    {
      id: 'R-6',
      path: 'M6298 5352l66 33 114 -54 51 152 -66 97 -33 98 -66 98 -33 130 -99 33 -132 65 66 65 -33 65 -98 33 -99 65 -132 -98 -132 0 -99 0 33 -65 -33 -98 -131 196 -99 -33 -99 -32 -99 -33 -33 -98 -66 33 -33 98 -33 130 -230 32 -33 -97 -165 -131 -66 0 -33 -97 31 -111 -31 -215 0 -98 -43 -102 17 -171 59 -36 77 -125 38 -62 107 -29 75 -94 -66 -97 -165 -65 -99 65 -99 -65 -99 32 -65 -65 65 -163 99 -65 33 -98 99 -32 165 32 99 -97 98 -33 33 -98 33 -97 132 0 33 -98 132 0 99 33 33 97 131 33 33 -98 33 -98 0 -130 99 -32 33 -98 33 -98 99 33 99 32 99 65 131 66 33 97 -98 65 0 261 65 98 -33 97 99 33 99 65 33 163 66 98 68 84 -68 46 -33 98 33 97 -33 98 -66 195z',
    },
    {
      id: 'R-4',
      path: 'M3485 5944l13 -100 -65 -228 0 -196 -29 -87 -37 -109 -57 -115 -96 -193 -34 -248 -74 -293 0 -131 -32 -98 -66 -98 -65 -195 33 -196 60 2 99 65 231 65 33 98 165 65 32 98 -98 32 33 98 98 98 132 0 99 0 66 -33 99 -65 99 -33 230 -65 99 0 -66 131 264 -33 99 98 -99 97 -165 -32 -99 32 -33 98 -99 65 -65 163 65 65 99 -32 99 65 99 -65 165 65 66 97 -75 94 -107 29 -38 62 -77 125 -59 36 -17 171 43 102 0 98 31 212 -31 114 33 97 66 0 165 131 33 97 230 -32 33 -130 33 -98 66 -33 33 98 99 33 99 32 99 33 131 -196 33 98 -33 65 -66 130 -65 98 65 98 -33 98 33 97 66 130 -99 33 -98 -33 -132 33 -99 -33 -99 -32 -33 163 0 130 -33 65 -66 163 -66 -98 -109 7 -187 -72 -132 0 -66 130 -66 0 -98 -32 -99 32 -165 33 -33 -98 -66 -97 33 -98 -33 -98 33 -98 33 -97 -66 0 -99 32 -66 -97 33 -98 -66 -98 -33 -97 0 -131 -168 114 40 -237 17 -132z',
    },
    {
      id: 'R-8',
      path: 'M9626 4408l-66 98 -33 97 0 98 -132 0 -99 -33 -98 33 -165 130 -99 -195 0 -130 -132 -33 0 -130 -99 -65 -99 -33 -98 -32 -66 -98 33 -98 -66 -98 33 -97 33 -131 99 -32 98 -33 0 -130 33 -98 99 -65 66 -195 -33 -98 0 -98 33 -97 0 -131 66 -97 66 -196 33 -97 66 -98 33 -98 -66 -195 -66 -131 0 -130 33 -98 -115 -137 98 -66 131 -196 -65 -130 -12 -129 44 -67 131 130 13 -73 147 -51 134 157 228 -33 98 -97 131 97 228 66 164 -33 98 -33 32 -228 164 33 65 228 98 0 98 33 98 65 163 -98 163 -163 163 130 0 131 33 196 98 33 -33 130 -1 133 -64 96 0 130 -65 196 32 98 76 234 186 93 98 -66 -30 -130 258 0 229 98 -57 98 285 -33 131 131 131 163 98 33 32 97 -163 262 -65 130 -98 98 -66 98 -32 98 0 131 -98 32 65 98 -98 66 -98 -33 -98 98 -32 98 -98 98 -33 98 -65 98 -131 32 -33 131 -98 -65 -163 196 -98 -66 -228 33 -66 130 -98 196 -63 -66 -66 -97 -99 -33 -66 98 -33 97 -99 33 -132 0 -33 -98 -98 -32 -99 32 -99 -97 -198 -33 -197 -130 -66 -98 -99 -65z',
    },
    {
      id: 'R-5',
      path: 'M6628 3073l-33-98-33-98-99 98-231 32c-127-41-207-70-329-130l33-98v-130l66-98-99-32-99-65-99 32h-132l-99 33 33-98 99-65 66-260 79-333 65-98 98 33 33-98 65-131 98-32h131l130 98 131-33 65-98 196-33 359-98 98 66 196 65h131l-82 261h147l196-65 33-131 98 33 130-33 98 261 98-32 66-66 65-97 130 32 131-32 163-98h98l115 137-33 98v130l66 131 66 195-33 98-66 98-33 97-66 196-66 97v131l-33 97v98l33 98-66 195-99 65-33 98v130l-98 33-99 32-99-32-99-65 66-98-66-98-33-65-99 65-99 98-33 65-33 98-98 65-99 33h-132l-33 97-33 98-99 65-164-32-132-33-99-33-99-65-66 98-99-33-197-65-132-97h-132l-66-98v-130l33-66 99-32 33-98 165-260z',
    },
    {
      id: 'R-3',
      path: 'M4453 4082h-99l-230 65-99 33-99 65-66 33h-231l-98-98-33-98 98-32-32-98v-98l98 33 99-33 66-130-33-98 99-130 33 98 329 97 165-195v-130l99-65h132l99-33 33-98-99-97-99-66 33-97 66-65 66-131 197 65 66-65 132-32 99-98 230-65 99-33h132l99-32 99 65 99 32-66 98v130l-33 98c122 60 202 89 329 130l231-32 99-98 33 98 33 98-165 260-33 98-99 32-33 66v130l66 98h132l132 97 197 65-99 66-65 32v163l-66 98-198 130-99 65-99-65-99-33 33-97-65-98v-261l98-65-33-97-131-66-99-65-99-32-99-33-33 98-33 98-99 32v130l-33 98-33 98-131-33-33-97-99-33h-132l-33 98h-132l-33 97-33 98-98 33-99-98-264 33 66-131z',
    },
    {
      id: 'R-2',
      path: 'M4255 3691l-329-97-33-98-99 130 33 98-66 130-99 33-98-33v98l-165-65-33-98-231-65-99-65-60-2v-131l-66-131v-261l-56-224h51l98-65 150-23 114-43h132l82-48 148 48 66 33h132v-130l99-261-33-97h132l99-33 197-130 99-33 99 33 99-33 99 33 33-98 98 33 99-66 99-32 66-98 99-32 116-138 98 33h97l131 33 98-33-79 333-66 260-99 65-33 98-230 65-99 98-132 32-66 65-197-65-66 131-66 65-33 97 99 66 99 97-33 98-99 33h-132l-99 65v130l-165 195z',
    },
    {
      id: 'R-1',
      path: 'M4674 392l32-98 294-196 196-98 33 98-33 131 65 228 98-65h131l98 32 32 98v131l-65 98-98 33-32 98-98 65-33 98-98 65-65 229 196 196v195l-116 138-99 32-66 98-99 32-99 66-98-33-33 98-99-33-99 33-99-33-99 33-197 130-99 33h-132l33 97-99 261v130h-132l-66-33-148-48-82 48h-132l-114 43-150 23-98 65h-51l-107-429-131-228-34-128 1-117 1-147 32-98-32-131 32-97 91-123 105-139 229-98 33-163 130-98 136-68 60-30 65-196 196-98 196 33 66-131 163-32 261 32 196-65 131 33z',
    },

  ],
};
