<template>
  <div
    v-if="this.$store.getters.companyState.industry == value && !disabled"
    class="card primary"
    @click="setIndustry(value)"
  >
    <font-awesome-icon
      :icon="icon"
    /><span>{{ text }}</span>
  </div>
  <div
    v-else-if="this.$store.getters.companyState.industry == value && disabled"
    class="card primary"
  >
    <font-awesome-icon
      :icon="icon"
    /><span>{{ text }}</span>
  </div>
  <div
    v-else
    class="card secondary"
    @click="setIndustry(value)"
  >
    <font-awesome-icon
      :icon="icon"
    /><span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  Name: 'Card',
  props: {
    text: String,
    icon: String,
    value: String,
    quiz: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutateDisabled: null,
    };
  },
  created() {
    this.mutateDisabled = this.disabled;
  },
  methods: {
    setIndustry(industry) {
      if (!this.mutateDisabled) {
        this.$store.commit('setIndustry', { industry, update: true, quiz: this.quiz });
      }
    },
  },
};
</script>
