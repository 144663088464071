<template>
  <div class="quiz-container">
    <div
      :style="style"
      class="progress-bar"
    />
    <b-container
      v-if="dimensions && keys"
      fluid
      class="content"
    >
      <c-dimensions
        :dimensions="dimensions"
        :navigate-to="navigateTo"
        :keys="keys"
        :get-dimensions-keys="getDimensionsKeys"
        :dimension_store="dimension_store"
        :question_store="question_store"
        :not-enabled="notEnabled"
        :next-dimension="nextDimension"
        :clear-responses="clearResponses"
        :submit="
          () => {
            showCleanedDim = false;
            error = false;
            finalize = true;
          }
        "
      />
      <c-question
        :response-getted="responseGetted"
        :dimensions="dimensions"
        :errors="errors"
        :dimension_store="dimension_store"
        :question_store="question_store"
        :get-dimensions-keys="getDimensionsKeys"
        :get-question-keys="getQuestionKeys"
        :selected="selected"
        :get-choice="getChoice"
        :prev-question="prevQuestion"
        :next-question="nextQuestion"
        :loading="loading"
        :show="!modalShow"
        :not-enabled="notEnabled"
      />
    </b-container>
    <b-modal
      v-model="modalShow"
      centered
      size="lg"
      ok-variant="success"
      header-class="p-2 border-bottom-0"
      footer-class="p-2 border-top-0"
      ok-title="Confirmar"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <div
        v-if="this.getDimensionByIndex()"
        class="title"
      >
        {{ this.getDimensionByIndex().name }}
      </div>
      <div class="description">
        Esta dimensão destina-se a empresas com processos de produção ou
        qualquer tipo de infraestrutura operacional que inclua hardware.
        <br>Caso contrário avance para a dimensão seguinte.
      </div>
      <div class="buttons">
        <div
          class="button primary"
          @click="closeModal"
        >
          Continuar
        </div>
        <div
          class="button primary"
          @click="nextDimension()"
        >
          Dimensão seguinte
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="finalize"
      centered
      size="lg"
      ok-variant="success"
      header-class="p-2 border-bottom-0"
      footer-class="p-2 border-top-0"
      ok-title="Confirmar"
      hide-footer
    >
      <div class="title">
        Questionário Finalizado
      </div>
      <div v-if="error || allResponses || showCleanedDim">
        <div class="feedback-row ">
          <div
            v-if="error && !showCleanedDim"
            class="error-danger"
          >
            Ocorreu um erro, por favor tente submeter o questionário mais tarde!
          </div>
          <div v-if="showCleanedDim">
            <div
              v-if="showCleanedDim"
              class="error-warning"
            >
              <div v-if="showCleanedDim2 && showCleanedDim4">
                As dimensões "Infraestrutura Inteligente" e "Produtos
                Inteligentes" serão excluídas do cálculo por não possuírem
                respostas.
              </div>
              <div v-if="showCleanedDim2 && !showCleanedDim4">
                A dimensão "Infraestrutura Inteligente" será excluída do cálculo
                por não possuir respostas.
              </div>
              <div v-if="!showCleanedDim2 && showCleanedDim4">
                A dimensão "Produtos Inteligentes" será excluída do cálculo por
                não possuir respostas.
              </div>
            </div>
          </div>
        </div>
        <div class="feedback-row ">
          <div
            v-if="allResponses"
            class="error-danger"
          >
            Por favor, responda a todas as questões!
          </div>
        </div>
      </div>
      <div class="buttons">
        <div
          v-if="!showCleanedDim"
          class="button secondary"
          @click="goToCompanyQuestions"
        >
          Consultar questões gerais
        </div>
        <div
          v-if="!showCleanedDim"
          class="button secondary"
          @click="finalize = false"
        >
          Voltar ao Questionário
        </div>
      </div>
      <div
        v-if="
          $store.getters.user.permission_id == 3 ||
            $store.getters.user.permission_id == 1
        "
        class="buttons"
      >
        <div
          v-if="!showCleanedDim"
          class="button primary"
          @click="() => openFuture()"
        >
          Submeter Questionário
        </div>

        <div
          v-if="showCleanedDim"
          class="button primary"
          @click="() => openFuture(true)"
        >
          Submeter Questionário
        </div>

        <div
          v-if="!showCleanedDim"
          class="button primary"
          @click="$router.push({ name: 'diagnostic' })"
        >
          Submeter mais tarde
        </div>
      </div>
      <div
        v-if="$store.getters.user.permission_id == 2"
        class="feedback-row "
      >
        <div class="error-danger">
          Não possui permissões para submeter o questionário!
        </div>
      </div>
    </b-modal>
    <b-modal
      id="future"
      hide-header
      hide-footer
      size="xl"
      centered
      header-class="p-2 border-bottom-0"
      footer-class="p-2 border-top-0"
    >
      <div class="report">
        <div
          v-if="generating || generated"
          class="feedback-row"
        >
          <div
            v-if="generating"
            class="error-success"
          >
            O seu relatório está a ser gerado. Será redirecionado para os
            resultados dentro de instantes.
          </div>
          <div
            v-if="generated"
            class="error-success"
          >
            O seu relatório foi gerado com sucesso.
          </div>
        </div>
        <div
          v-if="global"
          class="report-dimensions-classification"
        >
          <div class="title">
            Nas 6 dimensões analisadas, os níves de maturidade i4.0 são os
            seguintes:
          </div>
          <div class="row-dimensions-classification">
            <div class="dimensions">
              <div class="row-dimensions header">
                <div class="dimension">
                  Dimensão
                </div>
                <div class="classification">
                  Nível
                </div>
              </div>
              <div
                v-for="(dim, j) in Object.keys(dimensions)"
                :key="j"
                class="row-dimensions"
              >
                <div class="dimension">
                  {{ dimensions[dim].name }}
                </div>
                <div class="classification">
                  {{ dimensionsResult[j] }}
                </div>
              </div>
              <div class="row-dimensions">
                <div class="dimension">
                  Avaliação
                </div>
                <div
                  class="classification bg-primary text-light"
                  style="border-top-left-radius: 5px; border-bottom-left-radius: 5px; border-top-right-radius: 5px; border-bottom-right-radius: 5px"
                >
                  {{ global ? Math.floor(global[0].classification) : null }}
                </div>
              </div>
            </div>

            <div class="classifications">
              <img
                :src="
                  require(`@/assets/N${Math.floor(
                    global[0].classification
                  )}.svg`)
                "
              >
            </div>
          </div>
        </div>
        <div
          v-if="svg && dimensionsResult"
          class="report-themes-classification"
        >
          <div class="title">
            Na avaliação dos temas, de cada dimensão, obteve as seguintes
            classificações:
          </div>
          <div class="row-themes-classification">
            <div
              class="svg"
              v-html="svg"
            />

            <div class="table">
              <div class="row-dimensions">
                <div class="dimension">
                  Dimensão
                </div>
                <div class="themes">
                  <div class="row-themes">
                    <div class="theme">
                      Tema
                    </div>
                    <div class="classification">
                      Nível
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="(dim, j) in Object.keys(dimensions)"
                :key="`dimension-${j}`"
                class="row-dimensions"
              >
                <div class="dimension">
                  {{ dimensions[dim].name }}
                </div>
                <div class="themes">
                  <div
                    v-for="(theme, i) in Object.keys(
                      themes.filter((e) => e.dimension_id == dimensions[dim].id)
                    )"
                    :key="`theme-${i}`"
                    class="row-themes"
                  >
                    <div class="theme">
                      {{
                        themes.filter(
                          (e) => e.dimension_id == dimensions[dim].id
                        )[theme].name
                      }}
                    </div>
                    <div class="classification">
                      {{
                        themesResult[
                          themes.indexOf(
                            themes.filter(
                              (e) => e.dimension_id == dimensions[dim].id
                            )[theme]
                          )
                        ]
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  <c-level-group
          :on-click="getChoiceFuture"
          question="Selecione os seus objetivos futuros"
          :choices="choicesFuture"
          :max="Math.max(...choicesFuture[0].value.value)"
          :min="Math.min(...choicesFuture[0].value.value)"
          :selected="selectedFuture"
          :not-enabled="false"
        /> -->
        <c-radio-box-group
          question="Selecione os seus objetivos futuros"
          :choices="choicesFuture"
          :on-click="getChoiceFuture"
          :selected="selectedFuture"
          :not-enabled="false"
        />
        <div class="buttons">
          <div
            v-if="
              $store.getters.user.permission_id == 3 ||
                $store.getters.user.permission_id == 1
            "
            class="button primary"
            @click="submit()"
          >
            Submeter
          </div>
          <div
            v-if="$store.getters.user.permission_id == 2"
            class="feedback-row"
          >
            <div class="error-danger">
              Não possui permissões para submeter o questionário!
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  get, post, put, remove,
} from '../../../services/api';
import { generateChartSite } from '../../../services/common/generate-chart-site';

import DimensionsSideBar from '../../ui/DimensionsSideBar.vue';
// import LevelGroup from '../../ui/LevelGroup.vue';
import RadioBoxGroup from '../../ui/RadioBoxGroup.vue';
import QuestionContainer from '../../ui/QuestionContainer.vue';

export default {
  Name: 'Quiz',
  components: {
    'c-dimensions': DimensionsSideBar,
    'c-question': QuestionContainer,
    // 'c-level-group': LevelGroup,
    'c-radio-box-group': RadioBoxGroup,
  },
  data() {
    return {
      dimensions: {},
      question_store: this.$store.getters.question,
      dimension_store: this.$store.getters.dimension,
      currentQuestion: this.$store.getters.currentQuestion,
      style: '',
      response: null,
      loading: false,
      errors: [],
      notEnabled: false,
      modalShow: false,
      finalize: false,
      error: false,
      allResponses: false,
      quiz: null,
      generating: false,
      generated: false,
      selectedFuture: [1, 1, 1, 1, 1, 1],
      keys: null,
      responseGetted: false,
      choicesFuture: [
        {
          label: 'Estratégia e Organização',
          value: {
            label: {
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
        {
          label: 'Infraestrutura Inteligente',
          value: {
            label: {
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
        {
          label: 'Operações Inteligentes',
          value: {
            label: {
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
        {
          label: 'Produtos Inteligentes',
          value: {
            label: {
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
        {
          label: 'Serviços Baseados em Dados',
          value: {
            label: {
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
        {
          label: 'Recursos Humanos',
          value: {
            label: {
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
            },
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
            value: [1, 2, 3, 4, 5],
          },
        },
      ],
      timeout: null,
      windowTop: 0,
      prev_question: null,
      prev_dimension: null,
      svg: null,
      dimensionsResult: null,
      themesResult: null,
      global: null,
      themes: null,
      showCleanedDim: false,
      showCleanedDim2: false,
      showCleanedDim4: false,
      dimensionsGetted: false,
    };
  },
  computed: {
    selected() {
      return this.response;
    },
  },
  watch: {
    '$store.state.quiz.question': async function () {
      this.prev_question = this.question_store;
      this.question_store = this.$store.state.quiz.question;

      if (this.dimensionsGetted) {
        if (this.getQuestionByIndex().code === 'Q23') {
          this.response = null;
          let skipQuestion = false;
          await get('response', {
            quiz: this.$store.getters.quiz,
            question: this.getQuestionByIndex(true).id,
          })
            .then((response) => {
              response.data.forEach(async (element) => {
                if (element.value !== 5) {
                  skipQuestion = true;
                  await post('response', {
                    delete: true,
                    quiz: this.$store.getters.quiz,
                    question: this.getQuestionByIndex().id,
                  });
                }
                if (skipQuestion) {
                  if (this.prev_question === 0) {
                    if (this.prev_dimension > this.dimension_store) {
                      this.prevQuestion();
                    } else {
                      this.nextQuestion();
                    }
                  } else if (this.prev_question > this.$store.state.quiz.question) {
                    this.prevQuestion();
                  } else {
                    this.nextQuestion();
                  }
                }
              });
            })
            .catch((error) => {
            // console.log(error);
            });
        }

        if (this.getQuestionByIndex().code === 'Q20B') {
          this.response = null;
          let skipQuestion = false;
          await get('response', {
            quiz: this.$store.getters.quiz,
            question: this.getQuestionByIndex(true).id,
          })
            .then((response) => {
              response.data.forEach(async (element) => {
                if (element.value !== 0) {
                  skipQuestion = true;
                  await post('response', {
                    delete: true,
                    quiz: this.$store.getters.quiz,
                    question: this.getQuestionByIndex().id,
                  });
                }
              });
              if (skipQuestion) {
                if (this.prev_question === 0) {
                  if (this.prev_dimension > this.dimension_store) {
                    this.prevQuestion();
                  } else {
                    this.nextQuestion();
                  }
                } else if (this.prev_question > this.$store.state.quiz.question) {
                  this.prevQuestion();
                } else {
                  this.nextQuestion();
                }
              }
            })
            .catch((error) => {
            // console.log(error);
            });
        }
        this.updateCurrentQuesion();
      }
    },
    '$store.state.quiz.dimension': function () {
      this.prev_dimension = this.dimension_store;
      this.dimension_store = this.$store.state.quiz.dimension;
      this.updateCurrentQuesion();
      if (
        (this.dimension_store === 1 || this.dimension_store === 3)
        && !this.notEnabled
      ) {
        this.modalShow = true;
      }
    },
    '$store.state.quiz.faultQuestions': async function () {
      if (this.$store.getters.faultQuestions === null) {
        this.$store.commit('setDimension', 0);
        this.$store.commit('setQuestion', 0);
        this.errors = [];
      } else {
        this.getDimensionsKeys().forEach((dimension, i) => {
          if (
            this.getDimension(dimension).id
            === this.$store.getters.faultQuestions[0].dimension_id
          ) {
            this.$store.commit('setDimension', i);
            this.getQuestionKeys(dimension).forEach((question, j) => {
              if (
                this.getQuestion(dimension, question).id
                === this.$store.getters.faultQuestions[0].id
              ) {
                this.$store.commit('setQuestion', j);
              }
            });
          }
        });

        this.errors = [];
        this.$store.getters.faultQuestions.forEach((fault) => {
          this.getDimensionsKeys().forEach((dimension, i) => {
            if (this.getDimension(dimension).id === fault.dimension_id) {
              this.getQuestionKeys(dimension).forEach((question, j) => {
                if (this.getQuestion(dimension, question).id === fault.id) {
                  this.errors.push({ dimension: i, question: j });
                }
              });
            }
          });
        });
      }
      await this.setResponse();
    },
    '$store.state.quiz.currentQuestion': async function () {
      if (this.dimensionsGetted) {
        this.currentQuestion = this.$store.state.quiz.currentQuestion;
        this.updateCurrentQuesion();
        await this.setResponse();
      }
    },
    '$store.state.quiz.disabled': function () {
      this.notEnabled = this.$store.state.quiz.disabled;
    },
    dimensions() {
      this.updateCurrentQuesion();
    },
  },
  mounted() {
    this.$store.dispatch('setDimension', 0);
    this.$store.dispatch('setQuestion', 0);
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  async created() {
    this.errors = null;
    this.loading = true;
    await get(`quiz/${this.$store.getters.quiz}`).then((response) => {
      if (
        response.data.company_id
          === this.$store.getters.state.user.company_id
        && response.data.status === 'open'
      ) {
        this.$store.commit('setDisabled', false);
      }
    });
    this.$store.dispatch('setDimension', 0);
    this.$store.dispatch('setQuestion', 0);
    this.dimensionsGetted = false;
    if (!this.$store.getters.state.loggedIn) {
      this.$router.push({ name: 'auth' });
    } else {
      await get('/question_option_value/', {})
        .then(async (response2) => {
          this.dimensions = response2.data;
          if (this.$store.getters.faultQuestions === null) {
            this.errors = [];
          } else {
            this.getDimensionsKeys().forEach((dimension, i) => {
              if (
                this.getDimension(dimension).id
                === this.$store.getters.faultQuestions[0].dimension_id
              ) {
                this.$store.commit('setDimension', i);
                this.getQuestionKeys(dimension).forEach((question, j) => {
                  if (
                    this.getQuestion(dimension, question).id
                    === this.$store.getters.faultQuestions[0].id
                  ) {
                    this.$store.commit('setQuestion', j);
                  }
                });
              }
            });
            this.errors = [];
            this.$store.getters.faultQuestions.forEach((fault) => {
              this.getDimensionsKeys().forEach((dimension, i) => {
                if (this.getDimension(dimension).id === fault.dimension_id) {
                  this.getQuestionKeys(dimension).forEach((question, j) => {
                    if (this.getQuestion(dimension, question).id === fault.id) {
                      this.errors.push({ dimension: i, question: j });
                    }
                  });
                }
              });
            });
          }
          this.dimensionsGetted = true;
          await this.setResponse();
          this.keys = this.getDimensionsKeys();
        })
        .catch((error) => {
          this.loading = false;
        });
    }

    await get('theme', {}).then((response) => {
      this.themes = response.data;
    });
    this.notEnabled = this.$store.state.quiz.disabled;
  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY; /* or: e.target.documentElement.scrollTop */
    },
    async openFuture(cleaned = false) {
      this.error = false;
      this.showCleanedDim = false;
      this.showCleanedDim2 = false;
      this.showCleanedDim4 = false;
      this.quiz = this.$store.getters.quiz;
      await post('score', {
        quiz: this.$store.getters.quiz,
        cleaned,
      })
        .then(async (response) => {
          this.global = response.data.global;
          this.dimensionsResult = response.data.dimensions;
          this.themesResult = response.data.themes;
          this.svg = generateChartSite(
            response.data.themes,
            response.data.dimensions,
            response.data.global,
            [0, 0, 0, 0, 0, 0],
            response.data.kos,
          ).innerHTML;
          this.$bvModal.show('future');
        })
        .catch((error) => {
          this.$bvModal.hide('future');
          if (error.status === 409) {
            if (error.data.error === 'Fault Geral Questions.') {
              this.$store.commit('setFaultGeralQuestions', true);
              this.$router.push({
                name: 'company_quiz',
                params: {
                  id: this.$store.getters.quiz,
                },
              });
            } else if (error.data.error === 'Conflit. Cleaned dimension') {
              this.showCleanedDim = true;
              this.showCleanedDim2 = error.data.cleanedDim2;
              this.showCleanedDim4 = error.data.cleanedDim4;
            } else {
              this.allResponses = true;
              this.$store.commit('setFaultQuestions', error.data.questions);
            }
          } else {
            this.error = true;
          }
        });
    },
    async getChoiceFuture(value) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = await setTimeout(async () => {
        await post('future', {
          quiz_id: this.quiz,
          values: value,
        });
      }, 500);
    },
    async closeModal() {
      await post('/ko', {
        dimension: this.getDimensionByIndex().id,
        quiz: this.$store.getters.quiz,
        value: 0,
      })
        .then((response) => {
          this.modalShow = false;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async clearResponses() {
      await remove('/responses', {
        dimension: this.getDimensionByIndex().id,
        quiz: this.$store.getters.quiz,
      })
        .then((response) => {
          this.setResponse();
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async nextDimension() {
      await post('/ko', {
        dimension: this.getDimensionByIndex().id,
        quiz: this.$store.getters.quiz,
        value: 1,
      })
        .then((response) => {
          this.modalShow = false;
          this.navigateTo(this.dimension_store + 1);
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    updateCurrentQuesion() {
      let partial = 0;
      let total = 0;
      this.getDimensionsKeys().forEach((element, index) => {
        if (index < this.dimension_store) {
          partial
            += this.dimensions[element].questions.filter((el) => el.required)
              .length
            + this.$store.getters.responses
              .filter(
                (response) => this.dimensions[response.dimension_id]
                  == this.dimensions[element],
              )
              .map((el) => el.question_code)
              .filter((el) => this.dimensions[element].questions
                .filter((el) => !el.required)
                .map((el) => el.code)
                .includes(el)).length;
        } else if (index === this.dimension_store) {
          partial += parseInt(this.question_store, 10) + 1;
        }

        total += this.dimensions[element].questions.filter((el) => el.required)
          .length;
      });
      this.$store.commit('setTotalQuestions', total);
      this.$store.commit('setCurrentQuestion', partial);
      this.style = `zIndex: 1040; 
      position: absolute; 
      height: 4px; 
      width: ${(partial / total) * 100}%; 
      backgroundColor: rgb(65, 173, 238);
      height: 4px; 
      position: fixed`;
      this.loading = true;
    },
    getDimensionsKeys() {
      return Object.keys(this.dimensions);
    },
    getQuestionKeys(dimension) {
      return Object.keys(this.dimensions[dimension].questions);
    },
    getDimension(dimension) {
      return this.dimensions[dimension];
    },
    getQuestion(dimension, question) {
      return this.dimensions[dimension].questions[question];
    },
    getDimensionByIndex(prev = false) {
      if (this.dimensionsGetted) {
        return this.dimensions[
          this.getDimensionsKeys()[
            prev ? this.dimension_store - 1 : this.dimension_store
          ]
        ];
      }
      return null;
    },
    getQuestionByIndex(prev = false) {
      if (this.dimensionsGetted) {
        const questionIndex = this.getQuestionKeys(this.getDimensionByIndex().id)[
          this.question_store
        ];

        if (this.getDimensionByIndex()) {
          return this.getDimensionByIndex().questions[
            prev ? questionIndex - 1 : questionIndex
          ];
        }
        return null;
      }
      return null;
    },
    navigateTo(value) {
      this.$store.commit('setQuestion', 0);
      this.$store.commit('setDimension', value);
    },
    async asyncForEach(array, callback) {
      /* eslint-disable no-await-in-loop */
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array);
      }
      /* eslint-enable no-await-in-loop */
    },
    async getChoice(value) {
      const responses = [];
      let questionType = 1;
      if (this.getQuestionByIndex().type === 1) {
        questionType = 1;
        responses.push(
          this.getQuestionByIndex().choices.filter((n) => {
            if (n.value === value) {
              return n;
            }
          })[0],
        );
      } else if (this.getQuestionByIndex().type === 2) {
        questionType = 2;
        value.forEach((element) => {
          responses.push(
            {
              ...this.getQuestionByIndex().choices.filter((n) => {
                if (n.value === element.value) {
                  return n;
                }
              })[0],
              notes: element.notes,
            },
          );
        });
      } else if (this.getQuestionByIndex().type === 3) {
        questionType = 3;
        if (value.length > this.getQuestionByIndex().choices.length) {
          value = value.slice(0, this.getQuestionByIndex().choices.length);
        }
        value.forEach((element, i) => {
          /*    console.log(this.getQuestionByIndex(), i);
          console.log(this.getQuestionByIndex().choices[i]); */
          responses.push(
            this.getQuestionByIndex().choices[i].value.ids.filter((n) => {
              if (n.value === element) {
                return n;
              }
            })[0],
          );
        });
      }
      if (questionType === 1) {
        await this.asyncForEach(responses, async (element) => {
          await post('response', {
            quiz: this.$store.getters.quiz,
            question: this.getQuestionByIndex().id,
            question_option_value: element.id,
            question_type: questionType,
          })
            .then((responseQuiz) => {
              // console.log(responseQuiz);
            })
            .catch((errorQuiz) => {
              // console.log(errorQuiz);
            });
        });
      } else if (questionType === 2) {
        await post('response', {
          quiz: this.$store.getters.quiz,
          question: this.getQuestionByIndex().id,
          question_type: questionType,
          responses,
        })
          .then((responseQuiz) => {
            // console.log(responseQuiz);
          })
          .catch((errorQuiz) => {
            // console.log(errorQuiz);
          });
      } else if (questionType === 3) {
        await post('response', {
          quiz: this.$store.getters.quiz,
          question: this.getQuestionByIndex().id,
          question_type: questionType,
          responses,
        })
          .then((responseQuiz) => {
            // console.log(responseQuiz);
          })
          .catch((errorQuiz) => {
            // console.log(errorQuiz);
          });
      }
    },
    async nextQuestion() {
      if (
        this.question_store
        === this.getDimensionByIndex().questions.length - 1
      ) {
        if (this.dimension_store !== this.getDimensionsKeys().length - 1) {
          await this.$store.commit('setQuestion', 0);
          await this.$store.commit(
            'setDimension',
            parseInt(this.dimension_store, 10) + 1,
          );
        } else {
          this.finalize = true;
          this.showCleanedDim = false;
          this.showCleanedDim2 = false;
          this.showCleanedDim4 = false;
        }
      } else {
        await this.$store.commit(
          'setQuestion',
          parseInt(this.question_store, 10) + 1,
        );
      }
    },
    async prevQuestion() {
      if (this.question_store === 0) {
        if (this.dimension_store !== 0) {
          await this.$store.commit(
            'setDimension',
            parseInt(this.dimension_store, 10) - 1,
          );
          await this.$store.commit(
            'setQuestion',
            this.getDimensionByIndex(false).questions.length - 1,
          );
        }
      } else {
        await this.$store.commit(
          'setQuestion',
          parseInt(this.question_store, 10) - 1,
        );
      }
    },
    async submit() {
      const id = this.$store.getters.quiz;
      this.allResponses = null;
      this.error = null;
      this.showCleanedDim = false;

      this.pdfLoad = false;
      this.success = null;
      this.generating = true;
      this.generated = false;
      await post('score', {
        quiz: id,
      })
        .then(async (response) => {
          const svg = generateChartSite(
            response.data.themes,
            response.data.dimensions,
            response.data.global,
            response.data.future,
            response.data.kos,
          ).innerHTML.replaceAll('textpath', 'textPath').replaceAll('startoffset', 'startOffset');
          await put(`/quiz/${id}`, {
            svg,
            status: 'submitted',
          })
            .then(() => {
              this.$bvModal.hide('future');
              this.selected = [0, 0, 0, 0, 0, 0];
              this.success = true;

              get(`/score/pdf/${id}`, {}).then((response) => {
                this.generating = false;
                this.generated = false;
              });
              setTimeout(() => {
                this.$store.dispatch('dashboard/setState', 3);
                this.$router.push({ name: 'diagnostic' });
              }, 5000);
            })
            .catch((ex) => {
              this.generating = false;
              this.error = true;
            });
        })
        .catch((error) => {
          this.$bvModal.hide('future');
          this.generating = false;
          if (error.status === 409) {
            if (error.data.error === 'Fault Geral Questions.') {
              this.$store.commit('setFaultGeralQuestions', true);
              this.$router.push({
                name: 'company_quiz',
                params: {
                  id: this.$store.getters.quiz,
                },
              });
            } else {
              this.allResponses = true;
              this.$store.commit('setFaultQuestions', error.data.questions);
            }
          } else {
            this.generating = false;
            this.error = true;
          }
        });
    },
    async setResponse() {
      this.response = null;
      this.responseGetted = false;
      if (this.dimensionsGetted) {
        await get('response', {
          quiz: this.$store.getters.quiz,
          question: this.getQuestionByIndex().id,
        })
          .then((response) => {
            if (response.data.length > 0) {
              const questionType = this.getQuestionByIndex().type;
              if (questionType === 1) {
                this.response = response.data[0].value;
              } else if (questionType === 2) {
                const temp = [];
                response.data.forEach((element) => {
                // temp.push(element.value);
                  temp.push({ value: element.value, notes: element.notes });
                });
                this.response = temp;
              } else if (questionType === 3) {
                const tempIds = this.getQuestionByIndex().choices.map((el) => el.id);
                this.response = [];
                const temp = Array(this.getQuestionByIndex().choices.length).fill(
                  null,
                );
                response.data.forEach((element) => {
                  temp[tempIds.indexOf(element.question_option_id)] = element.value;
                });
                this.response = temp;
              }
              this.responseGetted = true;
            } else if (this.getQuestionByIndex().type === 3) {
              const temp = Array(this.getQuestionByIndex().choices.length).fill(
                null,
              );
              this.response = temp;
              this.responseGetted = true;
            } else {
              this.response = [];
              this.responseGetted = true;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.response = [];
            this.responseGetted = true;
            // console.log(error);
            this.loading = false;
          });
      }
    },
    goToCompanyQuestions() {
      const id = this.$store.getters.quiz;
      this.$store.commit('setDisabled', false);
      this.$store.commit('setQuiz', id);
      this.$router.push({
        name: 'company_quiz',
        params: {
          id,
        },
      });
    },
  },
};
</script>
