export default {
  namespaced: true,
  state: {
    state: localStorage.getItem('shift2future@dashboard-state') || 1,
  },
  mutations: {
    setState(state, value) {
      state.state = value;
      localStorage.setItem('shift2future@dashboard-state', value);
    },
  },
  actions: {
    setState({ commit }, state) {
      commit('setState', state);
    },
  },
  getters: {
    state: (state) => parseInt(state.state, 10),
  },
};
