<template>
  <b-container
    fluid
    class="resources-container"
  >
    <div class="events">
      <div
        v-for="year in years"
        :key="year"
        class="year-content"
      >
        <div class="events-header">
          <div class="total">
            <div class="year mr-2">
              {{ year }}
            </div>
            <div class="total">
              (Total: {{ resources.filter(el => el.event_date && el.event_date.split('-')[0] === year).length }})
            </div>
          </div>
          <div class="order">
            Ordenação:
            <b-form-select
              v-model="order"
              :options="[{value: 'desc', text: 'Mais Recente'}, {value: 'asc', text: 'Mais Antigo'}]"
            />
          </div>
        </div>
        <div
          v-for="resource in resources.filter(el => el.event_date && el.event_date.split('-')[0] === year)"
          :key="resource.id"
          class="event"
        >
          <div
            v-if="resource.event_date"
            class="date"
          >
            {{ resource.event_date.split('-').reverse().join('/') }}
          </div>
          <div class="dot" />
          <div class="content">
            <div class="thumbnail">
              <resource
                :resource="resource"
                :set-loading="setLoading"
                thumbnail
              />
            </div>
            <div class="body">
              <div class="types">
                {{ resource.resource_event_type ? resource.resource_event_type.label : '' }}{{ resource.resource_event_sub_type ? ` | ${resource.resource_event_sub_type.label}`: '' }}
              </div>
              <div class="title">
                {{ resource.label }}
              </div>
              <div class="responsible">
                {{ resource.event_responsible }}
              </div>
              <div
                v-if="resource.event_atendees !== null || resource.event_registered !== null || resource.event_views !== null"
                class="event-analytics"
              >
                <div>
                  <b-icon icon="people-fill" />
                  {{ resource.event_registered !== null ? `${resource.event_registered} inscritos` : '' }}  {{ resource.event_registered !== null && resource.event_atendees !== null ? '\\' : '' }} {{ resource.event_atendees !== null ? `${resource.event_atendees} participantes` : null }}
                </div>
                <div v-if="resource.event_views != null">
                  <b-icon icon="eye-fill" />
                  {{ resource.event_views }} visualizações
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import { get } from '../../../services/api';
import Resource from './Resource.vue';

export default {
  name: 'Events',
  components: { Resource },
  props: {
    setLoading: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      resources: [],
      order: null,
    };
  },
  computed: {
    years() {
      return [...new Set(this.resources.filter((el) => el.event_date !== null).map((el) => el.event_date.split('-')[0]))];
    },
  },
  watch: {
    order(value) {
      if (value === 'desc') {
        this.resources = this.resources.filter((el) => el.event_date !== null).sort((a, b) => (a.event_date < b.event_date ? 1 : -1));
      } else if (value === 'asc') {
        this.resources = this.resources.filter((el) => el.event_date !== null).sort((a, b) => (a.event_date < b.event_date ? 1 : -1)).reverse();
      }
    },
  },
  async created() {
    this.setLoading(true);
    await Promise.all([
      this.getResources(),
    ]);
    this.setLoading(false);
  },
  methods: {
    async getResources() {
      await get('results/resources?appendThumbnail=true&appendDocument=true&type=event', { all: 0 }).then((response) => {
        this.resources = response.data.map((el) => (
          {
            ...el,
            industries: el.industries.map((industry) => industry.roadmap),
          }
        ));
        this.order = 'desc';
      });
    },
  },
};
</script>
