<template>
  <b-container fluid>
    <b-row class="reset-password-container">
      <b-col>
        <b-container>
          <b-row v-if="error || success || passwords || passwordLength || true">
            <b-col
              cols="6"
              offset="3"
            >
              <div
                class="feedback-row"
              >
                <div
                  v-if="error"
                  class="error-danger"
                >
                  Ocorreu um erro, por favor tente mais tarde!
                </div>
                <div
                  v-if="passwords"
                  class="error-danger"
                >
                  As passwords devem coincidir!
                </div>
                <div
                  v-if="passwordLength"
                  class="error-danger"
                >
                  A password tem de conter pelo menos 8 caracteres!
                </div>
                <div
                  v-if="success"
                  class="error-success"
                >
                  Password alterada com sucesso!
                </div>
              </div>
            </b-col>
          </b-row>
          <div
            v-if="success"
          >
            <div class="buttons">
              <a href="../diagnostico/auth">Efetuar login na plataforma.</a>
            </div>
          </div>
          <b-row>
            <b-col
              cols="6"
              offset="3"
            >
              <b-form @submit="resetPassword">
                <b-form-group
                  id="input-group-5"
                  label="Email *"
                  label-for="input-5"
                >
                  <b-form-input
                    id="input-4"
                    v-model="form.email"
                    type="email"
                    required
                    placeholder="Email"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-5"
                  label="Password *"
                  label-for="input-5"
                >
                  <b-form-input
                    id="input-5"
                    v-model="form.password"
                    type="password"
                    required
                    :state="passwordValid"
                    placeholder="Password"
                  />
                  <b-form-invalid-feedback id="input-live-feedback">
                    A password deve conter pelo menos 8 caractéres e 2 das seguintes condições:
                    <ul>
                      <li>Caracteres especiais</li>
                      <li>Letras maiúsculas e minúsculas</li>
                      <li>Caracteres numéricos</li>
                    </ul>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-row class="mb-10">
                  <b-col>
                    Segurança da password: {{ strong == 0 ? 'Nenhuma' : strong == 1 ? 'Fraca' : strong == 2 ? 'Média' : 'Forte' }}
                  </b-col>
                </b-row>
                <b-row class="security-display">
                  <b-col
                    class="level"
                    :class="[strong >= 0 && 'visible-level', strong == 0 && 'last']"
                  >
                    <div
                      :class="strong == 0 ? 'danger' : strong == 1 ? 'danger' : strong == 2 ? 'warning' : 'success' "
                    />
                  </b-col>
                  <b-col
                    class="level"
                    :class="[strong >= 1 && 'visible-level', strong == 1 && 'last']"
                  >
                    <div
                      :class="strong >= 1 ? strong == 0 ? '' : strong == 1 ? 'danger' : strong == 2 ? 'warning' : 'success' : '' "
                    />
                  </b-col>
                  <b-col
                    class="level"
                    :class="[strong >= 2 && 'visible-level', strong == 2 && 'last']"
                  >
                    <div
                      :class="strong >= 2 ? strong == 0 ? '' : strong == 1 ? 'danger' : strong == 2 ? 'warning' : 'success' : '' "
                    />
                  </b-col>
                  <b-col
                    class="level"
                    :class="[strong >= 3 && 'visible-level', strong == 3 && 'last']"
                  >
                    <div
                      :class="strong >= 3 ? strong == 0 ? '' : strong == 1 ? 'danger' : strong == 2 ? 'warning' : 'success' : '' "
                    />
                  </b-col>
                </b-row>
                <b-form-group
                  id="input-group-6"
                  label="Confirmar password *"
                  label-for="input-6"
                  class="open-sans-light fs-14 text-dark"
                >
                  <b-form-input
                    id="input-6"
                    v-model="form.cpassword"
                    type="password"
                    required
                    placeholder="Confirmar password"
                    :state="equalPasswords"
                  />
                  <b-form-invalid-feedback id="input-live-feedback">
                    As passwords devem coincidir.
                  </b-form-invalid-feedback>
                </b-form-group>
                <div>* Campo de preenchimento obrigatório</div>
                <b-row
                  v-if="passwordValid && equalPasswords"
                >
                  <b-col
                    class="buttons justify-content-start"
                  >
                    <button
                      type="submit"
                      variant="primary"
                      class="button primary"
                    >
                      Reset password
                    </button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { post } from '../../../services/api';

export default {
  name: 'ResetPassword',
  data() {
    return {
      error: false,
      success: false,
      passwords: false,
      passwordLength: false,
      form: {
        email: this.$store.getters.resetEmail !== null ? this.$store.getters.resetEmail : '',
        password: '',
        cpassword: '',
      },
    };
  },
  computed: {
    strong() {
      if (!this.passwordValidLength) {
        return 0;
      }
      const passwordValidNumber = this.passwordValidNumber ? 1 : 0;
      const passwordValidCharacter = this.passwordValidCharacter ? 1 : 0;
      const passwordValidUpperCase = this.passwordValidUpperCase ? 1 : 0;

      return passwordValidNumber + passwordValidCharacter + passwordValidUpperCase;
    },
    passwordValid() {
      if (this.form.password === '') {
        return null;
      }

      const passwordValidNumber = this.passwordValidNumber ? 1 : 0;
      const passwordValidCharacter = this.passwordValidCharacter ? 1 : 0;
      const passwordValidUpperCase = this.passwordValidUpperCase ? 1 : 0;

      if (!this.passwordValidLength) {
        return false;
      }

      if (passwordValidNumber + passwordValidCharacter + passwordValidUpperCase >= 2) {
        return true;
      }
      return false;
    },
    passwordValidLength() {
      if (this.form.password === '') {
        return null;
      }
      return this.form.password.length >= 8;
    },
    passwordValidNumber() {
      if (this.form.password === '') {
        return null;
      }
      return /\d/.test(this.form.password);
    },
    passwordValidCharacter() {
      if (this.form.password === '') {
        return null;
      }
      return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.form.password);
    },
    passwordValidUpperCase() {
      if (this.form.password === '') {
        return null;
      }
      return /[A-Z]/.test(this.form.password) && /[a-z]/.test(this.form.password);
    },
    equalPasswords() {
      if (this.form.password === '' || this.form.cpassword === '') {
        return null;
      }
      return this.form.cpassword === this.form.password;
    },
  },

  methods: {
    async resetPassword(evt) {
      evt.preventDefault();
      if (this.passwordValid && this.equalPasswords) {
        const fields = JSON.parse(JSON.stringify(this.form));
        this.error = false;
        this.success = false;
        this.passwords = false;
        this.passwordLength = false;
        await post('reset-password', {
          password: fields.password,
          password_confirmation: fields.cpassword,
          token: this.$route.params.token,
          email: fields.email,
        }).then((response) => {
          this.success = true;
        }).catch((error) => {
          if (error.data?.errors?.password[0] == 'The password must be at least 8 characters.') {
            this.passwordLength = true;
          } else if (error.data?.errors?.password[0]) {
            this.passwords = true;
          } else {
            this.error = true;
          }
        });
      }
    },
  },
};
</script>
