<template>
  <b-container
    fluid
    class="coming-soon"
  >
    <b-row
      class="header-cs"
    >
      <b-col
        cols="12"
        class="text-center"
      >
        <img
          class="logo-cs"
          src="@/assets/s2f_2.svg"
        >
      </b-col>

      <b-col
        cols="12"
        class="text-center title"
      >
        Brevemente
      </b-col>
    </b-row>
    <div

      class="countdown"
    >
      <div
        class="days"
        cols="1"
      >
        <div class="value">
          {{ days }}
        </div>
        <div class="label">
          Dias
        </div>
      </div>
      <div
        class="hours"
        cols="1"
      >
        <div class="value">
          {{ hours }}
        </div>
        <div class="label">
          Horas
        </div>
      </div>
      <div
        class="minutes"
        cols="1"
      >
        <div class="value">
          {{ minutes }}
        </div>
        <div class="label">
          Minutos
        </div>
      </div>
      <div
        class="seconds"
        cols="1"
      >
        <div class="value">
          {{ seconds }}
        </div>
        <div class="label">
          Segundos
        </div>
      </div>
    </div>
    <div class="buttons">
      <div
        class="button primary"
        @click="$router.push({name: 'interest'})"
      >
        Manifestar interesse no projeto <b-icon icon="chevron-right" />
      </div>
    </div>
    <b-row
      class="footer-cs"
      align-h="center"
      align-v="center"
    >
      <b-col
        cols="12"
        class="text-center"
      >
        <img
          width="50%"
          src="@/assets/logosartborad-01.svg"
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'ComingSoon',
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      countDownDate: new Date('Mar 25, 2021 15:30:00').getTime(),
    };
  },
  created() {
    this.count();
  },
  methods: {
    count() {
      const x = setInterval(() => {
        // Get today's date and time
        const now = new Date().getTime();

        if (location.href.includes('localhost')) {
          this.countDownDate = new Date('Mar 25, 2021 9:59:45').getTime();
        }

        // Find the distance between now and the count down date
        const distance = this.countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24)) < 10
          ? `0${Math.floor(distance / (1000 * 60 * 60 * 24))}`
          : Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) < 10
          ? `0${Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))}`
          : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) < 10
          ? `0${Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))}`
          : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000) < 10
          ? `0${Math.floor((distance % (1000 * 60)) / 1000)}`
          : Math.floor((distance % (1000 * 60)) / 1000);

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          this.$router.push({ name: 'home' });
        }
      }, 1000);
    },
  },
};
</script>
