<template>
  <b-container
    class="incentives-container"
    fluid
  >
    <b-row class="incentives-header">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              cols="12"
              class=" text-left"
            >
              <div class="incentives-title">
                Apoios
              </div>
              <div class="incentives-subtitle">
                Incentivos e soluções de financiamento
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="incentives-banner">
      <b-col>
        <b-container>
          <b-overlay
            :show="!loaded"
            opacity="0"
            blur="none"
            style="min-height: 20vw;"
          >
            <b-row class="incentives-container">
              <b-col
                v-if="incentives.filter(e => e.national).length > 0"
                cols="12"
                class=" text-left title"
              >
                Apoios Nacionais
              </b-col>
              <b-col
                v-if="incentives.filter(e => e.national).length > 0"
                class="incentives"
              >
                <div
                  v-for="(incentive, i) in incentives.filter(e => e.national)"
                  :key="i"
                  class="incentives-card"
                >
                  <img
                    v-if="incentive.image"
                    class="incentive-logo"
                    :src="incentive.image"
                  >
                  <div
                    v-else
                    class="incentive-title"
                  >
                    {{ incentive.incentive }}
                  </div>
                  <div class="incentive-description">
                    {{ incentive.description }}
                  </div>
                  <a
                    v-if="incentive.link"
                    target="_blank"
                    :href="incentive.link"
                    class="incentive-link"
                  >
                    <b-icon
                      icon="arrow-right"
                      scale="1.5"
                    />
                  </a>
                </div>
              </b-col>
              <b-col
                v-if="incentives.filter(e => !e.national).length > 0"
                cols="12"
                class=" text-left title mt-5"
              >
                Apoios Europeus
              </b-col>
              <b-col
                v-if="incentives.filter(e => !e.national).length > 0"
                class="incentives"
              >
                <div
                  v-for="(incentive, i) in incentives.filter(e => !e.national)"
                  :key="i"
                  class="incentives-card"
                >
                  <img
                    v-if="incentive.image"
                    class="incentive-logo"
                    :src="incentive.image"
                  >
                  <div
                    v-else
                    class="incentive-title"
                  >
                    {{ incentive.incentive }}
                  </div>
                  <div class="incentive-description">
                    {{ incentive.description }}
                  </div>
                  <div
                    v-if="incentive.link"
                    class="incentive-link"
                  >
                    <b-icon
                      icon="arrow-right"
                      scale="1.5"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { get } from '../../services/api';

export default {
  name: 'Incentives',
  data() {
    return {
      incentives: [],
      loaded: false,
    };
  },
  async created() {
    await get('incentives', {}).then((response) => {
      this.incentives = response.data;
    });
    this.loaded = true;
  },
};
</script>
