<template>
  <div class="question-container step4">
    <div class="titles-row">
      <div
        class="title"
      >
        Indique o número aproximado de trabalhadores da sua empresa em Portugal.
      </div>
      <div
        class="title"
      >
        Qual foi o valor aproximado do volume de negócios no ano transato?
      </div>
    </div>

    <div class="titles-row w-100p">
      <div class="h-100p w-100p">
        <c-radio-box-group
          :choices="marks_1"
          :selected="value_1"
          :on-click="(value) => {
            getChoice(value, 'employees')
          }"
          inline
        />
        <!--  <el-slider
            v-model="value_1"
            :disabled="$store.state.quiz.disabled"
            :marks="marks_1"
            :step="1"
            :show-stops="false"
            :min="1"
            :max="4"
            :show-tooltip="false"
            vertical
            height="400px"
          /> -->
      </div>
      <div class="h-100p w-100p">
        <c-radio-box-group
          :choices="marks_2"
          :selected="value_2"
          :on-click="(value) => {
            getChoice(value, 'turnover')
          }"
          inline
        />
        <!-- <el-slider
            v-model="value_2"
            :disabled="$store.state.quiz.disabled"
            :marks="marks_2"
            :step="1"
            :max="4"
            :show-tooltip="false"
            vertical
            height="400px"
          /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { defaults } from 'chart.js';
import RadioBoxGroup from './RadioBoxGroup.vue';

export default {
  Name: 'Step4',
  components: {
    'c-radio-box-group': RadioBoxGroup,
  },
  props: {
    quiz: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      value_1: [parseInt(this.$store.getters.companyState.employees, 10) || 1],
      marks_1: [
        {
          value: {
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }],
            label: {
              1: 'Até 9',
              2: 'De 10 a 49',
              3: 'De 50 a 249',
              4: '250 ou mais',
            },
          },
        },
      ],
      value_2: [parseInt(this.$store.getters.companyState.turnover, 10) || 0],
      marks_2: [
        {
          value: {
            ids: [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }],
            label: {
              0: 'Abaixo de 2 milhões de euros',
              1: 'Entre 2 e 10 milhões de euros',
              2: 'Entre 10 e 50 milhões de euros',
              3: 'Acima de 50 milhões de euros',
              4: 'Não especificado',
            },
          },
        },
      ],
    };
  },
  watch: {
    value_1(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$store.commit('setEmployees', { employees: newValue, update: true, quiz: this.quiz });
      }
    },
    value_2(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$store.commit('setTurnover', { turnover: newValue, update: true, quiz: this.quiz });
      }
    },
  },
  created() {
    this.$store.commit('setEmployees', { employees: this.value_1, update: true, quiz: this.quiz });
    this.$store.commit('setTurnover', { turnover: this.value_2, update: true, quiz: this.quiz });
  },
  methods: {
    getChoice(value, type) {
      switch (type) {
        case 'turnover':
          this.$store.commit('setTurnover', { turnover: value[0], update: true, quiz: this.quiz });
          break;
        case 'employees':
          this.$store.commit('setEmployees', { employees: value[0], update: true, quiz: this.quiz });
          break;
        default:
          break;
      }
    },
  },
};
</script>
