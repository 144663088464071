<template>
  <div class="sidebar">
    <div
      v-if="loaded"
      class="links"
    >
      <a
        v-for="(link, i) in links"
        :key="i"
      >
        <div
          v-if="typeof link.condition == 'function' ? resolvedPromises[link.component] : link.condition"
          :class="{'active' : state === link.state}"
          @click="setState(link.state)"
        >
          <b-icon :icon="link.icon" />{{ link.label }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    state: {
      type: Number,
      default: 1,
    },
    setState: {
      type: Function,
      default: () => {},
    },
    links: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      loaded: false,
      resolvedPromises: [],
    };
  },
  async created() {
    await this.asyncForEach(this.links, async (element) => {
      if (typeof element.condition === 'function') {
        this.resolvedPromises[element.component] = await element.condition();
      }
    });
    this.loaded = true;
  },
  methods: {
    async asyncForEach(array, callback) {
      /* eslint-disable no-await-in-loop */
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array);
      }
      /* eslint-enable no-await-in-loop */
    },
  },
};
</script>
