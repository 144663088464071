function generateChartSite(themes, dimensions, global, future, kos) {
  const svg = document.createElement('svg');
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
  svg.setAttributeNS(null, 'viewBox', '-220 -220 565 440');

  const color = ['#f2f2f2', '#00455F', '#00678E', '#008ABE', '#00ADEE', '#32BDF1', '#66CDF4'];
  const r = [30, 48, 66, 84, 102, 120, 150, 160, 170, 180, 190, 200];
  const ang = [20, 40, 60, 75, 90, 105, 120, 135, 150, 165, 180, 210, 240, 260, 280, 300, 330, 360];
  const xmlns = 'http://www.w3.org/2000/svg';

  var n = 11;
  var p1 = r[n] * Math.cos(Math.PI * (270) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[0]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[0]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-1');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[0]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[0]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[1]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[1]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-2');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[1]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[1]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[2]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[2]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-3');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[2]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[2]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[3]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[3]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-4');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[3]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[3]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[4]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[4]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-5');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[4]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[4]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[5]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[5]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-6');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[5]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[5]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[6]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[6]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-7');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[6]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[6]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[7]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[7]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-8');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[7]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[7]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[8]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[8]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-9');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[8]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[8]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[9]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[9]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-10');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[9]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[9]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[10]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[10]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-11');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[10]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[10]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[11]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[11]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-12');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[11]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[11]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[12]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[12]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-13');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[12]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[12]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[13]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[13]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-14');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[13]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[13]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[14]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[14]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-15');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[14]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[14]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[15]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[15]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-16');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[15]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[15]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[16]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[16]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-17');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[16]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[16]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[17]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[17]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-theme-18');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(path);

  var n = 5;
  var p1 = 0;
  var p2 = r[n] * Math.sin(Math.PI * (270) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[2]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[2]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', 'none');
  path.setAttributeNS(null, 'id', 'popover-dimension-1');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + 60) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + 60) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + 120) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + 120) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-dimension-2');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', 'none');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + 120) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + 120) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + 180) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + 180) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-dimension-3');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', 'none');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + 180) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + 180) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + 240) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + 240) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-dimension-4');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', 'none');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + 240) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + 240) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + 300) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + 300) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-dimension-5');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', 'none');
  svg.appendChild(path);

  var p1 = r[n] * Math.cos(Math.PI * (270 + 300) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + 300) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + 360) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + 360) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'id', 'popover-dimension-6');
  path.setAttributeNS(null, 'fill', '#ffffff');
  path.setAttributeNS(null, 'fill-opacity', '0');
  path.setAttributeNS(null, 'stroke', 'none');
  svg.appendChild(path);

  const div = document.createElement('div');
  div.appendChild(svg);

  // <rect x="-215" y="-215" rx="10" ry="10" width="10" height="10" stroke="#383838" fill="#e6ffe6" stroke-width="1"></rect><text x="-200" y="-207" font-size="8">Nível Futuro</text>

  let rt = 210;
  p1 = rt * Math.cos(Math.PI * (180 - 0) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 0) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 20) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 20) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE = document.createElementNS(xmlns, 'defs');
  const gradE = document.createElementNS(xmlns, 'path');
  gradE.setAttributeNS(null, 'id', 'svgE');
  gradE.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defsE.appendChild(gradE);
  svg.appendChild(defsE);
  const gE = document.createElementNS(xmlns, 'g');
  gE.setAttributeNS(null, 'fill', '#383838');
  const textE = document.createElementNS(xmlns, 'text');
  textE.setAttributeNS(null, 'font-size', '50%');
  textE.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE = document.createElementNS(xmlns, 'textPath');
  textpathE.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE');
  textpathE.setAttributeNS(null, 'startOffset', '50%');
  textpathE.innerHTML = 'Estratégia';
  textE.appendChild(textpathE);
  gE.appendChild(textE);
  svg.appendChild(gE);
  p1 = rt * Math.cos(Math.PI * (180 - 20) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 20) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 40) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 40) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE1 = document.createElementNS(xmlns, 'defs');
  const gradE1 = document.createElementNS(xmlns, 'path');
  gradE1.setAttributeNS(null, 'id', 'svgE1');
  gradE1.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defsE1.appendChild(gradE1);
  svg.appendChild(defsE1);
  const gE1 = document.createElementNS(xmlns, 'g');
  gE1.setAttributeNS(null, 'fill', '#383838');
  const textE1 = document.createElementNS(xmlns, 'text');
  textE1.setAttributeNS(null, 'font-size', '50%');
  textE1.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE1 = document.createElementNS(xmlns, 'textPath');
  textpathE1.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE1');
  textpathE1.setAttributeNS(null, 'startOffset', '50%');
  textpathE1.innerHTML = 'Investimentos';
  textE1.appendChild(textpathE1);
  gE1.appendChild(textE1);
  svg.appendChild(gE1);
  p1 = rt * Math.cos(Math.PI * (180 - 40) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 40) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 60) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 60) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE2 = document.createElementNS(xmlns, 'defs');
  const gradE2 = document.createElementNS(xmlns, 'path');
  gradE2.setAttributeNS(null, 'id', 'svgE2');
  gradE2.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defsE2.appendChild(gradE2);
  svg.appendChild(defsE2);
  const gE2 = document.createElementNS(xmlns, 'g');
  gE2.setAttributeNS(null, 'fill', '#383838');
  const textE2 = document.createElementNS(xmlns, 'text');
  textE2.setAttributeNS(null, 'font-size', '50%');
  textE2.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE2 = document.createElementNS(xmlns, 'textPath');
  textpathE2.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE2');
  textpathE2.setAttributeNS(null, 'startOffset', '50%');
  textpathE2.innerHTML = 'Inovação';
  textE2.appendChild(textpathE2);
  gE2.appendChild(textE2);
  svg.appendChild(gE2);
  p1 = rt * Math.cos(Math.PI * (180 - 60) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 60) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 75) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 75) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE3 = document.createElementNS(xmlns, 'defs');
  const gradE3 = document.createElementNS(xmlns, 'path');
  gradE3.setAttributeNS(null, 'id', 'svgE3');
  gradE3.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defsE3.appendChild(gradE3);
  svg.appendChild(defsE3);
  const gE3 = document.createElementNS(xmlns, 'g');
  gE3.setAttributeNS(null, 'fill', '#383838');
  const textE3 = document.createElementNS(xmlns, 'text');
  textE3.setAttributeNS(null, 'font-size', '50%');
  textE3.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE3 = document.createElementNS(xmlns, 'textPath');
  textpathE3.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE3');
  textpathE3.setAttributeNS(null, 'startOffset', '50%');
  textpathE3.innerHTML = 'Infraestrutura';
  textE3.appendChild(textpathE3);
  gE3.appendChild(textE3);
  svg.appendChild(gE3);
  p1 = rt * Math.cos(Math.PI * (180 - 75) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 75) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 90) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 90) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE4 = document.createElementNS(xmlns, 'defs');
  const gradE4 = document.createElementNS(xmlns, 'path');
  gradE4.setAttributeNS(null, 'id', 'svgE4');
  gradE4.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defsE4.appendChild(gradE4);
  svg.appendChild(defsE4);
  const gE4 = document.createElementNS(xmlns, 'g');
  gE4.setAttributeNS(null, 'fill', '#383838');
  const textE4 = document.createElementNS(xmlns, 'text');
  textE4.setAttributeNS(null, 'font-size', '50%');
  textE4.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE4 = document.createElementNS(xmlns, 'textPath');
  textpathE4.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE4');
  textpathE4.setAttributeNS(null, 'startOffset', '50%');
  textpathE4.innerHTML = 'Modelação';
  textE4.appendChild(textpathE4);
  gE4.appendChild(textE4);
  svg.appendChild(gE4);
  p1 = rt * Math.cos(Math.PI * (180 - 90) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 90) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 105) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 105) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE5 = document.createElementNS(xmlns, 'defs');
  const gradE5 = document.createElementNS(xmlns, 'path');
  gradE5.setAttributeNS(null, 'id', 'svgE5');
  gradE5.setAttributeNS(null, 'd', `M${p4},${p3} A${rt},${rt},0,0,0,${p2},${p1}`);
  defsE5.appendChild(gradE5);
  svg.appendChild(defsE5);
  const gE5 = document.createElementNS(xmlns, 'g');
  gE5.setAttributeNS(null, 'fill', '#383838');
  const textE5 = document.createElementNS(xmlns, 'text');
  textE5.setAttributeNS(null, 'font-size', '50%');
  textE5.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE5 = document.createElementNS(xmlns, 'textPath');
  textpathE5.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE5');
  textpathE5.setAttributeNS(null, 'startOffset', '50%');
  textpathE5.innerHTML = 'Dados';
  textE5.appendChild(textpathE5);
  gE5.appendChild(textE5);
  svg.appendChild(gE5);
  p1 = rt * Math.cos(Math.PI * (180 - 105) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 105) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 120) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 120) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE6 = document.createElementNS(xmlns, 'defs');
  const gradE6 = document.createElementNS(xmlns, 'path');
  gradE6.setAttributeNS(null, 'id', 'svgE6');
  gradE6.setAttributeNS(null, 'd', `M${p4},${p3} A${rt},${rt},0,0,0,${p2},${p1}`);
  defsE6.appendChild(gradE6);
  svg.appendChild(defsE6);
  const gE6 = document.createElementNS(xmlns, 'g');
  gE6.setAttributeNS(null, 'fill', '#383838');
  const textE6 = document.createElementNS(xmlns, 'text');
  textE6.setAttributeNS(null, 'font-size', '50%');
  textE6.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE6 = document.createElementNS(xmlns, 'textPath');
  textpathE6.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE6');
  textpathE6.setAttributeNS(null, 'startOffset', '50%');
  textpathE6.innerHTML = 'Sistemas TI';
  textE6.appendChild(textpathE6);
  gE6.appendChild(textE6);
  svg.appendChild(gE6);
  p1 = rt * Math.cos(Math.PI * (180 - 120) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 120) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 135) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 135) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE7 = document.createElementNS(xmlns, 'defs');
  const gradE7 = document.createElementNS(xmlns, 'path');
  gradE7.setAttributeNS(null, 'id', 'svgE7');
  gradE7.setAttributeNS(null, 'd', `M${p4},${p3} A${rt},${rt},0,0,0,${p2},${p1}`);
  defsE7.appendChild(gradE7);
  svg.appendChild(defsE7);
  const gE7 = document.createElementNS(xmlns, 'g');
  gE7.setAttributeNS(null, 'fill', '#383838');
  const textE7 = document.createElementNS(xmlns, 'text');
  textE7.setAttributeNS(null, 'font-size', '50%');
  textE7.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE7 = document.createElementNS(xmlns, 'textPath');
  textpathE7.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE7');
  textpathE7.setAttributeNS(null, 'startOffset', '50%');
  textpathE7.innerHTML = 'Informação';
  textE7.appendChild(textpathE7);
  gE7.appendChild(textE7);
  svg.appendChild(gE7);
  p1 = rt * Math.cos(Math.PI * (180 - 135) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 135) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 150) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 150) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE8 = document.createElementNS(xmlns, 'defs');
  const gradE8 = document.createElementNS(xmlns, 'path');
  gradE8.setAttributeNS(null, 'id', 'svgE8');
  gradE8.setAttributeNS(null, 'd', `M${p4},${p3} A${rt},${rt},0,0,0,${p2},${p1}`);
  defsE8.appendChild(gradE8);
  svg.appendChild(defsE8);
  const gE8 = document.createElementNS(xmlns, 'g');
  gE8.setAttributeNS(null, 'fill', '#383838');
  const textE8 = document.createElementNS(xmlns, 'text');
  textE8.setAttributeNS(null, 'font-size', '50%');
  textE8.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE8 = document.createElementNS(xmlns, 'textPath');
  textpathE8.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE8');
  textpathE8.setAttributeNS(null, 'startOffset', '50%');
  textpathE8.innerHTML = 'Autónomos';
  textE8.appendChild(textpathE8);
  gE8.appendChild(textE8);
  svg.appendChild(gE8);
  p1 = rt * Math.cos(Math.PI * (180 - 150) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 150) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 165) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 165) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE9 = document.createElementNS(xmlns, 'defs');
  const gradE9 = document.createElementNS(xmlns, 'path');
  gradE9.setAttributeNS(null, 'id', 'svgE9');
  gradE9.setAttributeNS(null, 'd', `M${p4},${p3} A${rt},${rt},0,0,0,${p2},${p1}`);
  defsE9.appendChild(gradE9);
  svg.appendChild(defsE9);
  const gE9 = document.createElementNS(xmlns, 'g');
  gE9.setAttributeNS(null, 'fill', '#383838');
  const textE9 = document.createElementNS(xmlns, 'text');
  textE9.setAttributeNS(null, 'font-size', '50%');
  textE9.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE9 = document.createElementNS(xmlns, 'textPath');
  textpathE9.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE9');
  textpathE9.setAttributeNS(null, 'startOffset', '50%');
  textpathE9.innerHTML = 'Segurança TI';
  textE9.appendChild(textpathE9);
  gE9.appendChild(textE9);
  svg.appendChild(gE9);
  p1 = rt * Math.cos(Math.PI * (180 - 165) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 165) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 180) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 180) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE10 = document.createElementNS(xmlns, 'defs');
  const gradE10 = document.createElementNS(xmlns, 'path');
  gradE10.setAttributeNS(null, 'id', 'svgE10');
  gradE10.setAttributeNS(null, 'd', `M${p4},${p3} A${rt},${rt},0,0,0,${p2},${p1}`);
  defsE10.appendChild(gradE10);
  svg.appendChild(defsE10);
  const gE10 = document.createElementNS(xmlns, 'g');
  gE10.setAttributeNS(null, 'fill', '#383838');
  const textE10 = document.createElementNS(xmlns, 'text');
  textE10.setAttributeNS(null, 'font-size', '50%');
  textE10.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE10 = document.createElementNS(xmlns, 'textPath');
  textpathE10.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE10');
  textpathE10.setAttributeNS(null, 'startOffset', '50%');
  textpathE10.innerHTML = 'Cloud';
  textE10.appendChild(textpathE10);
  gE10.appendChild(textE10);
  svg.appendChild(gE10);
  p1 = rt * Math.cos(Math.PI * (180 - 180) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 180) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 210) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 210) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE11 = document.createElementNS(xmlns, 'defs');
  const gradE11 = document.createElementNS(xmlns, 'path');
  gradE11.setAttributeNS(null, 'id', 'svgE11');
  gradE11.setAttributeNS(null, 'd', `M${p4},${p3} A${rt},${rt},0,0,0,${p2},${p1}`);
  defsE11.appendChild(gradE11);
  svg.appendChild(defsE11);
  const gE11 = document.createElementNS(xmlns, 'g');
  gE11.setAttributeNS(null, 'fill', '#383838');
  const textE11 = document.createElementNS(xmlns, 'text');
  textE11.setAttributeNS(null, 'font-size', '50%');
  textE11.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE11 = document.createElementNS(xmlns, 'textPath');
  textpathE11.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE11');
  textpathE11.setAttributeNS(null, 'startOffset', '50%');
  textpathE11.innerHTML = 'TIC';
  textE11.appendChild(textpathE11);
  gE11.appendChild(textE11);
  svg.appendChild(gE11);
  p1 = rt * Math.cos(Math.PI * (180 - 210) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 210) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 240) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 240) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE12 = document.createElementNS(xmlns, 'defs');
  const gradE12 = document.createElementNS(xmlns, 'path');
  gradE12.setAttributeNS(null, 'id', 'svgE12');
  gradE12.setAttributeNS(null, 'd', `M${p4},${p3} A${rt},${rt},0,0,0,${p2},${p1}`);
  defsE12.appendChild(gradE12);
  svg.appendChild(defsE12);
  const gE12 = document.createElementNS(xmlns, 'g');
  gE12.setAttributeNS(null, 'fill', '#383838');
  const textE12 = document.createElementNS(xmlns, 'text');
  textE12.setAttributeNS(null, 'font-size', '50%');
  textE12.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE12 = document.createElementNS(xmlns, 'textPath');
  textpathE12.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE12');
  textpathE12.setAttributeNS(null, 'startOffset', '50%');
  textpathE12.innerHTML = 'Análise de dados';
  textE12.appendChild(textpathE12);
  gE12.appendChild(textE12);
  svg.appendChild(gE12);
  p1 = rt * Math.cos(Math.PI * (180 - 240) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 240) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 260) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 260) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE13 = document.createElementNS(xmlns, 'defs');
  const gradE13 = document.createElementNS(xmlns, 'path');
  gradE13.setAttributeNS(null, 'id', 'svgE13');
  gradE13.setAttributeNS(null, 'd', `M${p4},${p3} A${rt},${rt},0,0,0,${p2},${p1}`);
  defsE13.appendChild(gradE13);
  svg.appendChild(defsE13);
  const gE13 = document.createElementNS(xmlns, 'g');
  gE13.setAttributeNS(null, 'fill', '#383838');
  const textE13 = document.createElementNS(xmlns, 'text');
  textE13.setAttributeNS(null, 'font-size', '50%');
  textE13.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE13 = document.createElementNS(xmlns, 'textPath');
  textpathE13.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE13');
  textpathE13.setAttributeNS(null, 'startOffset', '50%');
  textpathE13.innerHTML = 'Novos Serviços';
  textE13.appendChild(textpathE13);
  gE13.appendChild(textE13);
  svg.appendChild(gE13);
  p1 = rt * Math.cos(Math.PI * (180 - 260) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 260) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 280) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 280) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE14 = document.createElementNS(xmlns, 'defs');
  const gradE14 = document.createElementNS(xmlns, 'path');
  gradE14.setAttributeNS(null, 'id', 'svgE14');
  gradE14.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defsE14.appendChild(gradE14);
  svg.appendChild(defsE14);
  const gE14 = document.createElementNS(xmlns, 'g');
  gE14.setAttributeNS(null, 'fill', '#383838');
  const textE14 = document.createElementNS(xmlns, 'text');
  textE14.setAttributeNS(null, 'font-size', '50%');
  textE14.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE14 = document.createElementNS(xmlns, 'textPath');
  textpathE14.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE14');
  textpathE14.setAttributeNS(null, 'startOffset', '50%');
  textpathE14.innerHTML = 'Receita';
  textE14.appendChild(textpathE14);
  gE14.appendChild(textE14);
  svg.appendChild(gE14);
  p1 = rt * Math.cos(Math.PI * (180 - 280) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 280) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 300) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 300) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE15 = document.createElementNS(xmlns, 'defs');
  const gradE15 = document.createElementNS(xmlns, 'path');
  gradE15.setAttributeNS(null, 'id', 'svgE15');
  gradE15.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defsE15.appendChild(gradE15);
  svg.appendChild(defsE15);
  const gE15 = document.createElementNS(xmlns, 'g');
  gE15.setAttributeNS(null, 'fill', '#383838');
  const textE15 = document.createElementNS(xmlns, 'text');
  textE15.setAttributeNS(null, 'font-size', '50%');
  textE15.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE15 = document.createElementNS(xmlns, 'textPath');
  textpathE15.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE15');
  textpathE15.setAttributeNS(null, 'startOffset', '50%');
  textpathE15.innerHTML = 'Nível de Utilização';
  textE15.appendChild(textpathE15);
  gE15.appendChild(textE15);
  svg.appendChild(gE15);
  p1 = rt * Math.cos(Math.PI * (180 - 300) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 300) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 330) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 330) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE16 = document.createElementNS(xmlns, 'defs');
  const gradE16 = document.createElementNS(xmlns, 'path');
  gradE16.setAttributeNS(null, 'id', 'svgE16');
  gradE16.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defsE16.appendChild(gradE16);
  svg.appendChild(defsE16);
  const gE16 = document.createElementNS(xmlns, 'g');
  gE16.setAttributeNS(null, 'fill', '#383838');
  const textE16 = document.createElementNS(xmlns, 'text');
  textE16.setAttributeNS(null, 'font-size', '50%');
  textE16.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE16 = document.createElementNS(xmlns, 'textPath');
  textpathE16.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE16');
  textpathE16.setAttributeNS(null, 'startOffset', '50%');
  textpathE16.innerHTML = 'Competências';
  textE16.appendChild(textpathE16);
  gE16.appendChild(textE16);
  svg.appendChild(gE16);
  p1 = rt * Math.cos(Math.PI * (180 - 330) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 330) / 180);

  p3 = rt * Math.cos(Math.PI * (180 - 360) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 360) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defsE17 = document.createElementNS(xmlns, 'defs');
  const gradE17 = document.createElementNS(xmlns, 'path');
  gradE17.setAttributeNS(null, 'id', 'svgE17');
  gradE17.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defsE17.appendChild(gradE17);
  svg.appendChild(defsE17);
  const gE17 = document.createElementNS(xmlns, 'g');
  gE17.setAttributeNS(null, 'fill', '#383838');
  const textE17 = document.createElementNS(xmlns, 'text');
  textE17.setAttributeNS(null, 'font-size', '50%');
  textE17.setAttributeNS(null, 'text-anchor', 'middle');
  const textpathE17 = document.createElementNS(xmlns, 'textPath');
  textpathE17.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgE17');
  textpathE17.setAttributeNS(null, 'startOffset', '50%');
  textpathE17.innerHTML = 'Aquisição';
  textE17.appendChild(textpathE17);
  gE17.appendChild(textE17);
  svg.appendChild(gE17);
  var line00 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line00.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[0]) / 180));
  line00.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[0]) / 180));
  line00.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[0]) / 180));
  line00.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[0]) / 180));
  line00.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line00);
  const line02 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line02.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[1]) / 180));
  line02.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[1]) / 180));
  line02.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[1]) / 180));
  line02.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[1]) / 180));
  line02.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line02);
  const line03 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line03.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[2]) / 180));
  line03.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[2]) / 180));
  line03.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[2]) / 180));
  line03.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[2]) / 180));
  line03.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line03);
  const line04 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line04.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[3]) / 180));
  line04.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[3]) / 180));
  line04.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[3]) / 180));
  line04.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[3]) / 180));
  line04.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line04);
  const line05 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line05.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[4]) / 180));
  line05.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[4]) / 180));
  line05.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[4]) / 180));
  line05.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[4]) / 180));
  line05.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line05);
  const line06 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line06.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[5]) / 180));
  line06.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[5]) / 180));
  line06.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[5]) / 180));
  line06.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[5]) / 180));
  line06.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line06);
  const line07 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line07.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[6]) / 180));
  line07.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[6]) / 180));
  line07.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[6]) / 180));
  line07.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[6]) / 180));
  line07.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line07);
  const line08 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line08.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[7]) / 180));
  line08.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[7]) / 180));
  line08.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[7]) / 180));
  line08.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[7]) / 180));
  line08.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line08);
  const line09 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line09.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[8]) / 180));
  line09.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[8]) / 180));
  line09.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[8]) / 180));
  line09.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[8]) / 180));
  line09.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line09);
  const line11 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line11.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[9]) / 180));
  line11.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[9]) / 180));
  line11.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[9]) / 180));
  line11.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[9]) / 180));
  line11.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line11);
  const line12 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line12.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[10]) / 180));
  line12.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[10]) / 180));
  line12.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[10]) / 180));
  line12.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[10]) / 180));
  line12.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line12);
  const line13 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line13.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[11]) / 180));
  line13.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[11]) / 180));
  line13.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[11]) / 180));
  line13.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[11]) / 180));
  line13.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line13);
  const line14 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line14.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[12]) / 180));
  line14.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[12]) / 180));
  line14.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[12]) / 180));
  line14.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[12]) / 180));
  line14.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line14);
  const line15 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line15.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[13]) / 180));
  line15.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[13]) / 180));
  line15.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[13]) / 180));
  line15.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[13]) / 180));
  line15.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line15);
  const line16 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line16.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[14]) / 180));
  line16.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[14]) / 180));
  line16.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[14]) / 180));
  line16.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[14]) / 180));
  line16.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line16);
  const line17 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line17.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[15]) / 180));
  line17.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[15]) / 180));
  line17.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[15]) / 180));
  line17.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[15]) / 180));
  line17.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line17);
  const line18 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line18.setAttributeNS(null, 'x1', r[6] * Math.cos(Math.PI * (270 + ang[16]) / 180));
  line18.setAttributeNS(null, 'y1', r[6] * Math.sin(Math.PI * (270 + ang[16]) / 180));
  line18.setAttributeNS(null, 'x2', r[11] * Math.cos(Math.PI * (270 + ang[16]) / 180));
  line18.setAttributeNS(null, 'y2', r[11] * Math.sin(Math.PI * (270 + ang[16]) / 180));
  line18.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line18);
  var n = themes[0];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[0]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[0]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[1]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[1];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[0]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[0]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[1]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[1]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[1]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[2];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[1]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[1]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[2]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[2]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[1]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[3];
  /* if (document.getElementById("Q0FI0").checked) {
    n = 5;
    color[2] = "#ffffe6";
  }; */
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[2]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[2]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[3]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[3]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[2]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[4];
  /* if (document.getElementById("Q0FI0").checked) {
    n = 5;
    color[2] = "#ffffe6";
  }; */
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[3]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[3]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[4]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[4]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[2]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[5];
  /* if (document.getElementById("Q0FI0").checked) {
    n = 5;
    color[2] = "#ffffe6";
  }; */
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[4]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[4]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[5]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[5]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[2]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[6];
  /* if (document.getElementById("Q0FI0").checked) {
    n = 5;
    color[2] = "#ffffe6";
  }; */
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[5]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[5]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[6]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[6]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[2]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[7];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[6]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[6]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[7]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[7]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[3]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[8];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[7]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[7]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[8]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[8]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[3]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[9];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[8]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[8]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[9]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[9]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[3]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[10];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[9]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[9]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[10]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[10]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[3]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[11];
  /* if (document.getElementById("Q0PI0").checked) {
    n = 5;
    color[4] = "#ffffe6";
  }; */
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[10]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[10]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[11]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[11]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[4]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[12];
  /* if (document.getElementById("Q0PI0").checked) {
    n = 5;
    color[4] = "#ffffe6";
  }; */
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[11]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[11]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[12]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[12]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[4]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[13];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[12]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[12]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[13]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[13]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[5]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[14];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[13]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[13]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[14]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[14]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[5]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[15];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[14]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[14]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[15]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[15]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[5]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[16];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[15]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[15]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[16]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[16]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[6]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = themes[17];
  n += 6;
  var p1 = r[n] * Math.cos(Math.PI * (270 + ang[16]) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + ang[16]) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[17]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[17]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[6]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  const circle01 = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circle01.setAttributeNS(null, 'r', 150);
  circle01.setAttributeNS(null, 'fill', '#ffffff');
  circle01.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(circle01);

  var line00 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line00.setAttributeNS(null, 'x1', r[0] * Math.cos(Math.PI * (270) / 180));
  line00.setAttributeNS(null, 'y1', r[0] * Math.sin(Math.PI * (270) / 180));
  line00.setAttributeNS(null, 'x2', r[5] * Math.cos(Math.PI * (270) / 180));
  line00.setAttributeNS(null, 'y2', r[5] * Math.sin(Math.PI * (270) / 180));
  line00.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line00);
  const line10 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line10.setAttributeNS(null, 'x1', r[0] * Math.cos(Math.PI * (270 + ang[2]) / 180));
  line10.setAttributeNS(null, 'y1', r[0] * Math.sin(Math.PI * (270 + ang[2]) / 180));
  line10.setAttributeNS(null, 'x2', r[5] * Math.cos(Math.PI * (270 + ang[2]) / 180));
  line10.setAttributeNS(null, 'y2', r[5] * Math.sin(Math.PI * (270 + ang[2]) / 180));
  line10.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line10);
  const line20 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line20.setAttributeNS(null, 'x1', r[0] * Math.cos(Math.PI * (270 + ang[6]) / 180));
  line20.setAttributeNS(null, 'y1', r[0] * Math.sin(Math.PI * (270 + ang[6]) / 180));
  line20.setAttributeNS(null, 'x2', r[5] * Math.cos(Math.PI * (270 + ang[6]) / 180));
  line20.setAttributeNS(null, 'y2', r[5] * Math.sin(Math.PI * (270 + ang[6]) / 180));
  line20.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line20);
  const line30 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line30.setAttributeNS(null, 'x1', r[0] * Math.cos(Math.PI * (270 + ang[10]) / 180));
  line30.setAttributeNS(null, 'y1', r[0] * Math.sin(Math.PI * (270 + ang[10]) / 180));
  line30.setAttributeNS(null, 'x2', r[5] * Math.cos(Math.PI * (270 + ang[10]) / 180));
  line30.setAttributeNS(null, 'y2', r[5] * Math.sin(Math.PI * (270 + ang[10]) / 180));
  line30.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line30);
  const line40 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line40.setAttributeNS(null, 'x1', r[0] * Math.cos(Math.PI * (270 + ang[15]) / 180));
  line40.setAttributeNS(null, 'y1', r[0] * Math.sin(Math.PI * (270 + ang[15]) / 180));
  line40.setAttributeNS(null, 'x2', r[5] * Math.cos(Math.PI * (270 + ang[15]) / 180));
  line40.setAttributeNS(null, 'y2', r[5] * Math.sin(Math.PI * (270 + ang[15]) / 180));
  line40.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line40);
  const line50 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line50.setAttributeNS(null, 'x1', r[0] * Math.cos(Math.PI * (270 + ang[12]) / 180));
  line50.setAttributeNS(null, 'y1', r[0] * Math.sin(Math.PI * (270 + ang[12]) / 180));
  line50.setAttributeNS(null, 'x2', r[5] * Math.cos(Math.PI * (270 + ang[12]) / 180));
  line50.setAttributeNS(null, 'y2', r[5] * Math.sin(Math.PI * (270 + ang[12]) / 180));
  line50.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(line50);
  const circlep01 = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circlep01.setAttributeNS(null, 'r', r[0]);
  circlep01.setAttributeNS(null, 'fill', 'none');
  circlep01.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(circlep01);
  const circlep02 = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circlep02.setAttributeNS(null, 'r', r[1]);
  circlep02.setAttributeNS(null, 'fill', 'none');
  circlep02.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(circlep02);
  const circlep03 = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circlep03.setAttributeNS(null, 'r', r[2]);
  circlep03.setAttributeNS(null, 'fill', 'none');
  circlep03.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(circlep03);
  const circlep04 = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circlep04.setAttributeNS(null, 'r', r[3]);
  circlep04.setAttributeNS(null, 'fill', 'none');
  circlep04.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(circlep04);
  const circlep05 = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circlep05.setAttributeNS(null, 'r', r[4]);
  circlep05.setAttributeNS(null, 'fill', 'none');
  circlep05.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(circlep05);
  const circlep06 = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circlep06.setAttributeNS(null, 'r', r[5]);
  circlep06.setAttributeNS(null, 'fill', 'none');
  circlep06.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(circlep06);

  if (future[0].value) {
    var n = future[0].value;
    var p1 = 0;
    var p2 = r[n] * Math.sin(Math.PI * (270) / 180);
    var p3 = r[n] * Math.cos(Math.PI * (270 + ang[2]) / 180);
    var p4 = r[n] * Math.sin(Math.PI * (270 + ang[2]) / 180);
    var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
    path.setAttributeNS(null, 'fill', '#FFB703');
    path.setAttributeNS(null, 'stroke', color[0]);
    svg.appendChild(path);
  }
  if (!kos['Infraestrutura Inteligente'] && future[1].value) {
    var n = future[1].value;
    var p1 = r[n] * Math.cos(Math.PI * (270 + 60) / 180);
    var p2 = r[n] * Math.sin(Math.PI * (270 + 60) / 180);
    var p3 = r[n] * Math.cos(Math.PI * (270 + 120) / 180);
    var p4 = r[n] * Math.sin(Math.PI * (270 + 120) / 180);
    var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
    path.setAttributeNS(null, 'fill', '#FFB703');
    path.setAttributeNS(null, 'stroke', color[0]);
    svg.appendChild(path);
  }
  if (future[2].value) {
    var n = future[2].value;
    var p1 = r[n] * Math.cos(Math.PI * (270 + 120) / 180);
    var p2 = r[n] * Math.sin(Math.PI * (270 + 120) / 180);
    var p3 = r[n] * Math.cos(Math.PI * (270 + 180) / 180);
    var p4 = r[n] * Math.sin(Math.PI * (270 + 180) / 180);
    var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
    path.setAttributeNS(null, 'fill', '#FFB703');
    path.setAttributeNS(null, 'stroke', color[0]);
    svg.appendChild(path);
  }
  if (!kos['Produtos Inteligentes'] && future[3].value) {
    var n = future[3].value;
    var p1 = r[n] * Math.cos(Math.PI * (270 + 180) / 180);
    var p2 = r[n] * Math.sin(Math.PI * (270 + 180) / 180);
    var p3 = r[n] * Math.cos(Math.PI * (270 + 240) / 180);
    var p4 = r[n] * Math.sin(Math.PI * (270 + 240) / 180);
    var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
    path.setAttributeNS(null, 'fill', '#FFB703');
    path.setAttributeNS(null, 'stroke', color[0]);
    svg.appendChild(path);
  }
  if (future[4].value) {
    var n = future[4].value;
    var p1 = r[n] * Math.cos(Math.PI * (270 + 240) / 180);
    var p2 = r[n] * Math.sin(Math.PI * (270 + 240) / 180);
    var p3 = r[n] * Math.cos(Math.PI * (270 + 300) / 180);
    var p4 = r[n] * Math.sin(Math.PI * (270 + 300) / 180);
    var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
    path.setAttributeNS(null, 'fill', '#FFB703');
    path.setAttributeNS(null, 'stroke', color[0]);
    svg.appendChild(path);
  }
  if (future[5].value) {
    var n = future[5].value;
    var p1 = r[n] * Math.cos(Math.PI * (270 + 300) / 180);
    var p2 = r[n] * Math.sin(Math.PI * (270 + 300) / 180);
    var p3 = r[n] * Math.cos(Math.PI * (270 + 360) / 180);
    var p4 = r[n] * Math.sin(Math.PI * (270 + 360) / 180);
    var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
    path.setAttributeNS(null, 'fill', '#FFB703');
    path.setAttributeNS(null, 'stroke', color[0]);
    svg.appendChild(path);
  }

  var n = dimensions[0];
  var p1 = 0;
  var p2 = r[n] * Math.sin(Math.PI * (270) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + ang[2]) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + ang[2]) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[1]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = dimensions[1];
  if (kos['Infraestrutura Inteligente']) {
    n = 5;
    color[2] = '#BDBDBD';
  }
  var p1 = r[n] * Math.cos(Math.PI * (270 + 60) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + 60) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + 120) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + 120) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[2]);
  path.setAttributeNS(null, 'fill-opacity', kos['Infraestrutura Inteligente'] ? '0.33' : '1');
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = dimensions[2];
  var p1 = r[n] * Math.cos(Math.PI * (270 + 120) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + 120) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + 180) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + 180) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[3]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = dimensions[3];
  if (kos['Produtos Inteligentes']) {
    n = 5;
    color[4] = '#BDBDBD';
  }
  var p1 = r[n] * Math.cos(Math.PI * (270 + 180) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + 180) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + 240) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + 240) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[4]);
  path.setAttributeNS(null, 'fill-opacity', kos['Produtos Inteligentes'] ? '0.33' : '1');
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = dimensions[4];
  var p1 = r[n] * Math.cos(Math.PI * (270 + 240) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + 240) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + 300) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + 300) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[5]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  var n = dimensions[5];
  var p1 = r[n] * Math.cos(Math.PI * (270 + 300) / 180);
  var p2 = r[n] * Math.sin(Math.PI * (270 + 300) / 180);
  var p3 = r[n] * Math.cos(Math.PI * (270 + 360) / 180);
  var p4 = r[n] * Math.sin(Math.PI * (270 + 360) / 180);
  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', `M0,0L${p1},${p2}A${r[n]},${r[n]},0,0,1,${p3},${p4} Z`);
  path.setAttributeNS(null, 'fill', color[6]);
  path.setAttributeNS(null, 'stroke', color[0]);
  svg.appendChild(path);
  const circle02 = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circle02.setAttributeNS(null, 'r', 30);
  circle02.setAttributeNS(null, 'fill', '#ffffff');
  circle02.setAttributeNS(null, 'stroke', '#ffffff');
  svg.appendChild(circle02);
  const textf = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  textf.setAttributeNS(null, 'id', 'text1');
  textf.setAttributeNS(null, 'x', -6);
  textf.setAttributeNS(null, 'y', 6);
  textf.setAttributeNS(null, 'class', 'number');
  textf.setAttributeNS(null, 'fill', '#383838');
  textf.innerHTML = Math.floor(global[0].classification);
  svg.appendChild(textf);
  rt = 130;
  var p1 = rt * Math.cos(Math.PI * (180 - 0) / 180);
  var p2 = rt * Math.sin(Math.PI * (180 - 0) / 180);
  var p3 = rt * Math.cos(Math.PI * (180 - 60) / 180);
  var p4 = rt * Math.sin(Math.PI * (180 - 60) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }

  const defs1 = document.createElementNS(xmlns, 'defs');
  const grad1 = document.createElementNS(xmlns, 'path');
  grad1.setAttributeNS(null, 'id', 'svgD1');
  grad1.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defs1.appendChild(grad1);
  svg.appendChild(defs1);
  const g1 = document.createElementNS(xmlns, 'g');
  g1.setAttributeNS(null, 'fill', '#383838');
  const text1 = document.createElementNS(xmlns, 'text');
  text1.setAttributeNS(null, 'font-size', '50%');
  text1.setAttributeNS(null, 'text-anchor', 'middle');
  text1.setAttributeNS(null, 'font-weight', 'bold ');
  const textpath1 = document.createElementNS(xmlns, 'textPath');
  textpath1.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgD1');
  textpath1.setAttributeNS(null, 'startOffset', '50%');
  textpath1.innerHTML = 'Estratégia e Organização';
  text1.appendChild(textpath1);
  g1.appendChild(text1);
  svg.appendChild(g1);
  p1 = rt * Math.cos(Math.PI * (180 - 60) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 60) / 180);
  p3 = rt * Math.cos(Math.PI * (180 - 120) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 120) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defs2 = document.createElementNS(xmlns, 'defs');
  const grad2 = document.createElementNS(xmlns, 'path');
  grad2.setAttributeNS(null, 'id', 'svgD2');
  grad2.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defs2.appendChild(grad2);
  svg.appendChild(defs2);
  const g2 = document.createElementNS(xmlns, 'g');
  g2.setAttributeNS(null, 'fill', '#383838');
  const text2 = document.createElementNS(xmlns, 'text');
  text2.setAttributeNS(null, 'font-size', '50%');
  text2.setAttributeNS(null, 'text-anchor', 'middle');
  text2.setAttributeNS(null, 'font-weight', 'bold ');
  const textpath2 = document.createElementNS(xmlns, 'textPath');
  textpath2.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgD2');
  textpath2.setAttributeNS(null, 'startOffset', '50%');
  textpath2.innerHTML = 'Infraestrutura Inteligente';
  text2.appendChild(textpath2);
  g2.appendChild(text2);
  svg.appendChild(g2);
  p1 = rt * Math.cos(Math.PI * (180 - 180) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 180) / 180);
  p3 = rt * Math.cos(Math.PI * (180 - 120) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 120) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defs3 = document.createElementNS(xmlns, 'defs');
  const grad3 = document.createElementNS(xmlns, 'path');
  grad3.setAttributeNS(null, 'id', 'svgD3');
  grad3.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,0,${p4},${p3}`);
  defs3.appendChild(grad3);
  svg.appendChild(defs3);
  const g3 = document.createElementNS(xmlns, 'g');
  g3.setAttributeNS(null, 'fill', '#383838');
  const text3 = document.createElementNS(xmlns, 'text');
  text3.setAttributeNS(null, 'font-size', '50%');
  text3.setAttributeNS(null, 'text-anchor', 'middle');
  text3.setAttributeNS(null, 'font-weight', 'bold ');
  const textpath3 = document.createElementNS(xmlns, 'textPath');
  textpath3.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgD3');
  textpath3.setAttributeNS(null, 'startOffset', '50%');
  textpath3.innerHTML = 'Operações Inteligentes';
  text3.appendChild(textpath3);
  g3.appendChild(text3);
  svg.appendChild(g3);
  p1 = rt * Math.cos(Math.PI * (180 - 240) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 240) / 180);
  p3 = rt * Math.cos(0);
  p4 = rt * Math.sin(0);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defs4 = document.createElementNS(xmlns, 'defs');
  const grad4 = document.createElementNS(xmlns, 'path');
  grad4.setAttributeNS(null, 'id', 'svgD4');
  grad4.setAttributeNS(null, 'd', `M${p2},${p1}A${rt},${rt},0,0,0,${p4},${p3}`);
  defs4.appendChild(grad4);
  svg.appendChild(defs4);
  const g4 = document.createElementNS(xmlns, 'g');
  g4.setAttributeNS(null, 'fill', '#383838');
  const text4 = document.createElementNS(xmlns, 'text');
  text4.setAttributeNS(null, 'font-size', '50%');
  text4.setAttributeNS(null, 'text-anchor', 'middle');
  text4.setAttributeNS(null, 'font-weight', 'bold ');
  const textpath4 = document.createElementNS(xmlns, 'textPath');
  textpath4.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgD4');
  textpath4.setAttributeNS(null, 'startOffset', '50%');
  textpath4.innerHTML = 'Produtos Inteligentes';
  text4.appendChild(textpath4);
  g4.appendChild(text4);
  svg.appendChild(g4);
  p1 = rt * Math.cos(Math.PI * (180 - 240) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 240) / 180);
  p3 = rt * Math.cos(Math.PI * (180 - 300) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 300) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defs5 = document.createElementNS(xmlns, 'defs');
  const grad5 = document.createElementNS(xmlns, 'path');
  grad5.setAttributeNS(null, 'id', 'svgD5');
  grad5.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defs5.appendChild(grad5);
  svg.appendChild(defs5);
  const g5 = document.createElementNS(xmlns, 'g');
  g5.setAttributeNS(null, 'fill', '#383838');
  const text5 = document.createElementNS(xmlns, 'text');
  text5.setAttributeNS(null, 'font-size', '50%');
  text5.setAttributeNS(null, 'text-anchor', 'middle');
  text5.setAttributeNS(null, 'font-weight', 'bold ');
  const textpath5 = document.createElementNS(xmlns, 'textPath');
  textpath5.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgD5');
  textpath5.setAttributeNS(null, 'startOffset', '50%');
  textpath5.innerHTML = 'Serviços baseados em Dados';
  text5.appendChild(textpath5);
  g5.appendChild(text5);
  svg.appendChild(g5);
  p1 = rt * Math.cos(Math.PI * (180 - 300) / 180);
  p2 = rt * Math.sin(Math.PI * (180 - 300) / 180);
  p3 = rt * Math.cos(Math.PI * (180 - 360) / 180);
  p4 = rt * Math.sin(Math.PI * (180 - 360) / 180);
  if (p1 > 0 && p3 > 0) {
    if (p2 < 0) {
      p2 -= 5;
    } else {
      p2 += 5;
    }
    if (p4 < 0) {
      p4 -= 5;
    } else {
      p4 += 5;
    }
  }
  const defs6 = document.createElementNS(xmlns, 'defs');
  const grad6 = document.createElementNS(xmlns, 'path');
  grad6.setAttributeNS(null, 'id', 'svgD6');
  grad6.setAttributeNS(null, 'd', `M${p2},${p1} A${rt},${rt},0,0,1,${p4},${p3}`);
  defs6.appendChild(grad6);
  svg.appendChild(defs6);
  const g6 = document.createElementNS(xmlns, 'g');
  g6.setAttributeNS(null, 'fill', '#383838');
  const text6 = document.createElementNS(xmlns, 'text');
  text6.setAttributeNS(null, 'font-size', '50%');
  text6.setAttributeNS(null, 'text-anchor', 'middle');
  text6.setAttributeNS(null, 'font-weight', 'bold ');
  text6.setAttributeNS(null, 'color', 'red');
  const textpath6 = document.createElementNS(xmlns, 'textPath');
  textpath6.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#svgD6');
  textpath6.setAttributeNS(null, 'startOffset', '50%');
  textpath6.innerHTML = 'Recursos Humanos';
  text6.appendChild(textpath6);
  g6.appendChild(text6);
  svg.appendChild(g6);

  svg.innerHTML += '<style>text {font-family: sans; font-weight: 300; font-size: 9px; color: #383838} .text2{font-size: 12px} .number {font-size: 18px; color: #383838} </style><circle r="160" stroke="#f2f2f2" fill="none"></circle><circle r="170" stroke="#f2f2f2" fill="none"></circle><circle r="180" stroke="#f2f2f2" fill="none"></circle><circle r="190" stroke="#f2f2f2" fill="none"></circle><circle r="200" stroke="#f2f2f2" fill="none"></circle><line x1="0" y1="150" x2="0" y2="200" stroke="#f2f2f2"></line><line x1="0" y1="-150" x2="0" y2="-200" stroke="#f2f2f2"></line>';
  svg.innerHTML += '<rect x="250" y="-30" rx="10" ry="10" width="14" height="14" stroke="#008ABE" fill="#008ABE" stroke-width="1"></rect>';
  svg.innerHTML += '<text x="270" y="-20" font-size="12" class="text2" fill="#383838">Nível Atual</text>';
  svg.innerHTML += '<rect x="250" y="-10" rx="10" ry="10" width="14" height="14" stroke="#FFB703" fill="#FFB703" stroke-width="1"></rect>';
  svg.innerHTML += '<text x="270" y="0" font-size="12" fill="#383838" class="text2" >Nível Futuro</text>';
  svg.innerHTML += '<rect x="250" y="10" rx="10" ry="10" width="14" height="14" stroke="#BDBDBD" fill="#BDBDBD" stroke-width="1"></rect>';
  svg.innerHTML += '<text x="270" y="20" font-size="12" class="text2" fill="#383838">N/A</text>';
  return div;
}

export { generateChartSite };
