<template>
  <b-container
    fluid
    class="interest"
  >
    <b-container class="interest-container">
      <b-overlay
        :show="overlay"
      >
        <div
          v-if="error || success || errorName || errorCompany"
          class="feedback-row pt-5"
        >
          <div
            v-if="errorName"
            class="error-danger"
          >
            Já existe um utilizador registado com este email!
          </div>
          <div
            v-else-if="errorCompany"
            class="error-danger"
          >
            Já existe uma empresa registada com este nome!
          </div>
          <div
            v-else-if="success"
            class="error-success"
          >
            O formulário foi submetido com sucesso. Consulte a sua caixa de email para mais Informações.
          </div>
          <div
            v-else-if="error"
            class="error-danger"
          >
            Ocorreu um erro ao submeter o formulário. Por favor tente mais tarde.
          </div>
        </div>
        <b-row class="p-5">
          <b-col
            cols="12"
            class="text-center title"
          >
            Manifestação de interesse
            <b-button
              v-if="success"
              class="button secondary"
              @click="$router.push({name: 'home'})"
            >
              {{ success ? 'Fechar' : 'Cancelar' }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form
              @submit="onSubmit"
              @reset="onReset"
            >
              <b-row align-v="start">
                <b-col
                  sm="12"
                  md="6"
                  align-self="stretch"
                  style="display: flex; flex-direction: column; justify-content: space-between;"
                >
                  <b-form-group
                    id="input-group-1"
                    label="Empresa *"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="form.company"
                      placeholder="Nome da empresa"
                      required
                    />
                  </b-form-group>
                  <b-form-group
                    id="input-group-2"
                    label="Responsável *"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="form.name"
                      placeholder="Nome do responsável"
                      required
                    />
                  </b-form-group>

                  <b-form-group
                    id="input-group-3"
                    label="Email *"
                    label-for="input-3"
                  >
                    <b-form-input
                      id="input-3"
                      v-model="form.email"
                      type="email"
                      placeholder="Email do responsável"
                      required
                    />
                  </b-form-group>

                  <b-form-group
                    id="input-group-4"
                    label="Telemóvel"
                    label-for="input-4"
                  >
                    <b-form-input
                      id="input-4"
                      v-model="form.phone"
                      type="text"
                      step="1"
                      :formatter="phoneFormatter"
                      placeholder="Nº de telemóvel"
                    />
                  </b-form-group>

                  <b-form-group
                    id="input-group-7"
                    label="Trabalhadores *"
                    label-for="input-7"
                  >
                    <b-form-input
                      id="input-7"
                      v-model="form.workers"
                      type="number"
                      step="1"
                      placeholder="Nº de trabalhadores"
                      required
                    />
                  </b-form-group>
                  <b-form-group
                    label="Setor de atividade *"
                    label-for="input-5"
                  >
                    <b-form-select
                      v-model="form.activity_sector"
                      :options="choices"
                      required
                      label-for="input-5"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group
                    id="input-group-4"
                    style="display: flex; flex-direction: column; justify-content: space-between;"
                    :label="`Selecione a região *`"
                  >
                    <h3
                      v-if="!form.region"
                      class="text-danger"
                    >
                      <b>Nenhuma região selecionada</b>
                    </h3>
                    <h3 v-else>
                      <b>{{ regions[form.region] }}</b>
                    </h3>
                    <b-row
                      align-v="center"
                      align-h="center"
                    >
                      <b-col cols="12">
                        <Map
                          :interest-function="(value)=> {
                            form.region = value
                          }"
                        />
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Tenho interesse em: (escolher uma ou mais opções)"
              >
                <b-form-checkbox-group
                  id="checkbox-group-2 "
                  v-model="form.interests"
                  :aria-describedby="ariaDescribedby"
                  name="interest"
                  stacked
                >
                  <b-form-checkbox value="diagnostic">
                    Realizar diagnóstico assistido de maturidade digital da empresa
                  </b-form-checkbox>
                  <b-form-checkbox value="sessions">
                    Sessões de Informação e capacitação para apoio à transição digital da empresa
                  </b-form-checkbox>
                  <b-form-checkbox value="share_good_practices">
                    Estar envolvido em iniciativas de partilha de boas práticas e acesso a ferramentas i4.0
                  </b-form-checkbox>
                  <b-form-checkbox value="receive_info">
                    Receber informação sobre estratégias i4.0 dedicadas ao setor de atividade da empresa
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <b-form-invalid-feedback
                :state="!needOne"
                style=" background-color: #ca0000; border-radius: 8px; color: #fff; font-size: 12px; padding: 8px;"
              >
                É necessário escolher pelo menos um interesse.
              </b-form-invalid-feedback>
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label=" Ao submeter esta  manifestação de interesse autoriza a utilização dos dados às entidades promotoras do projeto para comunicação relacionada unicamente com as atividades descritas no projeto"
                required="true"
              >
                <b-form-radio
                  v-model="form.consent"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="true"
                >
                  Sim
                </b-form-radio>
                <b-form-radio
                  v-model="form.consent"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="false"
                >
                  Não
                </b-form-radio>
              </b-form-group>
              <b-form-invalid-feedback
                :state="!consentError"
                style=" background-color: #ca0000; border-radius: 8px; color: #fff; font-size: 12px; padding: 8px;"
              >
                É necessário autorizar a utilização dos dados às entidades promotoras do projeto para comunicação relacionada unicamente com as atividades descritas no projeto.
              </b-form-invalid-feedback>
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label=" Aceito que dados recolhidos serão tratados de acordo com as normas de privacidade e proteção de dados em vigor e em cumprimento do RGPD."
                required="true"
              >
                <b-form-radio
                  v-model="treatment"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios2"
                  value="true"
                >
                  Sim
                </b-form-radio>
                <b-form-radio
                  v-model="treatment"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios2"
                  value="false"
                >
                  Não
                </b-form-radio>
              </b-form-group>
              <div class="mb-3">
                * Campo de preenchimento obrigatório
              </div>
              <!-- Ao submeter esta  manifestação de interesse autoriza a utilização dos dados às entidades promotoras do projeto para comunicação relacionada unicamente com as atividades descritas no projeto -->
              <div class="buttons">
                <b-button
                  v-if="treatment === 'true'"
                  type="submit"
                  variant="primary"
                  class="button primary"
                >
                  Submeter
                </b-button>
                <b-button
                  class="button secondary"
                  @click="$router.push({name: 'home'})"
                >
                  {{ success ? 'Fechar' : 'Cancelar' }}
                </b-button>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </b-overlay>
    </b-container>
  </b-container>
</template>

<script>
import { post } from '../../services/api';
import Map from '../ui/Map/index.vue';

export default {
  name: 'ExpressionOfInterest',
  components: {
    Map,
  },
  data() {
    return {
      consentError: false,
      error: false,
      success: false,
      errorName: false,
      errorCompany: false,
      needOne: false,
      treatment: null,
      choices: [
        { text: 'Agricultura, produção animal, caça e actividades dos serviços relacionados', value: 1 },
        { text: 'Silvicultura e exploração florestal', value: 2 },
        { text: 'Pesca e aquicultura', value: 3 },
        { text: 'Extracção de hulha e lenhite', value: 5 },
        { text: 'Extracção de petróleo bruto e gás natural', value: 6 },
        { text: 'Extracção e preparação de minérios metálicos', value: 7 },
        { text: 'Outras indústrias extractivas', value: 8 },
        { text: 'Actividades dos serviços relacionados com as indústrias extractivas', value: 9 },
        { text: 'Indústrias alimentares', value: 10 },
        { text: 'Indústria das bebidas', value: 11 },
        { text: 'Indústria do tabaco', value: 12 },
        { text: 'Fabricação de têxteis', value: 13 },
        { text: 'Indústria do vestuário', value: 14 },
        { text: 'Indústria do couro e dos produtos do couro', value: 15 },
        { text: 'Indústrias da madeira e da cortiça e suas obras, excepto mobiliário; fabricação de obras de cestaria e de espartaria', value: 16 },
        { text: 'Fabricação de pasta, de papel, cartão e seus artigos', value: 17 },
        { text: 'Impressão e reprodução de suportes gravados', value: 18 },
        { text: 'Fabricação de coque, de produtos petrolíferos refinados e de aglomerados de combustíveis', value: 19 },
        { text: 'Fabricação de produtos químicos e de fibras sintéticas ou artificiais, excepto produtos farmacêuticos', value: 20 },
        { text: 'Fabricação de produtos farmacêuticos de base e de preparações farmacêuticas', value: 21 },
        { text: 'Fabricação de artigos de borracha e de matérias plásticas', value: 22 },
        { text: 'Fabricação de outros produtos minerais não metálicos', value: 23 },
        { text: 'Indústrias metalúrgicas de base', value: 24 },
        { text: 'Fabricação de produtos metálicos, excepto máquinas e equipamentos', value: 25 },
        { text: 'Fabricação de equipamentos informáticos, equipamento para comunicações e produtos electrónicos e ópticos', value: 26 },
        { text: 'Fabricação de equipamento eléctrico', value: 27 },
        { text: 'Fabricação de máquinas e de equipamentos, n.e.', value: 28 },
        { text: 'Fabricação de veículos automóveis, reboques, semi-reboques e componentes para veículos automóveis', value: 29 },
        { text: 'Fabricação de outro equipamento de transporte', value: 30 },
        { text: 'Fabricação de mobiliário e de colchões', value: 31 },
        { text: 'Outras indústrias transformadoras', value: 32 },
        { text: 'Reparação, manutenção e instalação de máquinas e equipamentos', value: 33 },
        { text: 'Electricidade, gás, vapor, água quente e fria e ar frio', value: 35 },
        { text: 'Captação, tratamento e distribuição de água', value: 36 },
        { text: 'Recolha, drenagem e tratamento de águas residuais', value: 37 },
        { text: 'Recolha, tratamento e eliminação de resíduos; valorização de materiais', value: 38 },
        { text: 'Descontaminação e actividades similares', value: 39 },
        { text: 'Promoção imobiliária (desenvolvimento de projectos de edifícios); construção de edifícios', value: 41 },
        { text: 'Engenharia civil', value: 42 },
        { text: 'Actividades especializadas de construção', value: 43 },
        { text: 'Comércio, manutenção e reparação, de veículos automóveis e motociclos', value: 45 },
        { text: 'Comércio por grosso (inclui agentes), excepto de veículos automóveis e motociclos', value: 46 },
        { text: 'Comércio a retalho, excepto de veículos automóveis e motociclos', value: 47 },
        { text: 'Transportes terrestres e transportes por oleodutos ou gasodutos', value: 49 },
        { text: 'Transportes por água', value: 50 },
        { text: 'Transportes aéreos', value: 51 },
        { text: 'Armazenagem e actividades auxiliares dos transportes(inclui manuseamento)', value: 52 },
        { text: 'Actividades postais e de courier', value: 53 },
        { text: 'Alojamento', value: 55 },
        { text: 'Restauração e similares', value: 56 },
        { text: 'Actividades de edição', value: 58 },
        { text: 'Actividades cinematográficas, de vídeo, de produção de programas de televisão, de gravação de som e de edição de música', value: 59 },
        { text: 'Actividades de rádio e de televisão', value: 60 },
        { text: 'Telecomunicações', value: 61 },
        { text: 'Consultoria e programação informática e actividades relacionadas', value: 62 },
        { text: 'Actividades dos serviços de informação', value: 63 },
        { text: 'Actividades de serviços financeiros, excepto seguros e fundos de pensões', value: 64 },
        { text: 'Seguros, resseguros e fundos de pensões, excepto segurança social obrigatória', value: 65 },
        { text: 'Actividades auxiliares de serviços financeiros e dos seguros', value: 66 },
        { text: 'Actividades imobiliárias', value: 68 },
        { text: 'Actividades jurídicas e de contabilidade', value: 69 },
        { text: 'Actividades das sedes sociais e de consultoria para a gestão', value: 70 },
        { text: 'Actividades de arquitectura, de engenharia e técnicas afins; actividades de ensaios e de análises técnicas', value: 71 },
        { text: 'Actividades de Investigação científica e de desenvolvimento', value: 72 },
        { text: 'Publicidade, estudos de mercado e sondagens de opinião', value: 73 },
        { text: 'Outras actividades de consultoria, científicas, técnicas e similares', value: 74 },
        { text: 'Actividades veterinárias', value: 75 },
        { text: 'Actividades de aluguer', value: 77 },
        { text: 'Actividades de emprego', value: 78 },
        { text: 'Agências de viagem, operadores turísticos, outros serviços de reservas e actividades relacionadas', value: 79 },
        { text: 'Actividades de investigação e segurança', value: 80 },
        { text: 'Actividades relacionadas com edifícios, plantação e manutenção de jardins', value: 81 },
        { text: 'Actividades de serviços administrativos e de apoio prestados às empresas', value: 82 },
        { text: 'Administração Pública e Defesa; Segurança Social Obrigatória', value: 84 },
        { text: 'Educação', value: 85 },
        { text: 'Actividades de saúde humana', value: 86 },
        { text: 'Actividades de apoio social com alojamento', value: 87 },
        { text: 'Actividades de apoio social sem alojamento', value: 88 },
        { text: 'Actividades de teatro, de música, de dança e outras actividades artísticas e literárias', value: 90 },
        { text: 'Actividades das bibliotecas, arquivos, museus e outras actividades culturais', value: 91 },
        { text: 'Lotarias e outros jogos de aposta', value: 92 },
        { text: 'Actividades desportivas, de diversão e recreativas', value: 93 },
        { text: 'Actividades das organizações associativas', value: 94 },
        { text: 'Reparação de computadores e de bens de uso pessoal e doméstico', value: 95 },
        { text: 'Outras actividades de serviços pessoais', value: 96 },
        { text: 'Actividades das famílias empregadoras de pessoal doméstico', value: 97 },
        { text: 'Actividades de produção de bens e serviços pelas famílias para uso próprio', value: 98 },
        { text: 'Actividades dos organismos internacionais e outras instituições extraterritoriais', value: 99 },
      ],
      regions: {
        'R-1': 'Alto Minho',
        'R-2': 'Cávado',
        'R-3': 'Ave',
        'R-4': 'Área Metropolitana do Porto',
        'R-5': 'Alto Tâmega',
        'R-6': 'Tâmega e Sousa',
        'R-7': 'Douro',
        'R-8': 'Terras de Trás-os-Montes',
        'R-9': 'Oeste',
        'R-10': 'Região de Aveiro',
        'R-11': 'Região de Coimbra',
        'R-12': 'Região de Leiria',
        'R-13': 'Viseu Dão Lafões',
        'R-14': 'Beira Baixa',
        'R-15': 'Médio Tejo',
        'R-16': 'Beiras e Serra da Estrela',
        'R-17': 'Área Metroplotinada de Lisboa',
        'R-18': 'Alentejo Litoral',
        'R-19': 'Baixo Alentejo',
        'R-20': 'Lezíria do Tejo',
        'R-21': 'Alto Alentejo',
        'R-22': 'Alentejo Central',
        'R-23': 'Algarve',
        'R-24': 'Região Autónoma dos Açores',
        'R-25': 'Região Autónoma da Madeira',
      },
      form: {
        company: '',
        name: '',
        email: '',
        workers: '',
        phone: '',
        activity_sector: '',
        region: '',
        interests: [],
        consent: null,
      },
      overlay: false,
    };
  },
  methods: {
    phoneFormatter(value) {
      return value.split('').filter((el) => [...Array(10).keys()].includes(parseInt(el, 10))).join('').slice(0, 9)
        .split(/(.{3})/)
        .filter((O) => O)
        .join(' ');
    },
    async onSubmit(event) {
      event.preventDefault();
      this.consentError = false;
      if (this.form.consent === 'false') {
        this.consentError = true;
      } else {
        this.success = false;
        this.error = false;
        this.errorName = false;
        this.errorCompany = false;
        this.needOne = false;
        if (this.form.interests.length === 0) {
          this.needOne = true;
        } else {
          this.overlay = true;
          await post('auth/pre-register', {
            company: this.form.company,
            name: this.form.name,
            email: this.form.email,
            employees: this.form.workers,
            phone: this.form.phone,
            activity_sector: this.form.activity_sector,
            region: this.form.region,
            interests: this.form.interests.join(', '),
            consent: true,
          }).then((response) => {
            this.success = true;
            window.scrollTo(0, 0);
          }).catch((error) => {
            if (
              error?.data?.email
            && error?.data?.email[0]
            && error?.data?.email[0] === 'The email has already been taken.'
            ) {
              this.errorName = true;
            } if (
              error?.data?.company
            && error?.data?.company[0]
            && error?.data?.company[0] === 'The company has already been taken.'
            ) {
              this.errorCompany = true;
            } else if (error?.data?.company === 'Company already exist') {
              this.errorCompany = true;
            } else {
              this.error = true;
            }
          });
          window.scrollTo(0, 0);
          this.overlay = false;
        }
      }
    },
    onReset(event) {
      event.preventDefault();
      this.form.company = '';
      this.form.name = '';
      this.form.email = '';
      this.form.workers = '';
      this.form.phone = '';
      this.form.activity_sector = '';
      this.form.region = '';
      this.form.interests = [];
      this.form.consent = false;
    },
  },
};
</script>
