<template>
  <video-resource
    v-if="resource.format === 'video'"
    :resource="resource"
    :set-loading="setLoading"
    :thumbnail="thumbnail"
    :show-sectors="showSectors"
  />
  <document-resource
    v-else-if="resource.format === 'document'"
    :resource="resource"
    :set-loading="setLoading"
    :fill="fill"
    :thumbnail="thumbnail"
    :show-sectors="showSectors"
  />
  <link-resource
    v-else-if="resource.format === 'link' || resource.format === null"
    :resource="resource"
    :set-loading="setLoading"
    :thumbnail="thumbnail"
    :show-sectors="showSectors"
  />
</template>
<script>
import VideoResource from './Video.vue';
import DocumentResource from './Document.vue';
import LinkResource from './Link.vue';

export default {
  name: 'Resource',
  components: { VideoResource, DocumentResource, LinkResource },
  props: {
    resource: {
      type: Object,
      required: true,
    },
    setLoading: {
      type: Function,
      default: (value) => {
        this.loading = value;
      },
    },
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
    thumbnail: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSectors: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
