<template>
  <div class="question-container">
    <div>
      <div
        class="title"
      >
        Tipifique atividades i4.0 planeadas/em curso/implementadas na sua empresa.
      </div>
    </div>
    <!--  <c-level-group
      v-if="selected"
      :choices="choices"
      :max="Math.max(...choices[0].value.value)"
      :min="Math.min(...choices[0].value.value)"
      :selected="selected"
      :on-click="getChoice"
      :not-enabled="$store.state.quiz.disabled"
    /> -->
    <div class="titles-row w-100p">
      <c-radio-box-group
        v-if="selected"
        :choices="choices"
        :selected="selected"
        :on-click="getChoice"
      />
    </div>
  </div>
</template>

<script>
// import LevelGroup from './LevelGroup.vue';
import RadioBoxGroup from './RadioBoxGroup.vue';

export default {
  Name: 'Step8',
  components: {
    // 'c-level-group': LevelGroup,
    'c-radio-box-group': RadioBoxGroup,
  },
  props: {
    quiz: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selected: this.$store.getters.companyState.status || [1, 1, 1, 1, 1, 1],
      choices: [
        {
          label: 'Digitalização de processos com a implementação de informação',
          value: {
            label: {
              1: 'Não planeada', 2: 'Planeada', 3: 'Em curso', 4: 'Implementada', 5: 'Não relevante',
            },
            value: [1, 2, 3, 4, 5],
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
          },
        },
        {
          label: 'Integração entre sistemas e/ou equipamentos com recurso à IoT (Internet das coisas)',
          value: {
            label: {
              1: 'Não planeada', 2: 'Planeada', 3: 'Em curso', 4: 'Implementada', 5: 'Não relevante',
            },
            value: [1, 2, 3, 4, 5],
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
          },
        },
        {
          label: 'Implementação de sistemas que permitam o controlo eficiente dos processos, produtos e serviços e a análise do desempenho em tempo real',
          value: {
            label: {
              1: 'Não planeada', 2: 'Planeada', 3: 'Em curso', 4: 'Implementada', 5: 'Não relevante',
            },
            value: [1, 2, 3, 4, 5],
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
          },
        },
        {
          label: 'Contratação de técnicos essenciais para a transformação digital',
          value: {
            label: {
              1: 'Não planeada', 2: 'Planeada', 3: 'Em curso', 4: 'Implementada', 5: 'Não relevante',
            },
            value: [1, 2, 3, 4, 5],
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
          },
        },
        {
          label: 'Contratação de consultores para a transformação digital',
          value: {
            label: {
              1: 'Não planeada', 2: 'Planeada', 3: 'Em curso', 4: 'Implementada', 5: 'Não relevante',
            },
            value: [1, 2, 3, 4, 5],
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
          },
        },
        {
          label: 'Reconversão de técnicos para dar resposta à transformação digital',
          value: {
            label: {
              1: 'Não planeada', 2: 'Planeada', 3: 'Em curso', 4: 'Implementada', 5: 'Não relevante',
            },
            value: [1, 2, 3, 4, 5],
            ids: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
          },
        },
      ],
    };
  },
  methods: {
    getChoice(value) {
      this.$store.commit('setStatus', { status: value, update: true, quiz: this.quiz });
    },
  },
};
</script>
