<template>
  <div :style="cssVars">
    <svg
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      width="320"
      :height="height"
      viewBox="0 -28 240 320"
    >

      <g>
        <path
          class="cls-2 dimension_1"
          d="M145.95,97.925l.48-.277,74.359-42.931C200.429,23.27,165.765,1.96,126,0V86.385c7.947,1.423,14.94,5.612,19.95,11.54Z"
        />
        <path
          class="cls-3 dimension_4"
          d="M94.05,141.746l-.48,.277L19.211,184.955c20.359,31.447,55.024,52.756,94.789,54.716v-86.385c-7.948-1.423-14.94-5.611-19.951-11.54Z"
        />
        <path
          class="cls-3 dimension_5"
          d="M88.033,131.363c-1.306-3.602-2.033-7.479-2.033-11.528s.726-7.926,2.033-11.528l-.463-.268L13.201,65.103C4.772,81.516,0,100.116,0,119.835s4.772,38.319,13.201,54.732l74.368-42.937,.463-.268Z"
        />
        <path
          class="cls-3 dimension_6"
          d="M94.05,97.925c5.01-5.928,12.003-10.117,19.95-11.54V0C74.235,1.96,39.571,23.27,19.211,54.717l74.358,42.931,.48,.277Z"
        />
        <path
          class="cls-3 dimension_3"
          d="M145.95,141.746c-5.01,5.928-12.003,10.117-19.95,11.54v86.385c39.765-1.96,74.429-23.27,94.789-54.716l-74.359-42.931-.48-.277Z"
        />
      </g>
      <path
        class="cls-1 dimension_2"
        d="M152.43,108.307c1.307,3.602,2.033,7.479,2.033,11.528s-.726,7.926-2.033,11.528l.463,.268,97.864,56.502c10.688-20.421,16.742-43.651,16.742-68.297s-6.054-47.877-16.742-68.298l-97.864,56.502-.463,.268Z"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'Dim1',
  props: {
    height: {
      type: Number,
      default: 200,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        '--color': this.color,
      };
    },
  },
  mounted() {
    const dim1 = document.querySelectorAll('.dimension_1');
    dim1.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 1);
    }));

    const dim2 = document.querySelectorAll('.dimension_2');
    dim2.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 2);
    }));

    const dim3 = document.querySelectorAll('.dimension_3');
    dim3.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 3);
    }));

    const dim4 = document.querySelectorAll('.dimension_4');
    dim4.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 4);
    }));

    const dim5 = document.querySelectorAll('.dimension_5');
    dim5.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 5);
    }));

    const dim6 = document.querySelectorAll('.dimension_6');
    dim6.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 6);
    }));
  },
};
</script>
<style lang="scss" scoped>
  .cls-1,
  .cls-2 {
    fill: var(--color);
    cursor: pointer;

    &:hover {
      fill: var(--color);
      opacity: .32;
    }
  }

  .cls-1 {
    animation: blinkingFill 2s ease-in-out;
  }

  .cls-3 {
    fill: #A8AAAA;
    cursor: pointer;

    &:hover {
      fill: var(--color);
      opacity: 1;
    }
  }

  .cls-2 {
    opacity: .32;
    cursor: pointer;

     &:hover {
      fill: var(--color);
      opacity: 1;
    }
  }

  @keyframes blinkingFill {
    0%		{ fill: var(--color);}
    25%		{ fill: #A8AAAA;}
    50%		{ fill: var(--color)}
    75%		{ fill: #A8AAAA;}
    100%	{ fill: var(--color)}
  }
</style>
