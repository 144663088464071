<template>
  <b-container
    class="industry-container"
    fluid
  >
    <b-row class="industry-header">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              cols="12"
              class=" text-left"
            >
              <div class="industry-title">
                Indústria 4.0
              </div>
              <div class="industry-subtitle">
                Numa sociedade 5.0
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="industry-banner">
      <b-container>
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            class="title"
          >
            A 4ª Revolução Industrial
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            class="device-youtube"
          >
            <div class="video-wrapper">
              <youtube
                ref="youtube"
                :video-id="videoId"
                :fit-parent="fitParent"
                :resize="resize"
                :resize-delay="resizeDelay"
                @playing="playing"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            class="description"
          >
            A 4ª revolução industrial, também designada de “transformação digital” é caracterizada pela introdução de sistemas ciberfísicos - inteligentes e interligados - nos processos de produção; na cadeia de valor; na relação com o cliente e no modelo de negócio. O seu âmbito é vasto e não se refere apenas a máquinas e sistemas inteligentes e conectados: vai desde o sequenciamento genético à nanotecnologia, passando pelas energias renováveis e pela computação quântica. É a fusão destas tecnologias e a interação entre os domínios físico, digital e biológico que torna esta 4ª revolução diferente das revoluções anteriores.
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <b-row class="industry-container">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              cols="12"
            >
              <div
                class="industry-tabs"
              >
                <div
                  class="tab"
                  :class="state === 1 && 'active'"
                  @click="setState(1)"
                >
                  Evolução
                </div>
                <div
                  class="tab"
                  :class="state === 2 && 'active'"
                  @click="setState(2)"
                >
                  Conceitos
                </div>
                <div
                  class="tab"
                  :class="state === 3 && 'active'"
                  @click="setState(3)"
                >
                  Aplicações
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="state === 1"
            class="industry-timeline"
          >
            <b-col>
              <div
                class="industry-timeline-container"
              >
                <div
                  class="industry-timeline-container-left"
                >
                  <div class="industry-timeline-container-card">
                    <img
                      src="../../assets/Elementos/3 Industria 4.0/Evolução/Industria-1.0.jpg"
                      width="100%"
                    >
                  </div>

                  <div
                    class="industry-timeline-container-card"
                  >
                    <div class="industry-timeline-container-card-title">
                      Indústria 2.0
                    </div>
                    <div class="industry-timeline-container-card-subtitle">
                      Fim do século XX
                    </div>
                    <div class="industry-timeline-container-card-description">
                      A introdução da energia elétrica, dos combustíveis fósseis e da produção em série, permitiram explorar novos mercados e acelerar o ritmo industrial.
                    </div>
                  </div>

                  <div
                    class="industry-timeline-container-card"
                  >
                    <img
                      src="../../assets/Elementos/3 Industria 4.0/Evolução/Industria-3.0.jpg"
                      width="100%"
                    >
                  </div>

                  <div
                    class="industry-timeline-container-card"
                  >
                    <div class="industry-timeline-container-card-title">
                      Indústria 4.0
                    </div>
                    <div class="industry-timeline-container-card-subtitle">
                      2011
                    </div>
                    <div class="industry-timeline-container-card-description">
                      A “transformação digital” é caracterizada pela introdução de sistemas ciberfísicos, inteligentes e interligados, nos processos de produção, na cadeia de valor, na relação com o cliente e no modelo de negócio.
                    </div>
                  </div>

                  <div class="industry-timeline-container-card">
                    <img
                      src="../../assets/Elementos/3 Industria 4.0/Evolução/Sociedade-5.0.jpg"
                      width="100%"
                    >
                  </div>
                </div>
                <div
                  class="industry-timeline-container-center"
                >
                  <div
                    class="line"
                  >
                    <div
                      class="bullet"
                    />
                    <div
                      class="bullet"
                    />
                    <div
                      class="bullet"
                    />
                    <div
                      class="bullet"
                    />
                    <div
                      class="bullet"
                    />
                  </div>
                </div>
                <div
                  class="industry-timeline-container-right"
                >
                  <div
                    class="industry-timeline-container-card"
                  >
                    <div class="industry-timeline-container-card-title">
                      Indústria 1.0
                    </div>
                    <div class="industry-timeline-container-card-subtitle">
                      Fim do século XVIII
                    </div>
                    <div class="industry-timeline-container-card-description">
                      Surge com a investigação da máquina a vapor, a construção dos caminhos de ferro e a mecanização dos processos de produção.
                    </div>
                  </div>
                  <div
                    class="industry-timeline-container-card"
                  >
                    <img
                      src="../../assets/Elementos/3 Industria 4.0/Evolução/Industria-2.0.jpg"
                      width="100%"
                    >
                  </div>
                  <div
                    class="industry-timeline-container-card"
                  >
                    <div class="industry-timeline-container-card-title">
                      Indústria 3.0
                    </div>
                    <div class="industry-timeline-container-card-subtitle">
                      1950
                    </div>
                    <div class="industry-timeline-container-card-description">
                      Também designada por “revolução dos computadores” esta surge com a automação dos processos através da introdução da eletrónica, robótica e computação.
                    </div>
                  </div>
                  <div
                    class="industry-timeline-container-card"
                  >
                    <img
                      src="../../assets/Elementos/3 Industria 4.0/Evolução/Industria-4.0.jpg"
                      width="100%"
                    >
                  </div>
                  <div
                    class="industry-timeline-container-card"
                  >
                    <div class="industry-timeline-container-card-title">
                      Sociedade 5.0
                    </div>
                    <div class="industry-timeline-container-card-subtitle">
                      2017
                    </div>
                    <div class="industry-timeline-container-card-description">
                      O Japão apresentou como “próximo passo” da Indústria 4.0, o conceito de Sociedade 5.0 que posiciona o ser humano no centro da inovação e transformação tecnológica.
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-else-if="state === 2"
            class="industry-concepts"
          >
            <b-col
              cols="12"
              class="industry-concepts-title"
            >
              Principais Conceitos i4.0
            </b-col>
            <b-col class="industry-concepts-cards">
              <div
                class="industry-concepts-card"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Conceitos/big-data.jpg')+')'}"
              >
                Big Data
              </div>
              <div
                class="industry-concepts-card industry-concepts-card-half"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Conceitos/fabrica-inteligente.jpg')+')'}"
              >
                Infraestrutura Inteligente
              </div>
              <div
                class="industry-concepts-card industry-concepts-card-half"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Conceitos/inteligencia-artificial.jpg')+')'}"
              >
                Inteligência Artificail
              </div>
              <div
                class="industry-concepts-card"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Conceitos/internet-das-coisas.jpg')+')'}"
              >
                Internet das Coisas
              </div>
              <div
                class="industry-concepts-card"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Conceitos/internet-industrial.jpg')+')'}"
              >
                Internet Industrial
              </div>
              <div
                class="industry-concepts-card industry-concepts-card-half"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Conceitos/producao-aditiva.jpg')+')'}"
              >
                Produção Aditiva
              </div>
              <div
                class="industry-concepts-card industry-concepts-card-half"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Conceitos/realidade-aumentada.jpg')+')'}"
              >
                Realidade Aumentada
              </div>
              <div
                class="industry-concepts-card "
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Conceitos/sistemas-ciber-fisicos.jpg')+')'}"
              >
                Sistemas Ciber-Físicos de Produção
              </div>
            </b-col>
          </b-row>
          <b-row
            v-else-if="state === 3"
            class="industry-applications"
          >
            <b-col
              cols="12"
              class="industry-applications-title"
            >
              Exemplos de transformação digital
            </b-col>
            <b-col class="industry-applications-cards">
              <div
                class="industry-applications-card"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Aplicações/agroalimentar.jpg')+')'}"
              >
                Agroalimentar
              </div>
              <div
                class="industry-applications-card"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Aplicações/moldes-plasticos.jpg')+')'}"
              >
                Moldes & Plásticos
              </div>
              <div
                class="industry-applications-card industry-applications-card-half"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Aplicações/turismo.jpg')+')'}"
              >
                Turismo
              </div>
              <div
                class="industry-applications-card industry-applications-card-half"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Aplicações/saude.jpg')+')'}"
              >
                Saúde
              </div>
              <div
                class="industry-applications-card"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Aplicações/eletronica.jpg')+')'}"
              >
                Eletrónica
              </div>
              <div
                class="industry-applications-card"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Aplicações/software.jpg')+')'}"
              >
                Software
              </div>
              <div
                class="industry-applications-card industry-applications-card-half"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Aplicações/banca.jpg')+')'}"
              >
                Banca
              </div>
              <div
                class="industry-applications-card industry-applications-card-half"
                :style="{backgroundImage:
                  'url('+require('@/assets/Elementos/3 Industria 4.0/Aplicações/servicos-publicos.jpg')+')'}"
              >
                Serviços Públicos
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Industry',
  data() {
    return {
      state: 1,
      display: 1,
      videoId: 'I9UqWQxEMP0',
      resize: true,
      resizeDelay: 0,
      fitParent: true,
    };
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    setState(state) {
      this.state = state;
    },
    setDisplay(value) {
      this.display = value;
    },
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log('\o/ we are watching!!!');
    },
  },

};
</script>
