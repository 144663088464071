<template>
  <div class="question-container">
    <div>
      <div
        class="title"
      >
        Que objetivos pretende atingir com a adopção dos conceitos da digitalização e i4.0?
      </div>
    </div>
    <c-multichoice
      :choices="choices"
      :not-enabled="$store.state.quiz.disabled"
      :on-click="getChoice"
      :selected="selected"
    />
  </div>
</template>

<script>
import Multichoice from './Multichoice.vue';

export default {
  Name: 'Step7',
  components: {
    'c-multichoice': Multichoice,
  },
  props: {
    quiz: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selected: this.$store.getters.companyState.goals || [],
      choices: [
        { label: 'Aumento da eficiência das operações ou serviços', value: 1 },
        { label: 'Aumento dos proveitos', value: 2 },
        { label: 'Aumento da eficiência do sistema de gestão', value: 3 },
      ],
    };
  },
  methods: {
    getChoice(value) {
      this.$store.commit('setGoals', { goals: value, update: true, quiz: this.quiz });
    },
  },
};
</script>
