<template>
  <div :style="cssVars">
    <svg
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      width="320"
      :height="height"
      viewBox="0 -28 295 320"
    >
      <g>
        <path
          class="cls-2 dimension_1"
          d="M173.45,97.925l.48-.277,74.359-42.931C227.929,23.27,193.265,1.96,153.5,0V86.385c7.947,1.423,14.94,5.612,19.95,11.54Z"
        />
        <path
          class="cls-2 dimension_4"
          d="M121.55,141.746l-.48,.277-74.358,42.931c20.359,31.447,55.024,52.756,94.789,54.716v-86.385c-7.948-1.423-14.94-5.611-19.951-11.54Z"
        />
        <path
          class="cls-3 dimension_6"
          d="M121.55,97.925c5.01-5.928,12.003-10.117,19.95-11.54V0C101.735,1.96,67.071,23.27,46.711,54.717l74.358,42.931,.48,.277Z"
        />
        <path
          class="cls-2 dimension_3"
          d="M173.45,141.746c-5.01,5.928-12.003,10.117-19.95,11.54v86.385c39.765-1.96,74.429-23.27,94.789-54.716l-74.359-42.931-.48-.277Z"
        />
        <path
          class="cls-2 dimension_2"
          d="M254.299,65.104l-74.369,42.937-.463,.268c1.307,3.602,2.033,7.479,2.033,11.528s-.726,7.925-2.033,11.528l.463,.268,74.369,42.937c8.429-16.413,13.201-35.012,13.201-54.732s-4.772-38.32-13.201-54.732Z"
        />
      </g>
      <path
        class="cls-1 dimension_5"
        d="M115.533,108.308l-.463-.268L16.851,51.333C6.095,71.803,0,95.106,0,119.836s6.095,48.032,16.85,68.503l98.219-56.707,.463-.268c-1.306-3.603-2.033-7.48-2.033-11.528s.726-7.925,2.032-11.528Z"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'Dim1',
  props: {
    height: {
      type: Number,
      default: 200,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        '--color': this.color,
      };
    },
  },
  mounted() {
    const dim1 = document.querySelectorAll('.dimension_1');
    dim1.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 1);
    }));

    const dim2 = document.querySelectorAll('.dimension_2');
    dim2.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 2);
    }));

    const dim3 = document.querySelectorAll('.dimension_3');
    dim3.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 3);
    }));

    const dim4 = document.querySelectorAll('.dimension_4');
    dim4.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 4);
    }));

    const dim5 = document.querySelectorAll('.dimension_5');
    dim5.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 5);
    }));

    const dim6 = document.querySelectorAll('.dimension_6');
    dim6.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 6);
    }));
  },
};
</script>
<style lang="scss" scoped>
  .cls-1,
  .cls-2 {
    fill: var(--color);
    cursor: pointer;

    &:hover {
      fill: var(--color);
      opacity: .32;
    }
  }

  .cls-1 {
    animation: blinkingFill 2s ease-in-out;
  }

  .cls-3 {
    fill: #A8AAAA;
    cursor: pointer;

    &:hover {
      fill: var(--color);
      opacity: 1;
    }
  }

  .cls-2 {
    opacity: .32;
    cursor: pointer;

    &:hover {
      fill: var(--color);
      opacity: 1;
    }
  }

  @keyframes blinkingFill {
    0%		{ fill: var(--color);}
    25%		{ fill: #A8AAAA;}
    50%		{ fill: var(--color)}
    75%		{ fill: #A8AAAA;}
    100%	{ fill: var(--color)}
  }
</style>
