<template>
  <b-container
    class="diagnostic-container"
    fluid
  >
    <b-row class="diagnostic-header">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              cols="12"
              class=" text-left"
            >
              <div class="diagnostic-title">
                A Mudança Começa Aqui
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="diagnostic-banner">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              cols="12"
              sm="12"
              md="6"
              offset-md="3"
              offset-lg="0"
              lg="6"
              class="mockup"
            >
              <img
                src="@/assets/Mockup.png"
                width="100%"
              >
            </b-col>

            <b-col
              cols="12"
              md="12"
              lg="6"
              class="mt-5 mb-5"
            >
              <div class="diagnostic-banner-title">
                Diagnóstico i4.0
              </div>
              <div class="diagnostic-banner-description">
                Avalie o estado de maturidade digital da sua empresa e obtenha linhas de orientação para o desenho de um plano de ação e identificação de investimentos a realizar.
              </div>
              <div class="buttons">
                <div
                  class="button primary"
                  @click="$router.push({name: 'dashboard'})"
                >
                  Começar agora <b-icon icon="chevron-right" />
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="diagnostic-dimensions">
      <b-col class="diagnostic-dimensions-container">
        <b-container>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <div class="diagnostic-banner-title">
                Dimensões do Diagnóstico i4.0
              </div>
              <p class="diagnostic-banner-subtitle">
                O nível de cada dimensão é calculado tendo por base a menor classificação obtida por um ou mais temas dentro dessa dimensão.
              </p>
              <p class="diagnostic-banner-subtitle">
                A classificação final da maturidade de uma empresa é obtida através da média ponderada das classificações das diferentes dimensões, com os seguintes pesos:
              </p>
            </b-col>
            <b-col
              cols="12"
              md="8"
              class="dimensions-list"
            >
              <div
                v-for="(item, i) in items"
                :key="i"
                class="dimension-item"
              >
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="icon">
                  <b-icon
                    :icon="open === item.index ? 'slash-circle-fill' : 'plus-circle-fill'"
                    :rotate="open === item.index ? '45' : 0"
                    @click="toggleOpen(item.index)"
                  />
                </div>
                <div
                  v-if="open === item.index"
                  class="description"
                >
                  <p
                    v-for="(desc, k) in item.description.split('\n')"
                    :key="k"
                  >
                    {{ desc }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="diagnostic-results">
      <b-col>
        <b-container>
          <b-row align-v="center">
            <b-col
              class="mt-2 mb-2"
              cols="12"
              md="7"
            >
              <div class="diagnostic-banner-title">
                Análise dos Resultados
              </div>
              <div class="diagnostic-banner-description">
                Este diagnóstico segue o modelo desenvolvido pelo <a
                  href="https://www.iwkoeln.de/en/institute.html"
                  target="_blank"
                >IW Consult da Cologne Institute for Economic Research</a> e pela <a
                  href="https://www.fir.rwth-aachen.de/en/"
                  target="_blanck"
                >FIR da RWTH da Universidade de Aachen</a> que considera 6 níveis de maturidade digital que são avaliados segundo 6 dimensões (círculo interno) e os 18 temas (círculo externo).
              </div>
            </b-col>
            <b-col
              class="mt-2 mb-2"
              cols="12"
              md="5"
            >
              <img
                src="@/assets/dimensoes.svg"
                width="100%"
              >
            </b-col>
          </b-row>
          <b-row
            align-v="end"
            class="mt-5 mb-5"
          >
            <b-col
              class="mt-2 mb-2"
              cols="12"
              md="7"
            >
              <img
                src="@/assets/niveis de maturidade.svg"
                width="100%"
              >
            </b-col>
            <b-col
              class="mt-2 mb-2"
              cols="12"
              md="5"
            >
              <div class="diagnostic-banner-title">
                Níveis de Maturidade
              </div>
              <div class="diagnostic-banner-description mb-0">
                A partir da avaliação que incide em seis dimensões da Indústria 4.0 é possível posicionar a empresa num de seis níveis de maturidade i4.0.
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="diagnostic-start">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              cols="12"
              md="7"
            >
              <div class="diagnostic-banner-title">
                Diagnóstico i4.0
              </div>
              <div class="diagnostic-banner-subtitle">
                Avalie o estado de maturidade digital da sua empresa.
              </div>
            </b-col>
            <b-col
              cols="12"
              md="5"
            >
              <div
                class="buttons justify-content-start"
              >
                <div
                  class="button primary"
                  @click="$router.push({name: 'diagnostic'})"
                >
                  Começar agora <b-icon icon="chevron-right" />
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Diagnostic',
  data() {
    return {
      open: 1,
      items: [
        { index: 1, title: 'Informação Geral e Grupos de comparação', description: 'A ferramenta SHIFTo4.0 apresenta ainda a secção Grupos de comparação onde é escolhido o grupo de comparação, por setor económico e por localização, de forma a tornar possível diferenciar os resultados. ' },
        { index: 2, title: 'Estratégia e Organização ', description: 'A indústria 4.0 é um tema estratégico para qualquer empresa. Os seus conceitos são muito abrangentes e vão além do simples melhoramento de produtos, processos ou serviços existentes através da utilização de tecnologias digitais. Na realidade, estes novos conceitos oferecem a oportunidade de a empresa desenvolver novos modelos de negócio, tendo por isso uma importância estratégica. Assim, nesta dimensão procuram-se respostas para os seguintes temas: status de implementação da estratégia i4.0; operacionalização e revisão da estratégia através de um sistema de indicadores; investimentos relacionados com a i.4.0 e gestão da tecnologia e inovação.' },
        { index: 3, title: 'Infraestrutura Inteligente', description: 'Esta dimensão pretende, essencialmente, avaliar as infraestruturas das empresas tanto as de produção de bens como as de serviços. No caso de empresas de fabricação de bens, é avaliada a funcionalidade dos equipamentos quanto ao controlo por TI, a capacidade de comunicação entre eles e a sua interoperabilidade, assim como o seu potencial de atualização. É igualmente avaliada a capacidade de a empresa recolher dados de equipamentos e de processos e caraterizar os sistemas utilizados para o mesmo. No caso de empresas de serviços, e.g. as de commodities, toda a infraestrutura inerente aos processos e operações é avaliada nesta dimensão. Contudo, é reconhecida a não aplicabilidade desta dimensão a muitas empresas, principalmente as de serviços, razão pela qual é possível ser excluída, não afetando o cálculo final do nível de maturidade da empresa a qual será, então, determinada apenas em função das restantes dimensões (com reafectação dos pesos relativos). ' },
        { index: 4, title: 'Operações Inteligentes', description: 'Esta dimensão pretende avaliar o grau de partilha da informação entre as diversas áreas ou departamentos de uma empresa e que informação é partilhada externamente com fornecedores, clientes, outras empresas, etc. Como tal, a organização das TI e as suas soluções de segurança são de grande importância. A utilização de serviços em nuvem e as razões subjacentes à sua não utilização são também questões importantes na avaliação desta dimensão. Nesta dimensão também se avalia a capacidade de a empresa realizar determinadas operações autonomamente. ' },
        { index: 5, title: 'Produtos Inteligentes', description: 'Nesta dimensão pretende-se recolher informação sobre a existência de produtos físicos com componentes TIC (sensores, RFID, interfaces de comunicação, etc.), quer sejam fabricados pela empresa ou por ela utilizados, que permitam disponibilizar dados do seu estado e de eficiências das operações. Desta forma, torna-se possível monitorar e otimizar a condição dos produtos, com potencial de aplicação durante a fase de utilização e na criação de novos serviços – e.g., através de comunicação entre clientes e fabricantes. Como tal, são colocadas questões sobre a capacidade de os produtos da empresa já virem equipados com funcionalidades baseadas em componentes TIC, se existe recolha de dados proveniente dos produtos, se a recolha é efetuada durante a fase de utilização e com que objetivo são analisados os dados. Analogamente à Infraestrutura Inteligente, nesta dimensão é incluída uma pergunta de exclusão para as que não produzam ou utilizem produtos inteligentes. Quando ativada a exclusão, o cálculo final do nível de maturidade será apenas realizado em função das restantes dimensões (com reafectação dos pesos relativos). ' },
        { index: 6, title: 'Serviços Baseados em Dados', description: 'A avaliação desta dimensão é de grande importância pois ela permite, em grande parte, refletir o nível avançado de maturidade em que a empresa se encontra. Contribui também para a reflexão por parte da empresa sobre a contribuição que a i4.0 pode trazer à sua atividade, potenciando eventualmente outros negócios, ou mesmo incrementando valor aos atuais. Assim, é avaliada a existência de serviços baseada em dados, o nível de utilização destes e a sua contribuição para as receitas da empresa. ' },
        { index: 7, title: 'Recursos Humanos', description: 'A transformação para a i4.0 só é possível com recursos humanos devidamente qualificados e motivados para esta transformação. Para além das competências técnicas específicas é necessário criar uma “cultura i4.0” que deverá ser interiorizada por cada um dos membros da empresa. O novo paradigma requer conhecimentos em áreas mais específicas, como a análise de dados, software colaborativo, tecnologias de automação, IoT, Big Data, entre outras. De acordo com o desenvolvimento da estratégia i4.0 definida pela empresa, um levantamento e planeamento de recursos humanos é fundamental para tornar a transformação i4.0 uma realidade. Desta forma pretende-se, nesta dimensão, avaliar as competências dos recursos humanos da empresa quanto aos requisitos i4.0, e se existe um esforço em adquirir competências em falta. ' },
        { index: 8, title: 'Relatório', description: 'Após a conclusão do inquérito é apresentada a avaliação global, onde o nível de maturidade global da empresa é calculado tendo por base a menor classificação obtida por um ou mais temas dentro de uma dimensão e exibido graficamente (gráfico tipo Aster) assim como em forma de tabela. A classificação é decomposta nas 6 dimensões analisadas e posteriormente desdobrada pelos 18 temas, por forma a obter uma visão especifica das classificações individuais por tema. O gráfico apresenta uma visão holística do estado de maturidade atual da empresa, por dimensão e tema, assim como do seu estado futuro de acordo com os objetivos estabelecidos num horizonte temporal de 5 anos. O relatório contempla ainda orientações genéricas para permitir à empresa o aumento da sua maturidade atual para o nível futuro definido através dos seus objetivos. Finalmente o relatório é emitido em formato “Portable Document Format” (PDF) e remetido para o correio eletrónico do respondente da empresa. ' },
      ],
    };
  },
  methods: {
    toggleOpen(open) {
      if (this.open !== open) {
        this.open = open;
      } else {
        this.open = 0;
      }
    },
  },
};
</script>
