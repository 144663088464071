import Vue from 'vue';
import Router from 'vue-router';
import title from '@/mixins/middlewares/title';
import Industry from './components/pages/Industry.vue';
import Incentives from './components/pages/Incentives.vue';
import Employability from './components/pages/Employability.vue';
import Diagnostic from './components/pages/Diagnostic/Dashboard.vue';
import Auth from './components/pages/Diagnostic/Auth.vue';
import ResetPassword from './components/pages/Diagnostic/ResetPassword.vue';
import Quiz from './components/pages/Diagnostic/Quiz.vue';
import Company from './components/pages/Diagnostic/Company.vue';
import Project from './components/pages/Project.vue';
import Results from './components/pages/Results.vue';
import Home from './components/pages/Home.vue';
import diagnostici40 from './components/pages/Diagnostic.vue';
import ExpressionOfInterest from './components/pages/ExpressionOfInterest.vue';
import ComingSoon from './components/pages/ComingSoon.vue';
import ShiftToi40 from './components/pages/ShiftToi40.vue';
import Testimonials from './components/pages/Testimonials.vue';
import DownloadResource from './components/pages/DownloadResource.vue';
import DownloadRoadmapResource from './components/pages/DownloadRoadmapResource.vue';
import store from './store/index.js';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Home',
        middleware: [title],
      },
    },
    {
      path: '/industria',
      name: 'industry',
      component: Industry,
      meta: {
        title: 'Indústria',
        middleware: [title],
      },
    },
    {
      path: '/apoios',
      name: 'incentives',
      component: Incentives,
      meta: {
        title: 'Incentivos',
        middleware: [title],
      },
    },
    {
      path: '/empregabilidade',
      name: 'employability',
      component: Employability,
      meta: {
        title: 'Empregabilidade',
        middleware: [title],
      },
    },
    /* {
      path: '/noticias',
      name: 'news',
      component: News,
      meta: {
        title: 'Notícias',
        middleware: [title],
      },
    }, */
    {
      path: '/projeto',
      name: 'project',
      component: Project,
      meta: {
        title: 'O Projeto',
        middleware: [title],
      },
    },
    {
      path: '/resultados',
      name: 'results',
      component: Results,
      meta: {
        title: 'Resultados',
        middleware: [title],
      },
    },
    {
      path: '/diagnosticoi40',
      name: 'diagnostici40',
      component: diagnostici40,
      meta: {
        title: 'Diagnóstico i4.0',
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/dashboard',
      name: 'dashboard',
      component: Diagnostic,
      meta: {
        title: 'Área privada: Dashboard',
        dashboardState: 1,
        middleware: [title],
      },
    },
    {
      path: '/diagnostico',
      name: 'diagnostic',
      component: Diagnostic,
      meta: {
        title: 'Área privada: Questionários',
        dashboardState: 2,
        middleware: [title],
      },
    },
    {
      path: '/diagnostico',
      name: 'quizzes',
      component: Diagnostic,
      meta: {
        title: 'Área privada: Questionários',
        dashboardState: 2,
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/relatorios',
      name: 'reports',
      component: Diagnostic,
      meta: {
        title: 'Área privada: Relatórios',
        dashboardState: 3,
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/empresa',
      name: 'dashboard-company',
      component: Diagnostic,
      meta: {
        title: 'Área privada: Minha empresa',
        dashboardState: 4,
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/perfil',
      name: 'profile',
      component: Diagnostic,
      meta: {
        title: 'Área privada: Perfil',
        dashboardState: 5,
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/faq',
      name: 'faq',
      component: Diagnostic,
      meta: {
        title: 'Área privada: FAQs',
        dashboardState: 9,
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/documentos',
      name: 'documents',
      component: Diagnostic,
      meta: {
        title: 'Área privada: Documentos',
        dashboardState: 6,
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/administracao',
      name: 'admin',
      component: Diagnostic,
      meta: {
        title: 'Área privada: Administração',
        dashboardState: 7,
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/facilitador',
      name: 'facilitator',
      component: Diagnostic,
      meta: {
        title: 'Área privada: Facilitador',
        dashboardState: 8,
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/auth',
      name: 'auth',
      component: Auth,
      meta: {
        title: 'Autenticação',
        middleware: [title],
      },
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        title: 'Reset password',
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/company',
      name: 'company',
      component: Company,
      meta: {
        title: 'Empresa',
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/quiz/company/:id',
      name: 'company_quiz',
      component: Company,
      meta: {
        title: 'Questões gerais',
        middleware: [title],
      },
    },
    {
      path: '/diagnostico/quiz',
      name: 'quiz',
      component: Quiz,
      meta: {
        title: 'Questionário',
        middleware: [title],
      },
    },
    {
      path: '/interesse/',
      name: 'interest',
      component: ExpressionOfInterest,
      meta: {
        title: 'Manifestação de interesse',
        middleware: [title],
      },
    },
    {
      path: '/coming_soon/',
      name: 'coming_soon',
      component: ComingSoon,
      meta: {
        title: 'Brevemente',
        middleware: [title],
      },
    },
    {
      path: '/projeto/shiftToI4.0',
      name: 'shiftToi40',
      component: ShiftToi40,
      meta: {
        title: 'shiftToi40',
        middleware: [title],
      },
    },
    {
      path: '/testemunhos/:id',
      name: 'testimonials_id',
      component: Testimonials,
      meta: {
        title: (route) => `Testemunhos ${route.params.id}`,
        middleware: [title],
      },
    },
    {
      path: '/testemunhos',
      name: 'testimonials',
      component: Testimonials,
      meta: {
        title: 'Testemunhos',
        middleware: [title],
      },
    },
    {
      path: '/recurso/download/:id',
      name: 'download_resource',
      component: DownloadResource,
      meta: {
        title: 'Recurso',
        middleware: [title],
      },
    },
    {
      path: '/roadmap/recurso/download/:id',
      name: 'download_resource',
      component: DownloadRoadmapResource,
      meta: {
        title: 'Recurso',
        middleware: [title],
      },
    },

  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.dashboardState)) {
    // You can use store variable here to access globalError or commit mutation
    store.dispatch('dashboard/setState', to.meta.dashboardState);
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    middleware[0]({ ...context, next: nextMiddleware });
  }
});

export default router;
