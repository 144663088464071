<template>
  <div
    class="quiz-container"
  >
    <b-container
      fluid
      class="content"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        class="question-content"
      >
        <div
          class="question-container"
        >
          <div
            class="question-row"
          >
            <div
              class="question-arrow"
              @click="prevStep"
            >
              <b-icon icon="chevron-left" />
            </div>
            <div
              class="question"
            >
              <div
                v-if="faultGeralQuestions"
                class="feedback-row "
              >
                <div
                  class="error-danger"
                >
                  Responder a todas questões gerais.
                </div>
              </div>
              <component
                :is="steps[this.$store.getters.step.step]"
                :quiz="quiz"
              />
            </div>
            <div
              class="question-arrow"
              @click="nextStep"
            >
              <b-icon
                icon="chevron-right"
              />
            </div>
          </div>
        </div>
      </b-overlay>
      <b-modal
        v-model="modalShow"
        centered
        size="md"
        ok-variant="success"
        header-class="p-2 border-bottom-0"
        footer-class="p-2 border-top-0"
        ok-title="Confirmar"
        hide-footer
      >
        <div

          class="title"
        >
          Guardar questões gerais
        </div>
        <div class="buttons">
          <div
            class="button primary"
            @click="submit"
          >
            Confirmar
          </div>
          <div
            class="button secondary"
            @click="modalShow = false"
          >
            Cancelar
          </div>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import Step1 from '../../ui/Step1.vue';
// import Step2 from '../../../components/Step2'
import Step3 from '../../ui/Step3.vue';
import Step4 from '../../ui/Step4.vue';
import Step5 from '../../ui/Step5.vue';
import Step6 from '../../ui/Step6.vue';
import Step7 from '../../ui/Step7.vue';
import Step8 from '../../ui/Step8.vue';
import Step9 from '../../ui/Step9.vue';
import Step10 from '../../ui/Step10.vue';

import { get } from '../../../services/api/index';

export default {
  Name: 'Company',
  components: {
    'c-1': Step1,
    'c-2': Step3,
    'c-3': Step4,
    'c-4': Step5,
    'c-5': Step6,
    'c-6': Step7,
    'c-7': Step8,
    'c-8': Step9,
    'c-9': Step10,
  },
  data() {
    return {
      steps: {
        1: 'c-1',
        2: 'c-2',
        3: 'c-3',
        4: 'c-4',
        5: 'c-5',
        6: 'c-6',
        7: 'c-7',
        8: 'c-8',
        9: 'c-9',
      },
      step: 1,
      loading: false,
      modalShow: false,
      quiz: null,
      faultGeralQuestions: null,
      style: '',
    };
  },
  async created() {
    if (!this.$store.getters.state.loggedIn) {
      this.$router.push({ name: 'auth' });
    } else {
      this.$store.commit('setStep', 1);
      this.loading = true;
      if (this.$route.params.id) {
        this.quiz = parseInt(this.$route.params.id, 10) || null;
        await get(`quiz/company/${this.$route.params.id}`, {})
          .then((response2) => {
            this.$store.commit('setIndustry', { industry: response2.data.company.industry, update: false });
            this.$store.commit('setCountry', { country: response2.data.company.country, update: false });
            this.$store.commit('setRegion', { region: response2.data.company.region ? `R-${response2.data.company.region}` : null, update: false });
            this.$store.commit('setEmployees', { employees: response2.data.company.employees, update: false });
            this.$store.commit('setCauses', { causes: JSON.parse(response2.data.company.causes), update: false });
            this.$store.commit('setGoals', { goals: JSON.parse(response2.data.company.goals), update: false });
            this.$store.commit('setConcept', { concept: response2.data.company.know_the_concept, update: false });
            this.$store.commit('setStatus', { status: JSON.parse(response2.data.company.status), update: false });
            this.$store.commit('setTurnover', { turnover: response2.data.company.turnover, update: false });
            this.$store.commit('setEconomicSector', { economicSector: response2.data.company.economic_sector, update: false });
            this.$store.commit('setClusters', { clusters: response2.data.company.clusters, update: false });
          });
        this.faultGeralQuestions = this.$store.getters.faultGeralQuestions;
        if (this.faultGeralQuestions) {
          this.$store.commit('setFaultGeralQuestions', false);
        }
      } else {
        this.$router.push({ name: 'diagnostic' });
      }

      this.loading = false;
    }
  },
  methods: {
    submit() {
      this.$router.push({ name: 'quiz' });
    },
    nextStep() {
      if (this.$store.getters.step.step == 9 && !this.$store.state.quiz.disabled) {
        this.modalShow = true;
      } else if (this.$store.getters.step.step == 9 && this.$store.state.quiz.disabled) {
        this.$router.push({ name: 'quiz' });
      }
      this.$store.commit('nextState');
    },
    prevStep() {
      this.$store.commit('prevState');
    },
  },
};
</script>
