<template>
  <b-container
    class="testimonials-container d-flex"
    fluid
    style="min-height: 61vh;"
  >
    <b-row
      class="d-flex"
      style="flex: 1"
    >
      <b-col
        class="d-flex"
        style="flex: 1"
      >
        <b-container
          class="d-flex"
          style="flex: 1"
        >
          <b-row
            class="d-flex align-items-center justify-content-center"
            style="flex: 1"
          >
            <b-col
              cols="12"
              class="testimonials-title text-center"
              style="font-size: 25px"
            >
              <div
                v-if="!notFound"
                class="align-items-center justify-content-center d-flex"
              >
                <div
                  class="button success sm align-items-center justify-content-center d-flex mr-3"
                  style="width: 40px; height: 40px; border-radius: 50%"
                >
                  <b-icon
                    icon="arrow-down"
                    scale="2"
                  />
                </div>
                Se o download não começar dentro de alguns segundos, <a
                  style="cursor: pointer; text-decoration: none; margin-left: 5px"
                  class="link"
                  @click="download"
                >clique aqui...</a>
              </div>
              <div
                v-else
                class="align-items-center justify-content-center d-flex"
              >
                <div
                  class="button danger sm align-items-center justify-content-center d-flex mr-3"
                  style="width: 40px; height: 40px; border-radius: 50%"
                >
                  <b-icon
                    icon="x"
                    scale="3"
                  />
                </div>
                Ups! Recurso não encontrado!
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import mime from 'mime';
import { get } from '../../services/api';

export default {
  name: 'DownloadRoadmapResource',
  data() {
    return {
      notFound: false,
    };
  },
  async created() {
    await this.download();
  },
  methods: {
    async download() {
      const { id } = this.$route.params;
      await get(`results/roadmaps/resources/${id}`).then((response) => {
        const linkSource = `data:${mime.getType(response.data.document_path)};base64,${response.data.document}`;
        const downloadLink = document.createElement('a');
        const fileName = response.data.document_path;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }).catch((err) => {
        this.notFound = err.status === 404;
      });
    },
  },
};
</script>
