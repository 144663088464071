<template>
  <b-container
    class="home-container"
    fluid
  >
    <b-row class="home-header">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              sm="12"
              lg="8"
              offset-lg="2"
              class="home-title text-center"
            >
              Acelerar a Transição Digital das PME
            </b-col>
          </b-row>
          <b-row>
            <b-col class="buttons">
              <div
                class="button primary"
                @click="$router.push({name: 'interest'})"
              >
                SABER MAIS SOBRE O PROJETO <b-icon icon="chevron-right" />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>

    <b-row class="home-diagnostic">
      <b-container>
        <b-row>
          <b-col
            cols="12"
            sm="12"
            lg="4"
          >
            <div class="title">
              Diagnóstico i4.0
            </div>
            <div class="enphasis">
              Descubra o nível de maturidade digital da sua empresa.
            </div>
            <div class="desc">
              Obtenha linhas de orientação para o traçado de um plano de ação e identificação de investimentos a realizar.
            </div>
            <b-button
              variant="primary"
              class="button mb-3"
              style=" position: inherit; z-index: 50;"
              @click="$router.push({name: 'diagnostic'})"
            >
              Começar agora <b-icon icon="chevron-right" />
            </b-button>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            lg="8"
          >
            <img
              class="image-mockup"
              src="@/assets/Elementos/1 Home/diagnostico-shiftto4.0.png"
            >
          </b-col>
          <b-col
            class="prepare"
            cols="12"
            sm="12"
            lg="6"
          >
            Preparamos as PME para a economia digital
          </b-col>
        </b-row>
        <b-row>
          <b-col class="prepare-icons">
            <div class="prepare-icons-container">
              <div class="prepare-icons-circle">
                <img src="@/assets/Elementos/1 Home/sensibilizacao.svg">
              </div>
              <div class="prepare-icons-label">
                Sensibilização, Formação & Capacitação
              </div>
            </div>
            <div class="prepare-icons-container">
              <div class="prepare-icons-circle">
                <img src="@/assets/Elementos/1 Home/diagnosticos.svg">
              </div>
              <div class="prepare-icons-label">
                Diagnósticos SHIFTo4.0
              </div>
            </div>
            <div class="prepare-icons-container">
              <div class="prepare-icons-circle">
                <img src="@/assets/Elementos/1 Home/roadmaps.svg">
              </div>
              <div class="prepare-icons-label">
                Boas Práticas & Benchmarking
              </div>
            </div>
            <div class="prepare-icons-container">
              <div class="prepare-icons-circle">
                <img src="@/assets/Elementos/1 Home/boaspraticas.svg">
              </div>
              <div class="prepare-icons-label">
                Roadmaps & Outras Ferramentas de Apoio
              </div>
            </div>
            <div class="prepare-icons-container">
              <div class="prepare-icons-circle">
                <img src="@/assets/Elementos/1 Home/comunicacao.svg">
              </div>
              <div class="prepare-icons-label">
                Comunicação & Disseminação do Projeto
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <b-row class="home-testimonials">
      <b-col>
        <b-container>
          <b-row>
            <b-col class="home-testimonials-title">
              Testemunhos
            </b-col>
          </b-row>
          <b-row
            align-v="center"
          >
            <b-col
              order="1"
              order-sm="1"
              class="text-right scroll-indicator mb-5 pr-5"
              cols="6"
              sm="1"
              @click="scrollOneLeft"
            >
              <b-icon
                scale="2.5"
                icon="chevron-left"
              />
            </b-col>
            <b-col
              cols="12"
              sm="10"
              order="3"
              order-sm="2"
            >
              <b-row
                id="testimonials"
                class="home-testimonials-cards-row"
                @mouseover="stopScroll()"
                @mouseleave="resumeScroll()"
              >
                <b-col
                  v-for="(testimonial, i) in testimonials"
                  :key="i"
                  class="home-testimonials-card"
                >
                  <div
                    class="author"
                  >
                    <img
                      :src="`${testimonial.image}`"
                    >
                    <div class="description">
                      <div class="name">
                        {{ testimonial.author }}
                      </div>
                      <div class="company">
                        <a
                          :href="testimonial.company_link"
                          target="_blank"
                        > {{ testimonial.company }}</a>
                      </div>
                    </div>
                  </div>

                  <div class="quotes">
                    “
                  </div>
                  <div class="emphasis">
                    {{ testimonial.emphasis }}
                  </div>
                  <div
                    class="read-more"
                    @click="$router.push({name: 'testimonials_id', params: {
                      id: testimonial.id
                    }})"
                  >
                    Continuar a ler <b-icon icon="chevron-right" />
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              order="2"
              order-sm="3"
              cols="6"
              sm="1"
              class="text-left scroll-indicator mb-5 pl-5"
              @click="scrollOneRight"
            >
              <b-icon
                scale="2.5"
                icon="chevron-right"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { get } from '../../services/api';

export default {
  name: 'Home',
  data() {
    return {
      testimonials: [],
      timer: 0,
      slideTimer: 0,
      scrollAmount: 0,
      scrollLeft: false,
      timeInterval: 4000,
      amountOfScroll: 5,
    };
  },
  async created() {
    const limit = new Date('Mar 25, 2021 15:30:00').getTime();
    const now = new Date().getTime();
    const distance = limit - now;

    if (distance > 0 && location.href.includes('shift2future.pt') && !location.href.includes('shift2future.pt:8080')) {
      this.$router.push({ name: 'coming_soon' });
    } else {
      await get('testimonials').then((response) => {
        this.testimonials = response.data;
      });
    }

    clearInterval(this.timer);
    clearInterval(this.slideTimer);

    this.scrollTestimonials();
  },

  updated() {
    clearInterval(this.timer);
    clearInterval(this.slideTimer);
  },

  beforeDestroy() {
    this.$nextTick(() => {
      clearInterval(this.timer);
      clearInterval(this.slideTimer);
    });
  },

  methods: {
    scrollTestimonials() {
      this.timer = setInterval(() => {
        this.startScroll();
      }, this.timeInterval);
    },

    stopScroll() {
      clearInterval(this.timer);
      clearInterval(this.slideTimer);
    },

    resumeScroll() {
      this.startScroll();
    },

    startScroll() {
      const div = document.getElementById('testimonials');
      this.scrollLeft = div.offsetWidth + div.scrollLeft >= div.scrollWidth || this.scrollLeft;
      this.scroll(div);
    },

    scrollOneLeft() {
      clearInterval(this.timer);
      clearInterval(this.slideTimer);
      const div = document.getElementById('testimonials');
      const length = window.innerWidth <= 992 ? div.offsetWidth + 40 : (div.offsetWidth + 40) / 2;
      if (this.scrollLeft) {
        div.scrollLeft -= length - this.scrollAmount;
      } else {
        div.scrollLeft -= length + this.scrollAmount;
      }
      this.scrollAmount = 0;

      this.scrollLeft = div.scrollLeft !== 0;
      this.scrollTestimonials();
    },

    scrollOneRight() {
      clearInterval(this.timer);
      clearInterval(this.slideTimer);
      const div = document.getElementById('testimonials');
      const length = window.innerWidth <= 992 ? div.offsetWidth + 40 : (div.offsetWidth + 40) / 2;
      if (this.scrollLeft) {
        div.scrollLeft += length + this.scrollAmount;
      } else {
        div.scrollLeft += length - this.scrollAmount;
      }
      this.scrollAmount = 0;

      this.scrollLeft = div.offsetWidth + div.scrollLeft >= div.scrollWidth;
      this.scrollTestimonials();
    },

    scroll(div) {
      const scrollableDiv = div;
      clearInterval(this.timer);
      const length = window.innerWidth <= 992 ? scrollableDiv.offsetWidth + 40 : (scrollableDiv.offsetWidth + 40) / 2;

      this.slideTimer = setInterval(() => {
        scrollableDiv.scrollLeft += this.scrollLeft ? -this.amountOfScroll : this.amountOfScroll;
        this.scrollAmount += this.amountOfScroll;
        clearInterval(this.timer);

        if (this.scrollAmount >= length) {
          clearInterval(this.slideTimer);
          this.scrollAmount = 0;
          this.timer = setInterval(() => {
            this.startScroll();
          }, this.timeInterval);
          if (scrollableDiv.scrollLeft <= 0 && this.scrollLeft) {
            this.scrollLeft = false;
          }
        }
      }, 15);
      this.scrollTestimonials();
    },
  },
};
</script>
