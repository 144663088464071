<template>
  <b-container
    class="employability-container"
    fluid
  >
    <b-row class="employability-header">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              cols="12"
              class=" text-left"
            >
              <div class="employability-title">
                Os Empregos do Futuro
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="employability-banner">
      <b-col>
        <b-container>
          <b-row>
            <b-col
              cols="10"
              offset="1"
              class="employability-subtitle"
            >
              A <a
                href="https://www.cognizant.com/"
                target="_blank"
              >Cognizant</a>, empresa de tecnologia da informação americana, divulgou um estudo que identifica 21 novas carreiras que surgirão até 2029 e que parecem retiradas de um filme de ficção científica.
            </b-col>
            <b-col
              class="employability-cards"
            >
              <div
                class="card-employability half"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/01-engenheiro-de-dados.jpg')+')' }"
                @click="showModal('A tendência futura das empresas é para recrutarem engenheiros capazes de assegurar a gestão eficaz dos seus dados, alimentando os algoritmos com informação que permita melhorar a qualidade dos dados armazenada e aumentar a quantidades de informação essencial ao negócio. O objetivo final é transformar os dados aparentemente inúteis em informação valiosa para o negócio.')"
              >
                <div
                  class="content-card-employability-2"
                >
                  <div class="title">
                    Engenheiro de dados inúteis
                  </div>
                  <div class="description">
                    A tendência futura das empresas é para recrutarem engenheiros capazes de assegurar a gestão eficaz dos seus dados, alimentando os algoritmos com informação que permita melhorar a qualidade dos dados armazenada e aumentar a quantidades de informação essencial ao negócio. O objetivo final é transformar os dados aparentemente inúteis em informação valiosa para o negócio.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                >
                  <div class="number">
                    01
                  </div>
                  <div class="title">
                    Engenheiro de dados inúteis
                  </div>
                </div>
              </div>

              <div
                class="card-employability half"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/02-agentes-cibercrime.jpg')+')' }"
                @click="showModal('Parte da sua missão passa por identificar e combater ciberataques a organismos públicos e entidades privadas. Para além de sólidas competências técnicas na área da programação e desenvolvimento, são exigidas excelentes capacidades de comunicação e trabalho em equipa já que terão de cooperar com outras forças de segurança.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Agentes de Cibercrime
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Parte da sua missão passa por identificar e combater ciberataques a organismos públicos e entidades privadas. Para além de sólidas competências técnicas na área da programação e desenvolvimento, são exigidas excelentes capacidades de comunicação e trabalho em equipa já que terão de cooperar com outras forças de segurança.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                >
                  <div

                    class="number"
                  >
                    02
                  </div>
                  <div

                    class="title"
                  >
                    Agentes de Cibercrime
                  </div>
                </div>
              </div>

              <div
                class="card-employability"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/03-conselheiro-reabilitacao.jpg')+')' }"

                @click="showModal('Com o cibercrime a aumentar entre as camadas mais jovens da população, a figura de conselheiro de reabilitação de cibercriminosos tem nas suas mãos a responsabilidade de potencializar os conhecimentos destes jovens em boas práticas para a sociedade, garantindo assim mais segurança à rede.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Conselheiro de Reabilitação
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Com o cibercrime a aumentar entre as camadas mais jovens da população, a figura de conselheiro de reabilitação de cibercriminosos tem nas suas mãos a responsabilidade de potencializar os conhecimentos destes jovens em boas práticas para a sociedade, garantindo assim mais segurança à rede.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div
                    class="number"
                  >
                    03
                  </div>
                  <div

                    class="title"
                  >
                    Conselheiro de Reabilitação
                  </div>
                </div>
              </div>

              <div

                class="card-employability half"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/04-designer-voz.jpg')+')' }"

                @click="showModal('Um designer de user experience (experiência do utilizador) que trabalha na área da voz desenvolve um conjunto de ferramentas de diagnóstico, algoritmos, protocolos linguísticos em que criam assistentes de voz personalizados que permitirá aos utilizadores selecionar o seu assistente de voz ideal.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    UX Designer de Voz
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Um designer de user experience (experiência do utilizador) que trabalha na área da voz desenvolve um conjunto de ferramentas de diagnóstico, algoritmos, protocolos linguísticos em que criam assistentes de voz personalizados que permitirá aos utilizadores selecionar o seu assistente de voz ideal.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div

                    style="display: flex;"
                    class="number"
                  >
                    04
                  </div>
                  <div

                    style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                    class="title"
                  >
                    UX Designer de Voz
                  </div>
                </div>
              </div>

              <div
                class="card-employability half"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/05-assistentes-felicidade.jpg')+')' }"

                @click="showModal('Numa abordagem de menos é mais a missão dos assistentes de felicidade é identificar as necessidades dos indivíduos e ajudar na definição de estratégias personalizadas de motivação e satisfação para encontrarem a felicidade.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Assistentes de Felicidade
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Numa abordagem de menos é mais a missão dos assistentes de felicidade é identificar as necessidades dos indivíduos e ajudar na definição de estratégias personalizadas de motivação e satisfação para encontrarem a felicidade.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div

                    style="display: flex;"
                    class="number"
                  >
                    05
                  </div>
                  <div

                    style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                    class="title"
                  >
                    Assistentes de Felicidade
                  </div>
                </div>
              </div>

              <div
                class="card-employability half"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/06-lider-etica-empresarial.jpg')+')' }"
                @click="showModal('Responsáveis de enquadrar a forma como os trabalhadores se devem posicionar em plataformas digitais sem lesar a imagem da empresa ou entrar em conflito com as obrigações éticas da sua atividade, para lhes dar a garantia que a organização gere a informação que tem deles de forma transparente e honesta.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Líder de Ética Empresarial
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Responsáveis de enquadrar a forma como os trabalhadores se devem posicionar em plataformas digitais sem lesar a imagem da empresa ou entrar em conflito com as obrigações éticas da sua atividade, para lhes dar a garantia que a organização gere a informação que tem deles de forma transparente e honesta.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div

                    style="display: flex;"
                    class="number"
                  >
                    06
                  </div>
                  <div

                    style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                    class="title"
                  >
                    Líder de Ética Empresarial
                  </div>
                </div>
              </div>

              <div
                class="card-employability half"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/07-gestores-casas-inteligentes.jpg')+')' }"
                @click="showModal('Com o crescimento das smart homes, será fundamental ter um profissional que trabalhe ao lado de arquitetos, engenheiros e clientes para desenhar casas inteligentes que ofereçam experiências tecnológicas integradas à estética e ao meio ambiente.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Gestores de Casas Inteligentes
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Com o crescimento das smart homes, será fundamental ter um profissional que trabalhe ao lado de arquitetos, engenheiros e clientes para desenhar casas inteligentes que ofereçam experiências tecnológicas integradas à estética e ao meio ambiente.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div

                    style="display: flex;"
                    class="number"
                  >
                    07
                  </div>
                  <div

                    style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                    class="title"
                  >
                    Gestores de Casas Inteligentes
                  </div>
                </div>
              </div>
              <div
                class="card-employability-quad"
              >
                <div
                  class="card-employability"
                  :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/08-auditor-falhas.jpg')+')' }"
                  @click="showModal('A missão do auditor de algoritmos é supervisionar o funcionamento do algoritmo e garantir que não há falhas de funcionamento ou possíveis constrangimentos legais ou regulatórios e que estejam alinhados com os valores éticos e morais da empresa.')"
                >
                  <div

                    class="open-sans-regular
              fs-12
              text-light
              text-left
              content-card-employability-2"
                    style=" display: flex; flex-direction: column; height: 100%;"
                  >
                    <div class="rubik-regular fs-16 text-light title">
                      Auditor de Falhas de Algoritmo
                    </div>
                    <div
                      class="mt-16 description"
                    >
                      A missão do auditor de algoritmos é supervisionar o funcionamento do algoritmo e garantir que não há falhas de funcionamento ou possíveis constrangimentos legais ou regulatórios e que estejam alinhados com os valores éticos e morais da empresa.
                    </div>
                  </div>
                  <div
                    class="content-card-employability-1"
                    style="display: flex; flex-direction: column; height: 100%;"
                  >
                    <div

                      style="display: flex;"
                      class="number"
                    >
                      08
                    </div>
                    <div

                      style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                      class="title"
                    >
                      Auditor de Falhas de Algoritmo
                    </div>
                  </div>
                </div>

                <div
                  class="card-employability"
                  :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/09-coordenador-formacao.jpg')+')' }"

                  @click="showModal('Um dos desafios apontados à tecnologia por quem gere talento é a obrigatoriedade que esta impõe aos profissionais de se manterem permanentemente atualizados, para não se tornarem obsoletos. O Coordenador de Formação Contínua é alguém que apoia os profissionais a traçar planos de formação ao longo da vida, adequados aos seus objetivos de carreira e às necessidades das empresas.')"
                >
                  <div

                    class="open-sans-regular
              fs-12
              text-light
              text-left
              content-card-employability-2"
                    style=" display: flex; flex-direction: column; height: 100%;"
                  >
                    <div class="rubik-regular fs-16 text-light title">
                      Coordenador de Formação Contínua
                    </div>
                    <div
                      class="mt-16 description"
                    >
                      Um dos desafios apontados à tecnologia por quem gere talento é a obrigatoriedade que esta impõe aos profissionais de se manterem permanentemente atualizados, para não se tornarem obsoletos. O Coordenador de Formação Contínua é alguém que apoia os profissionais a traçar planos de formação ao longo da vida, adequados aos seus objetivos de carreira e às necessidades das empresas.
                    </div>
                  </div>
                  <div
                    class="content-card-employability-1"
                    style="display: flex; flex-direction: column; height: 100%;"
                  >
                    <div

                      style="display: flex;"
                      class="number"
                    >
                      09
                    </div>
                    <div

                      style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                      class="title"
                    >
                      Coordenador de Formação Contínua
                    </div>
                  </div>
                </div>

                <div
                  class="card-employability"
                  :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/10-vigilante-cibercalamidade.jpg')+')' }"
                  @click="showModal('Com o aumento exponencial de atentados cibernéticos, está a tornar-se cada vez mais importante para as empresas constituírem equipas permanentemente dedicadas à vigilância e prevenção das suas fragilidades informáticas e a exposição ao risco de ciberataques de grande dimensão. Formação em matemática, ciências, estatística ou áreas relacionadas são determinantes para aceder a esta carreira.')"
                >
                  <div

                    class="open-sans-regular
              fs-12
              text-light
              text-left
              content-card-employability-2"
                    style=" display: flex; flex-direction: column; height: 100%;"
                  >
                    <div class="rubik-regular fs-16 text-light title">
                      Vigilante de Cibercalamidade
                    </div>
                    <div
                      class="mt-16 description"
                    >
                      Com o aumento exponencial de atentados cibernéticos, está a tornar-se cada vez mais importante para as empresas constituírem equipas permanentemente dedicadas à vigilância e prevenção das suas fragilidades informáticas e a exposição ao risco de ciberataques de grande dimensão. Formação em matemática, ciências, estatística ou áreas relacionadas são determinantes para aceder a esta carreira.
                    </div>
                  </div>
                  <div
                    class="content-card-employability-1"
                    style="display: flex; flex-direction: column; height: 100%;"
                  >
                    <div

                      style="display: flex;"
                      class="number"
                    >
                      10
                    </div>
                    <div

                      style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                      class="title"
                    >
                      Vigilante de Cibercalamidade
                    </div>
                  </div>
                </div>

                <div
                  class="card-employability"
                  :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/11-arquiteto-estadios-digitais.jpg')+')' }"
                  @click="showModal('Assim como os fãs dos desportos tradicionais, os amantes de jogos e desportos digitais também apreciam espaços onde possam reunir-se para partilharem experiências ou disputarem desafios. Em resposta a esta necessidade surge o Arquiteto de Estádios Digitais responsável pela construção de estádios, tecnologicamente adaptados, para acolher os amantes do gaming e dos jogos digitais.')"
                >
                  <div

                    class="open-sans-regular
              fs-12
              text-light
              text-left
              content-card-employability-2"
                    style=" display: flex; flex-direction: column; height: 100%;"
                  >
                    <div class="rubik-regular fs-16 text-light title">
                      Arquiteto de Estádios Digitais
                    </div>
                    <div
                      class="mt-16 description"
                    >
                      Assim como os fãs dos desportos tradicionais, os amantes de jogos e desportos digitais também apreciam espaços onde possam reunir-se para partilharem experiências ou disputarem desafios. Em resposta a esta necessidade surge o Arquiteto de Estádios Digitais responsável pela construção de estádios, tecnologicamente adaptados, para acolher os amantes do gaming e dos jogos digitais.
                    </div>
                  </div>
                  <div
                    class="content-card-employability-1"
                    style="display: flex; flex-direction: column; height: 100%;"
                  >
                    <div

                      style="display: flex;"
                      class="number"
                    >
                      11
                    </div>
                    <div

                      style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                      class="title"
                    >
                      Arquiteto de Estádios Digitais
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="card-employability height-double"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/12-arquiteto-maritimo.jpg')+')' }"
                @click="showModal('Profissionais com conhecimento técnico suficiente para criar soluções arquitectónicas que permitam mitigar os efeitos da subida dos níveis do mar nas cidades costeiras.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Arquiteto Marítimo
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Profissionais com conhecimento técnico suficiente para criar soluções arquitectónicas que permitam mitigar os efeitos da subida dos níveis do mar nas cidades costeiras.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div

                    style="display: flex;"
                    class="number"
                  >
                    12
                  </div>
                  <div

                    style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                    class="title"
                  >
                    Arquiteto Marítimo
                  </div>
                </div>
              </div>

              <div

                class="card-employability height-double"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/13-guardiao-id.jpg')+')' }"
                @click="showModal('A necessidade de filtrar e verificar a origem das informações está a tornar imperativa a qualificação de profissionais, com sólidos conhecimentos tecnológicos e gosto pela confirmação de factos, para filtrar e repor a verdade digital.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Guardião de Identidade Digital
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    A necessidade de filtrar e verificar a origem das informações está a tornar imperativa a qualificação de profissionais, com sólidos conhecimentos tecnológicos e gosto pela confirmação de factos, para filtrar e repor a verdade digital.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div

                    style="display: flex;"
                    class="number"
                  >
                    13
                  </div>
                  <div

                    style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                    class="title"
                  >
                    Guardião de Identidade Digital
                  </div>
                </div>
              </div>
              <div
                class="card-employability-quad"
              >
                <div
                  class="card-employability"
                  :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/14-designer-personalidade.jpg')+')' }"
                  @click="showModal('Pensar e desenvolver vozes e personalidades para produtos/ serviços digitais, permitindo melhorar a experiência dos utilizadores e aproximá-los da marca/produto.')"
                >
                  <div

                    class="open-sans-regular
              fs-12
              text-light
              text-left
              content-card-employability-2"
                    style=" display: flex; flex-direction: column; height: 100%;"
                  >
                    <div class="rubik-regular fs-16 text-light title">
                      Designer de Personalidade
                    </div>
                    <div
                      class="mt-16 description"
                    >
                      Pensar e desenvolver vozes e personalidades para produtos/ serviços digitais, permitindo melhorar a experiência dos utilizadores e aproximá-los da marca/produto.
                    </div>
                  </div>
                  <div
                    class="content-card-employability-1"
                    style="display: flex; flex-direction: column; height: 100%;"
                  >
                    <div

                      style="display: flex;"
                      class="number"
                    >
                      14
                    </div>
                    <div

                      style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                      class="title"
                    >
                      Designer de Personalidade
                    </div>
                  </div>
                </div>

                <div
                  class="card-employability"
                  :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/15-gestor-rv.jpg')+')' }"

                  @click="showModal('Gerir e programar experiências de realidade virtual para a área comercial, envolvendo marketing, publicidade e estratégias de comunicação, adaptadas a clientes de diferentes perfis e localização geográficas distintas.')"
                >
                  <div

                    class="open-sans-regular
              fs-12
              text-light
              text-left
              content-card-employability-2"
                    style=" display: flex; flex-direction: column; height: 100%;"
                  >
                    <div class="rubik-regular fs-16 text-light title">
                      Gestor de Realidade Virtual para Retalho
                    </div>
                    <div
                      class="mt-16 description"
                    >
                      Gerir e programar experiências de realidade virtual para a área comercial, envolvendo marketing, publicidade e estratégias de comunicação, adaptadas a clientes de diferentes perfis e localização geográficas distintas.
                    </div>
                  </div>
                  <div
                    class="content-card-employability-1"
                    style="display: flex; flex-direction: column; height: 100%;"
                  >
                    <div

                      style="display: flex;"
                      class="number"
                    >
                      15
                    </div>
                    <div

                      style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                      class="title"
                    >
                      Gestor de Realidade Virtual para Retalho
                    </div>
                  </div>
                </div>

                <div
                  class="card-employability"
                  :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/16-consultor-agricultura-vertical.jpg')+')' }"

                  @click="showModal('O papel dos consultores de agricultura vertical passa por assegurar a instalação de hortas verticais, a sua manutenção e a formação dos utilizadores. Entre os clientes destes profissionais poderão estar entidades públicas e privadas, ou pequenos agricultores domésticos.')"
                >
                  <div

                    class="open-sans-regular
              fs-12
              text-light
              text-left
              content-card-employability-2"
                    style=" display: flex; flex-direction: column; height: 100%;"
                  >
                    <div class="rubik-regular fs-16 text-light title">
                      Consultor de Agricultura Vertical
                    </div>
                    <div
                      class="mt-16 description"
                    >
                      O papel dos consultores de agricultura vertical passa por assegurar a instalação de hortas verticais, a sua manutenção e a formação dos utilizadores. Entre os clientes destes profissionais poderão estar entidades públicas e privadas, ou pequenos agricultores domésticos.
                    </div>
                  </div>
                  <div
                    class="content-card-employability-1"
                    style="display: flex; flex-direction: column; height: 100%;"
                  >
                    <div

                      style="display: flex;"
                      class="number"
                    >
                      16
                    </div>
                    <div

                      style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                      class="title"
                    >
                      Consultor de Agricultura Vertical
                    </div>
                  </div>
                </div>

                <div
                  class="card-employability"
                  :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/17-gestor-riscos.jpg')+')' }"

                  @click="showModal('O gestor de riscos tecnológicos estará focado na monitorização permanente das soluções de inteligência artificial utilizadas pelas empresas, de forma a minimizar os riscos e consequências para os profissionais da empresa ou clientes.')"
                >
                  <div

                    class="open-sans-regular
              fs-12
              text-light
              text-left
              content-card-employability-2"
                    style=" display: flex; flex-direction: column; height: 100%;"
                  >
                    <div class="rubik-regular fs-16 text-light title">
                      Gestor de Riscos Tecnológicos
                    </div>
                    <div
                      class="mt-16 description"
                    >
                      O gestor de riscos tecnológicos estará focado na monitorização permanente das soluções de inteligência artificial utilizadas pelas empresas, de forma a minimizar os riscos e consequências para os profissionais da empresa ou clientes.
                    </div>
                  </div>
                  <div
                    class="content-card-employability-1"
                    style="display: flex; flex-direction: column; height: 100%;"
                  >
                    <div

                      style="display: flex;"
                      class="number"
                    >
                      17
                    </div>
                    <div

                      style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                      class="title"
                    >
                      Gestor de Riscos Tecnológicos
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="card-employability half"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/18-gestao-subscricoes.jpg')+')' }"

                @click="showModal('Na era da subscrição de serviços o especialista em Gestão de Subscrições é uma função que cruza o marketing e a comunicação, com capacidades analíticas e conhecimento tecnológico. O seu papel é encontrar os melhores planos para o seu cliente, melhorando a experiência dos pacotes existentes, evitando assim insatisfações dos consumidores.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Especialista em Gestão de Subscrições
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Na era da subscrição de serviços o especialista em Gestão de Subscrições é uma função que cruza o marketing e a comunicação, com capacidades analíticas e conhecimento tecnológico. O seu papel é encontrar os melhores planos para o seu cliente, melhorando a experiência dos pacotes existentes, evitando assim insatisfações dos consumidores.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div

                    style="display: flex;"
                    class="number"
                  >
                    18
                  </div>
                  <div

                    style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                    class="title"
                  >
                    Especialista em Gestão de Subscrições
                  </div>
                </div>
              </div>

              <div
                class="card-employability half"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/19-engenheiros-carros-voadores.jpg')+')' }"

                @click="showModal('Em cinco anos, antecipa a Cognizant, os carros voadores serão uma opção no mercado. Será necessária formação em engenharia (aeronáutica, de materiais e informática), desenvolvimento de software e tecnologia.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Engenheiros de Carros Voadores
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Em cinco anos, antecipa a Cognizant, os carros voadores serão uma opção no mercado. Será necessária formação em engenharia (aeronáutica, de materiais e informática), desenvolvimento de software e tecnologia.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div

                    style="display: flex;"
                    class="number"
                  >
                    19
                  </div>
                  <div

                    style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                    class="title"
                  >
                    Engenheiros de Carros Voadores
                  </div>
                </div>
              </div>

              <div
                class="card-employability half"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/20-designer-interfaces-hapticos.jpg')+')' }"

                @click="showModal('Maximizar a experiência do consumidor por meio de interfaces tácteis, o designer de interfaces hápticos consegue, com o peso e a textura certa, transformar um produto barato num equipamento de luxo.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Designer de Interfaces Hápticos
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Maximizar a experiência do consumidor por meio de interfaces tácteis, o designer de interfaces hápticos consegue, com o peso e a textura certa, transformar um produto barato num equipamento de luxo.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div

                    style="display: flex;"
                    class="number"
                  >
                    20
                  </div>
                  <div

                    style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                    class="title"
                  >
                    Designer de Interfaces Hápticos
                  </div>
                </div>
              </div>

              <div
                class="card-employability half"
                :style="{ backgroundImage: 'url('+require('../../assets/Elementos/6 Empregabilidade/21-gestores-contributo-social.jpg')+')' }"

                @click="showModal('Tem como responsabilidade dominar um briefing corporativo e usar todos os canais sociais para ajudar os clientes a construir, manter, gerenciar e polir os seus propósitos para consumidores e funcionários.')"
              >
                <div

                  class="open-sans-regular
            fs-12
            text-light
            text-left
            content-card-employability-2"
                  style=" display: flex; flex-direction: column; height: 100%;"
                >
                  <div class="rubik-regular fs-16 text-light title">
                    Gestores de Contributo Social
                  </div>
                  <div
                    class="mt-16 description"
                  >
                    Tem como responsabilidade dominar um briefing corporativo e usar todos os canais sociais para ajudar os clientes a construir, manter, gerenciar e polir os seus propósitos para consumidores e funcionários.
                  </div>
                </div>
                <div
                  class="content-card-employability-1"
                  style="display: flex; flex-direction: column; height: 100%;"
                >
                  <div

                    style="display: flex;"
                    class="number"
                  >
                    21
                  </div>
                  <div

                    style="align-items: center; display: flex; height: 100%; justify-content: center; margin-top: -36px;"
                    class="title"
                  >
                    Gestores de Contributo Social
                  </div>
                </div>
              </div>
            </b-col>
            <b-col
              cols="10"
              offset="1"
            >
              <div class="emplyability-more">
                Saiba Mais
              </div>

              <div class="emplyability-more-list">
                <a
                  class="emplyability-more-list-item"
                  href="http://www3.weforum.org/docs/WEF_Future_of_Jobs_2020.pdf"
                  target="_blank"
                >
                  <span>Ler “The Future of Jobs”</span><b-icon icon="chevron-right" />
                </a>
                <a
                  class="emplyability-more-list-item"
                  href="https://www.cognizant.com/whitepapers/21-more-jobs-of-the-future-a-guide-to-getting-and-staying-employed-through-2029-codex3928.pdf"
                  target="_blank"
                >
                  <span>Ler “More Jobs of the Future – A Guide to Getting and Staying Employed – Through 2029”</span><b-icon icon="chevron-right" />
                </a>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalShow"
      centered
      size="md"
      ok-variant="success"
      header-class="p-2 border-bottom-0"
      footer-class="p-2 border-top-0"
      hide-footer
    >
      {{ modalText }}
    </b-modal>
  </b-container>
</template>

<script>
export default {
  name: 'Employability',
  data() {
    return {
      modalShow: false,
      modalText: null,
    };
  },
  methods: {
    showModal(text) {
      this.modalText = text;
      this.modalShow = true;
    },
  },
};
</script>
