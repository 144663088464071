<template>
  <div id="app">
    <c-navbar :scroll="windowTop" />
    <router-view
      v-if="loaded"
      class="mt-162 bg-light-grey "
    />
    <c-footer />
    <vue-cookie-accept-decline
      :debug="false"
      :position="'bottom'"
      :transition-name="'slideFromBottom'"
      :element-id="'cookieStatusBanner'"
      :status="status"
      type="bar"
      disable-decline
      @clickedAccept="cookieClickedAccept"
      @clickedDecline="cookieClickedDecline"
    >
      <div slot="message">
        Este site utiliza cookies para permitir uma melhor experiência por parte do utilizador. Ao navegar no site estará a consentir a sua utilização.
        <a
          href="https://www.isq.pt/politica-de-privacidade/"
          target="_blank"
        >Utilização e privacidade</a>
      </div>

      <!-- Optional -->
      <div
        slot="acceptContent"
        class="button primary"
      >
        Aceitar
      </div>
    </vue-cookie-accept-decline>
  </div>
</template>

<script>

import Navbar from './components/layout/Navbar.vue';
import Footer from './components/layout/Footer.vue';
import { get } from './services/api';

export default {
  name: 'App',
  components: {
    'c-navbar': Navbar,
    'c-footer': Footer,
  },
  data() {
    return {
      windowTop: window.top.scrollY,
      status: null,
      loaded: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  async created() {
    if (!this.$store.getters.state.loggedIn && this.$router.currentRoute.path.includes('diagnostico') && !this.$router.currentRoute.path.includes('diagnosticoi40')) {
      this.$router.push({ name: 'auth' });
    }

    await get('configs').then((result) => {
      this.$store.dispatch('setConfigs', result.data);
    });

    /* await fetch('https://api.ipify.org?format=json')
      .then((x) => x.json())
      .then(({ ip }) => {
        this.$store.commit('setIpAddress', ip);
      }); */
    this.loaded = true;
  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY; /* or: e.target.documentElement.scrollTop */
    },
    cookieStatus() {
      this.status = localStorage.getItem('S2F@cookies') || null;
    },
    cookieClickedAccept() {
      localStorage.setItem('S2F@cookies', 'accept');
    },
    cookieClickedDecline() {
      localStorage.setItem('S2F@cookies', 'decline');
    },
  },
};
</script>
