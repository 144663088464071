<template>
  <div style="height: 100%">
    <b-form-group
      :id="`horizontal_${index}`"
      :checked="mutableSelect"
      value-field="value"
      :disabled="notEnabled"
      class="open-sans-regular fs-13 h-100p child-100p"
    >
      <div
        class="custom-radio-box"
        style="height: 100%"
      >
        <b-form-radio
          v-for="(option, index2) in options"
          :key="option.id"
          v-model="mutableSelect"
          :value="option.value"
          :class="options.map(el => el.value).indexOf(mutableSelect) > index2 ? 'prev' : (options.map(el => el.value).indexOf(mutableSelect) == index2 ? 'active' : 'inactive')"
          size="lg"
          @change="check"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'RadioBox',
  props: {
    question: String,
    labels: Object,
    options: Array,
    index: Number,
    selected: Number,
    onClick: Function,
    notEnabled: Boolean,
  },
  data() {
    return {
      preventPOST: false,
      mutableSelect: this.selected,
    };
  },
  watch: {
    selected(newValue) {
      this.preventPOST = true;
      this.mutableSelect = newValue;
    },
  },
  methods: {
    check(value) {
      if (!this.preventPOST) {
        this.onClick(value, this.index);
      }
      this.preventPOST = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .child-100p::v-deep div {
    height: 100%;
  }
</style>
