<template>
  <b-overlay
    :show="loading"
    style="width: 100%; min-height: calc(100vh - 305px)"
    opacity="0.5"
    z-index="50"
  >
    <b-container
      class="results-container"
      fluid
    >
      <b-row class="results-prepare">
        <b-container>
          <b-row class="results-container-tabs">
            <b-col cols="12">
              <div
                v-if="results.length > 0"
                class="results-tabs"
              >
                <div
                  v-for="result in results"
                  :key="result.id"
                  class="tab"
                  :class="state === result.order && 'active'"
                  @click="setState(result)"
                >
                  {{ result.label }}
                </div>
                <b-dropdown
                  v-if="resultSelected.code === 'all_resources'"
                  no-caret
                  class="ml-auto"
                >
                  <template #button-content>
                    <div
                      class="button outlined secondary"
                    >
                      <b-icon icon="sliders" />
                      Filtros
                    </div>
                  </template>

                  <b-dropdown-header>
                    <div class="d-flex flex-column justify-content-between align-items-start">
                      Filtros

                      <b-button
                        class="button secondary my-3 sm"
                        @click="() => {
                          removeAllFilters()
                        }"
                      >
                        Remover Filtros
                      </b-button>
                    </div>
                  </b-dropdown-header>
                  <b-dropdown-form>
                    <b-form-checkbox
                      v-model="allSectors"
                      size="md"
                      class="mb-2"
                      @change="allSectorsChanged"
                    >
                      Setores
                    </b-form-checkbox>
                    <b-form-checkbox-group
                      v-model="sectors"
                      class="pl-2"
                      :options="industries"
                      label-field="label"
                      text-field="label"
                      value-field="code"
                      size="md"
                      stacked
                      @change="sectorSelectedChanged"
                    />
                  </b-dropdown-form>

                  <b-dropdown-divider />

                  <b-dropdown-form>
                    <b-form-checkbox
                      v-model="allResources"
                      size="md"
                      class="mb-2"
                      @change="allResourceTypesChanged"
                    >
                      Recursos
                    </b-form-checkbox>
                    <b-form-checkbox-group
                      v-model="resourceTypesSelected"
                      class="pl-2"
                      :options="resourceTypes"
                      label-field="label"
                      text-field="label"
                      value-field="code"
                      size="md"
                      stacked
                      @change="resourceTypeSelectedChanged"
                    />
                  </b-dropdown-form>

                  <b-dropdown-divider />

                  <b-dropdown-form>
                    <b-form-checkbox
                      v-model="allFormats"
                      class="mb-2"
                      size="md"
                      @change="allResourceFormatsChanged"
                    >
                      Formatos
                    </b-form-checkbox>
                    <b-form-checkbox-group
                      v-model="resourceFormatsSelected"
                      class="pl-2"
                      :options="resourceFormats"
                      label-field="label"
                      text-field="label"
                      value-field="code"
                      size="md"
                      stacked
                      @change="resourceFormatSelectedChanged"
                    />
                  </b-dropdown-form>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
          <b-row
            class="results-tab-content"
          >
            <component
              :is="results.find(el => el.order === state).component"
              v-if="results.find(el => el.order === state) && results.find(el => el.order === state).component"
              :set-loading="setLoading"
              fill
              :filters="{
                industries: sectors,
                resources: resourceTypesSelected,
                formats: resourceFormatsSelected,
              }"
            />
          </b-row>
        </b-container>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import { get } from '../../services/api';
import Roadmaps from '../ui/Results/Roadmaps.vue';
import AllResources from '../ui/Results/AllResources.vue';
import Report from '../ui/Results/Report.vue';
import CaseStudies from '../ui/Results/CaseStudies.vue';
import GoodPractices from '../ui/Results/GoodPractices.vue';
import Videos from '../ui/Results/Videos.vue';
import FinancingGuides from '../ui/Results/FinancingGuides.vue';
import Events from '../ui/Results/Events.vue';

export default {
  name: 'Results',
  components: {
    roadmaps: Roadmaps,
    allResources: AllResources,
    report: Report,
    caseStudies: CaseStudies,
    goodPractices: GoodPractices,
    videos: Videos,
    financingGuides: FinancingGuides,
    events: Events,
  },
  data() {
    return {
      state: 1,
      loading: false,
      results: [],
      resultSelected: null,

      allSectors: false,
      allResources: false,
      allFormats: false,
      sectors: [],
      resourceTypesSelected: [],
      resourceFormatsSelected: [],

      resourceTypes: [],
      industries: [],
      resourceFormats: [],
    };
  },
  computed: {
    canViewResults() {
      const item = this.$store.getters.configs && this.$store.getters.configs.find((el) => el.code === 'show_results_tab');

      return (item && item.value === '1') || (this.$store.getters.user && this.$store.getters.user.permission_id === 1);
    },
  },
  async created() {
    this.setLoading(true);

    if (!this.canViewResults) {
      this.$router.push({ name: 'home' });
    }
    await Promise.all([
      this.getResults(),
      this.loadResourceTypes(),
      this.loadResourceFormats(),
      this.loadIndustries(),
    ]);
    this.setLoading(false);
  },
  methods: {
    setState(result) {
      this.state = result.order;
      this.resultSelected = result;
    },
    setLoading(value) {
      this.loading = value;
    },
    async getResults() {
      await get('results').then((response) => {
        this.results = response.data;
        this.resultSelected = this.results[0];
      });
    },
    async loadResourceTypes() {
      await get('results/resource-types/', { all: true }).then((response) => {
        this.resourceTypes = response.data;
      });
    },
    async loadResourceFormats() {
      await get('results/resource-formats/', { all: true }).then((response) => {
        this.resourceFormats = response.data;
      });
    },
    async loadIndustries() {
      await get('results/industries/', { all: true }).then((response) => {
        this.industries = response.data;
      });
    },
    removeAllFilters() {
      this.allSectors = false;
      this.sectors = [];
      this.allResources = false;
      this.resourceTypesSelected = [];
      this.allFormats = false;
      this.resourceFormatsSelected = [];
    },
    allSectorsChanged(value) {
      if (value) {
        this.industries.forEach((el) => {
          if (!this.sectors.includes(el.code)) {
            this.sectors.push(el.code);
          }
        });
      } else {
        this.sectors = [];
      }
    },
    sectorSelectedChanged() {
      if (this.sectors.length === this.industries.length) {
        this.allSectors = true;
      } else {
        this.allSectors = false;
      }
    },
    allResourceTypesChanged(value) {
      if (value) {
        this.resourceTypes.forEach((el) => {
          if (!this.resourceTypesSelected.includes(el.code)) {
            this.resourceTypesSelected.push(el.code);
          }
        });
      } else {
        this.resourceTypesSelected = [];
      }
    },
    resourceTypeSelectedChanged() {
      if (this.resourceTypesSelected.length === this.resourceTypes.length) {
        this.allResources = true;
      } else {
        this.allResources = false;
      }
    },
    allResourceFormatsChanged(value) {
      if (value) {
        this.resourceFormats.forEach((el) => {
          if (!this.resourceFormatsSelected.includes(el.code)) {
            this.resourceFormatsSelected.push(el.code);
          }
        });
      } else {
        this.resourceFormatsSelected = [];
      }
    },
    resourceFormatSelectedChanged() {
      if (this.resourceFormatsSelected.length === this.resourceFormats.length) {
        this.allFormats = true;
      } else {
        this.allFormats = false;
      }
    },
  },
};
</script>
