<template>
  <div class="question-content">
    <b-overlay
      :show="loading || !show"
      rounded="sm"
      style="min-height: calc(100vh - 4.5714rem - 2.1429rem - 2.1429rem);"
    >
      <div
        v-if="!loading && show"
        class="question-container"
      >
        <div
          class="question-row"
          :set="dimension = getDimensionsKeys()[dimension_store]"
        >
          <div
            class="question-arrow"
            @click="prevQuestion"
          >
            <b-icon icon="chevron-left" />
          </div>
          <div
            class="question"
            :set="question = getQuestionKeys(dimension)[question_store]"
          >
            <div
              v-if="errors.length > 0
                && errors.some(e => e.dimension === dimension_store && e.question === question_store)"
              class="feedback-row "
            >
              <div
                class="error-danger"
              >
                Por favor, responda à pergunta
              </div>
            </div>
            <c-choice
              v-if="dimensions[dimension].questions[question].type == 1 && selected !== null && responseGetted"
              :on-click="getChoice"
              :question="dimensions[dimension].questions[question].question"
              :description="dimensions[dimension].questions[question].description"
              :choices="dimensions[dimension].questions[question].choices"
              :selected="selected !== null ? selected.toString() : null"
              :not-enabled="notEnabled"
              :response-getted="responseGetted"
            />
            <c-multichoice-v2
              v-else-if="dimensions[dimension].questions[question].type == 2 && selected !== null && responseGetted"
              :on-click="getChoice"
              :question="dimensions[dimension].questions[question].question"
              :description="dimensions[dimension].questions[question].description"
              :choices="dimensions[dimension].questions[question].choices"
              :selected="selected !== null ? selected : null"
              :not-enabled="notEnabled"
              :response-getted="responseGetted"
            />
            <c-radio-box-group
              v-else-if="dimensions[dimension].questions[question].type == 3 && selected !== null && responseGetted"
              :on-click="getChoice"
              :question="dimensions[dimension].questions[question].question"
              :description="dimensions[dimension].questions[question].description"
              :choices="dimensions[dimension].questions[question].choices"
              :selected="
                selected !== null
                  ? selected
                  : Array(dimensions[dimension].questions[question].choices.length)
                    .fill(Math.min(...dimensions[dimension].questions[question].choices[0].value.value))
              "
              :response-getted="responseGetted"
              :not-enabled="notEnabled"
            />
          </div>
          <div
            class="question-arrow"
            @click="nextQuestion"
          >
            <b-icon icon="chevron-right" />
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import Choice from './Choice.vue';
import Multichoice from './Multichoice.vue';
import MultichoiceV2 from './MultichoiceV2.vue';
import RadioBoxGroup from './RadioBoxGroup.vue';

export default {
  name: 'QuestionContainer',
  components: {
    'c-choice': Choice,
    'c-multichoice': Multichoice,
    'c-multichoice-v2': MultichoiceV2,
    'c-radio-box-group': RadioBoxGroup,
  },
  props: {
    dimensions: Object,
    errors: Array,
    selected: [Array, Number],
    navigateTo: Function,
    getChoice: Function,
    prevQuestion: Function,
    nextQuestion: Function,
    getDimensionsKeys: Function,
    getQuestionKeys: Function,
    dimension_store: [Number, String],
    question_store: [Number, String],
    loading: Boolean,
    show: Boolean,
    notEnabled: Boolean,
    responseGetted: Boolean,
  },
};
</script>
