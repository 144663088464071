export default {
  state: {
    dimension: sessionStorage.getItem('dimension') || 0,
    question: sessionStorage.getItem('question') || 0,
    totalQuestions: sessionStorage.getItem('totalQuestions') || 0,
    currentQuestion: sessionStorage.getItem('currentQuestion') || 0,
    faultQuestions: null,
    quiz: sessionStorage.getItem('quiz') || null,
    disabled: sessionStorage.getItem('disabled') === 'true' || true,
    faultGeralQuestions: false,
    reportQuiz: null,
    responses: [],
    dimensions: [],
  },
  mutations: {
    setDimension(state, data) {
      state.dimension = data;
      sessionStorage.setItem('dimension', data);
    },
    setQuestion(state, data) {
      state.question = data;
      sessionStorage.setItem('question', data);
    },
    setTotalQuestions(state, data) {
      const nonRequired = state.responses.map((el) => el.question_code);
      let total = 0;
      Object.entries(state.dimensions).forEach(([, dimension]) => {
        total += dimension.questions
          .filter((el) => !el.required).map((el) => el.code).filter((el) => nonRequired.includes(el)).length;
      });
      const totalQuestions = parseInt(data, 10) + parseInt(total, 10);
      state.totalQuestions = totalQuestions;
      sessionStorage.setItem('totalQuestions', totalQuestions);
    },
    setCurrentQuestion(state, data) {
      state.currentQuestion = data;
      sessionStorage.setItem('currentQuestion', data);
    },
    setQuiz(state, data) {
      state.quiz = data;
      sessionStorage.setItem('quiz', data);
    },
    setDisabled(state, data) {
      state.disabled = data;
      sessionStorage.setItem('disabled', data);
    },
    setFaultQuestions(state, data) {
      state.faultQuestions = data;
    },
    setFaultGeralQuestions(state, data) {
      state.faultGeralQuestions = data;
    },
    setReportQuiz(state, value) {
      state.reportQuiz = value;
    },
    setResponses(state, value) {
      const nonRequired = value.map((el) => el.question_code);
      let total = 0;
      let all = 0;
      Object.entries(state.dimensions).forEach(([, dimension]) => {
        total += dimension.questions
          .filter((el) => !el.required).map((el) => el.code).filter((el) => nonRequired.includes(el)).length;
        all += dimension.questions.filter((el) => el.required).length;
      });
      const totalQuestions = parseInt(all, 10) + parseInt(total, 10);
      state.totalQuestions = totalQuestions;
      sessionStorage.setItem('totalQuestions', totalQuestions);
      state.responses = value;
    },
    setDimensions(state, value) {
      state.dimensions = value;
    },
  },
  actions: {
    setReportQuiz({ commit }, id) {
      commit('setReportQuiz', id);
    },
    setDimension({ commit }, value) {
      commit('setDimension', value);
    },
    setQuestion({ commit }, value) {
      commit('setQuestion', value);
    },
    setDimensions({ commit }, dimensions) {
      commit('setDimensions', dimensions);
    },
    setResponses({ commit, state }, responses) {
      commit('setResponses', responses);
      /* const nonRequired = responses.map((el) => el.question_code);
      let total = 0;
      Object.entries(state.dimensions).forEach(([, dimension]) => {
        total += dimension.questions
          .filter((el) => !el.required).map((el) => el.code).filter((el) => nonRequired.includes(el)).length;
      });
      commit('setTotalQuestions', state.totalQuestions + total); */
    },
  },
  getters: {
    dimension: (state) => state.dimension,
    question: (state) => state.question,
    totalQuestions: (state) => state.totalQuestions,
    currentQuestion: (state) => state.currentQuestion,
    quiz: (state) => state.quiz,
    faultQuestions: (state) => state.faultQuestions,
    disabled: (state) => state.disabled,
    faultGeralQuestions: (state) => state.faultGeralQuestions,
    reportQuiz: (state) => state.reportQuiz,
    responses: (state) => state.responses,
    dimensions: (state) => state.dimensions,
  },
};
