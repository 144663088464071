<template>
  <div :style="cssVars">
    <svg
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      width="320"
      :height="height"
      viewBox="0 0 240 320"
    >

      <g>
        <path
          id="dim4"
          class="cls-2 dimension_4"
          d="M 94.1 169.3 l -0.5 0.3 L 19.2 212.5 c 20.4 31.4 55 52.8 94.8 54.7 v -86.4 c -7.9 -1.4 -14.9 -5.6 -20 -11.5 Z"
        />
        <path
          class="cls-2 dimension_5"
          d="M 88 158.9 c -1.3 -3.6 -2 -7.5 -2 -11.5 s 0.7 -7.9 2 -11.5 l -0.5 -0.3 L 13.2 92.6 C 4.8 109 0 127.6 0 147.3 s 4.8 38.3 13.2 54.7 l 74.4 -42.9 l 0.5 -0.3 Z"
        />
        <path
          class="cls-2 dimension_6"
          d="M 94.1 125.4 c 5 -5.9 12 -10.1 20 -11.5 V 27.5 c -39.8 2 -74.4 23.3 -94.8 54.7 l 74.4 42.9 l 0.5 0.3 Z"
        />
        <path
          class="cls-2 dimension_3"
          d="M 146 169.3 c -5 5.9 -12 10.1 -20 11.5 v 86.4 c 39.8 -2 74.4 -23.3 94.8 -54.7 l -74.4 -42.9 l -0.5 -0.3 Z"
        />
        <path
          class="cls-2 dimension_2"
          d="M 226.8 92.6 l -74.4 42.9 l -0.5 0.3 c 1.3 3.6 2 7.5 2 11.5 s -0.7 7.9 -2 11.5 l 0.5 0.3 l 74.4 42.9 c 8.4 -16.4 13.2 -35 13.2 -54.7 s -4.8 -38.3 -13.2 -54.7 Z"
        />
      </g>
      <path
        class="cls-1 dimension_1"
        d="M 126.5 0 V 113.6 c 7.9 1.4 14.9 5.6 19.9 11.5 l 0.5 -0.3 l 97.7 -56.4 C 219.6 28.9 176.2 2.2 126.5 0 Z"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'Dim1',
  props: {
    height: {
      type: Number,
      default: 200,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        '--color': this.color,
      };
    },
  },
  mounted() {
    const dim1 = document.querySelectorAll('.dimension_1');
    dim1.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 1);
    }));

    const dim2 = document.querySelectorAll('.dimension_2');
    dim2.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 2);
    }));

    const dim3 = document.querySelectorAll('.dimension_3');
    dim3.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 3);
    }));

    const dim4 = document.querySelectorAll('.dimension_4');
    dim4.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 4);
    }));

    const dim5 = document.querySelectorAll('.dimension_5');
    dim5.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 5);
    }));

    const dim6 = document.querySelectorAll('.dimension_6');
    dim6.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 6);
    }));
  },
};
</script>
<style lang="scss" scoped>
  .cls-1 {
    cursor: pointer;
    fill: var(--color);
    animation: blinkingFill 2s ease-in-out;

    &:hover {
      opacity: .32;
    }
  }

  .cls-2 {
    fill: #A8AAAA;
    cursor: pointer;

    &:hover {
      fill: var(--color);
      opacity: 1;
    }
  }

  @keyframes blinkingFill {
    0%		{ fill: var(--color);}
    25%		{ fill: #A8AAAA;}
    50%		{ fill: var(--color)}
    75%		{ fill: #A8AAAA;}
    100%	{ fill: var(--color)}
  }
</style>
