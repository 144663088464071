<template>
  <b-overlay
    :show="loading"
  >
    <div
      class="auth"
    >
      <b-container>
        <div class="auth-container">
          <div class="login">
            <div class="title">
              Registo
            </div>
            <div class="description">
              <p>
                Aceda, através de um registo gratuito, aos resultados gerados, bem como a ampla divulgação através da integração de mecanismos automáticos de disseminação de conteúdos pelos canais online.
              </p>
              Já tem registo? <a
                v-b-modal.modal-1
                class="link"
              >Entrar</a>
            </div>
          </div>
          <div
            class="register"
          >
            <div
              v-if="error || errorEmail || companyError || emailSendedRegister "
              class="feedback-row "
            >
              <div
                v-if="error"
                class="error-danger"
              >
                Ocorreu um erro, por favor tente submeter o questionário mais tarde!
              </div>
              <div
                v-if="errorEmail && !emailSendedRegister"
                class="error-danger"
              >
                Já existe um utilizador registado com este email!
                <div
                  class="mt-2"
                  style="text-decoration: underline;"
                >
                  <a
                    v-b-modal.showSendEmail
                  >Recuperar palavra-passe</a>
                </div>
              </div>
              <div
                v-if="companyError"
                class="error-success"
              >
                Esta empresa já está registada na Plataforma SHIFT2Future. O responsável pela gestão de novos utilizadores {{ hiddenEmail && `(${hiddenEmail})` }} será notificado para adicionar o seu registo. Assim que o mesmo estiver concluído, será notificado por email
              </div>
              <div
                v-if="emailSendedRegister"
                class="error-success"
              >
                Para continuar o registo é necessário confirmar o seu email através do link enviado para a sua caixa de email.
              </div>
            </div>
            <b-form
              ref="signUp"
              @submit="onSubmitSignUp"
            >
              <b-form-group
                id="input-group-1"
                label="Empresa *"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.company"
                  required
                  placeholder="Nome da empresa"
                />
              </b-form-group>
              <b-form-group
                id="input-group-6"
                label="NIF *"
                label-for="input-6"
                class="hide-arrows"
              >
                <b-form-input
                  id="input-6"
                  v-model.number="form.nif"
                  required
                  placeholder="NIF da empresa"
                  :state="vatIDValid"
                  :formatter="maxLength"
                  number
                  @input="value => validateVATID(value)"
                />
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-2"
                    label="Respondente *"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="form.name"
                      required
                      placeholder="Nome do respondente"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-3"
                    label="Cargo"
                    label-for="input-3"
                  >
                    <b-form-select
                      v-model="form.role"
                      :options="options"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group
                id="input-group-4"
                label="Email *"
                label-for="input-4"
              >
                <b-form-input
                  id="input-4"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Email do respondente"
                />
              </b-form-group>
              <!--
            <b-form-group
              id="input-group-5"
              label="Password"
              label-for="input-5"
            >
              <b-form-input
                id="input-5"
                v-model="form.password"
                type="password"
                required
                :state="passwordValid"
                placeholder="Password"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                A password deve conter pelo menos 8 caractéres e 2 das seguintes condições:
                <ul>
                  <li>Caracteres especiais</li>
                  <li>Letras maiúsculas e minúsculas</li>
                  <li>Caracteres numéricos</li>
                </ul>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-row>
              <b-col>
                Segurança da password: {{ strong == 0 ? 'Nenhuma' : strong == 1 ? 'Fraca' : strong == 2 ? 'Média' : 'Forte' }}
              </b-col>
            </b-row>
            <b-row class="mb-20">
              <b-col style="padding-left: 15px; padding-right: 1px;">
                <div
                  style=" height: 20px;"
                  :style="strong == 0 ? 'background-color: var(--danger)' : strong == 1 ? 'background-color: var(--danger)' : strong == 2 ? 'background-color: var(--warning)' : 'background-color: var(--success)' "
                />
              </b-col>
              <b-col style="padding-left: 1px; padding-right: 1px;">
                <div
                  style=" height: 20px;"
                  :style="strong >= 1 ? strong == 0 ? 'background-color: transparent' : strong == 1 ? 'background-color: var(--danger)' : strong == 2 ? 'background-color: var(--warning)' : 'background-color: var(--success)' : 'background-color: transparent' "
                />
              </b-col>
              <b-col style="padding-left: 1px; padding-right: 1px;">
                <div
                  style=" height: 20px;"
                  :style="strong >= 2 ? strong == 0 ? 'background-color: transparent' : strong == 1 ? 'background-color: var(--danger)' : strong == 2 ? 'background-color: var(--warning)' : 'background-color: var(--success)' : 'background-color: transparent' "
                />
              </b-col>
              <b-col style="padding-left: 1px; padding-right: 15px;">
                <div
                  style=" height: 20px;"
                  :style="strong >= 3 ? strong == 0 ? 'background-color: transparent' : strong == 1 ? 'background-color: var(--danger)' : strong == 2 ? 'background-color: var(--warning)' : 'background-color: var(--success)' : 'background-color: transparent' "
                />
              </b-col>
            </b-row>
            <b-form-group
              id="input-group-6"
              label="Confirmar password"
              label-for="input-6"
              class="open-sans-light fs-14 text-dark"
            >
              <b-form-input
                id="input-6"
                v-model="form.cpassword"
                type="password"
                required
                placeholder="Confirmar password"
                :state="equalPasswords"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                As passwords devem coincidir.
              </b-form-invalid-feedback>
            </b-form-group>
             -->
              <b-form-checkbox
                id="checkbox-1"
                v-model="form.status"
                name="checkbox-1"
                value="accepted"
                unchecked-value="not_accepted"
                required
                class="text-dark-grey fs-13"
              >
                Dou o meu consentimento ao ISQ, para tratamento de dados recolhidos no âmbito do presente questionário que se destinam exclusivamente ao estudo da maturidade das empresas quanto à indústria 4.0 e o respetivo mapeamento nacional, nos termos da Política de Privacidade, que li e aceito. <a
                  href="https://www.isq.pt/politica-de-privacidade/"
                  target="_blank"
                >Política de Privacidade</a> *
              </b-form-checkbox>
              <b-form-group
                label="Deseja ser contactado para efetuar o diagonóstico assistido e realizar uma análise mais detalhada relativa à maturidade tecnológica da sua empresa?**"
                class="text-dark-grey"
              >
                <b-form-radio-group

                  id="radio-group-2"
                  v-model="form.selected"
                  name="radio-sub-component"
                  required
                  class="text-dark-grey"
                >
                  <b-form-radio
                    value="yes"
                  >
                    Sim
                  </b-form-radio>
                  <b-form-radio value="no">
                    Não
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <div class="register-footer">
                <div
                  class="text-dark-grey"
                >
                  * Campo de preenchimento obrigatório
                </div>
                <div class="submit">
                  <!-- v-if="passwordValid && equalPasswords" -->
                  <div>
                    <button
                      v-if="vatIDValid"
                      class="button primary"
                      type="submit"
                    >
                      Começar
                    </button>
                  </div>

                  <div class="tool">
                    <div>
                      Ferramenta
                    </div>
                    <img
                      src="../../../assets/shift_body-color.svg"
                      height="32"
                    >
                  </div>
                </div>
                <div class="privacy">
                  **A proteção da privacidade e dos dados pessoais constitui um compromisso fundamental do ISQ, para com o titular de dados pessoais que utiliza as plataformas digitais e os serviços ISQ. Com a aplicação do novo Regulamento Geral sobre a Proteção de Dados (RGPD), a partir de 25 de maio de 2018, O ISQ vem manifestar que se mantém fortemente empenhado e comprometido na proteção da privacidade e dos dados pessoais do respetivo titular que utiliza as plataformas digitais e os serviços ISQ e procede, nessa medida, à atualização da sua Política de Privacidade, em conformidade com todos os princípios e normas da proteção de dados que sustentam o RGPD. Procuramos explicar que dados pessoais recolhemos, para que finalidades os podemos usar, como os tratamos, com quem os partilhamos, durante quanto tempo os conservamos, bem como as formas de entrar em contacto connosco e de exercer os seus direitos. O ISQ sugere e convida à consulta das páginas dedicadas à privacidade e à proteção dos dados pessoais, cujas atualizações serão disponibilizadas nas plataformas digitais ISQ. <a
                    href="https://www.isq.pt/politica-de-privacidade/"
                    target="_blank"
                  >Política de Privacidade</a>
                </div>
              </div>
            </b-form>

            <b-modal
              id="showSendEmail"
              centered
              hide-footer
              header-class="border-bottom-0"
              footer-class="border-top-0"
              size="lg"
            >
              <div>
                <b-row v-if="userNotExist || emailSendError || emailSended">
                  <b-col
                    cols="12"
                    class="feedback-row"
                  >
                    <div
                      v-if="emailSendError"
                      class="error-danger"
                    >
                      Ocorreu um erro, por favor tente submeter o questionário mais tarde!
                    </div>
                    <div
                      v-if="userNotExist"
                      class="error-danger"
                    >
                      O email inserido não corresponde a nenhum utilizador!
                    </div>
                    <div
                      v-if="emailSended"
                      class="error-success"
                    >
                      Email para recuperação de password enviado com sucesso. Verifique a sua caixa de email!
                    </div>
                  </b-col>
                </b-row>
                <div class="title">
                  Recuperar password
                </div>
                <b-form
                  style="text-align: left;"
                  @submit="onSubmitSendEmail"
                >
                  <b-form-group
                    id="input-group-2"
                    label="Email *"
                    label-for="input-2-re"
                  >
                    <b-form-input
                      id="input-2-re"
                      v-model="sendEmail.email"
                      type="email"
                      required
                      placeholder="Email"
                    />
                  </b-form-group>
                  <div>* Campo de preenchimento obrigatório</div>
                  <div class="buttons justify-content-start">
                    <button
                      type="submit"
                      variant="primary"
                      class="button primary "
                    >
                      Recuperar password
                    </button>
                  </div>
                </b-form>
              </div>
            </b-modal>

            <b-modal
              id="modal-1"
              centered
              hide-footer
              header-class="border-bottom-0"
              footer-class="border-top-0"
              size="lg"
            >
              <div>
                <b-row v-if="wrong || errorLogin">
                  <b-col
                    cols="12"
                    class="feedback-row"
                  >
                    <div
                      v-if="errorLogin"
                      class="error-danger"
                    >
                      Ocorreu um erro, por favor tente submeter o questionário mais tarde!
                    </div>
                    <div
                      v-if="wrong"
                      class="error-danger"
                    >
                      Email ou password errados!
                    </div>
                  </b-col>
                </b-row>
                <div class="title">
                  Iniciar Sessão
                </div>
                <b-form
                  style="text-align: left;"
                  @submit="onSubmitSignIn"
                >
                  <b-form-group
                    id="input-group-1"
                    label="Email *"
                    label-for="input-1-si"
                  >
                    <b-form-input
                      id="input-1-si"
                      v-model="login.email"
                      type="email"
                      required
                      placeholder="Email"
                    />
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Password *"
                    label-for="input-2-si"
                  >
                    <b-form-input
                      id="input-2-si"
                      v-model="login.password"
                      type="password"
                      required
                      placeholder="Password"
                    />
                  </b-form-group>
                  <div
                    class="text-right text-primary"
                  >
                    <a
                      v-b-modal.showSendEmail
                    >
                      Esqueceu-se da password?
                    </a>
                  </div>
                  <div>* Campo de preenchimento obrigatório</div>
                  <div class="buttons justify-content-start">
                    <button
                      type="submit"
                      variant="primary"
                      class="button primary"
                    >
                      Continuar
                    </button>
                  </div>
                </b-form>
              </div>
            </b-modal>
          </div>
        </div>
      </b-container>
    </div>
  </b-overlay>
</template>

<script>
import { post } from '../../../services/api/index';

export default {
  name: 'Auth',
  data() {
    return {
      vatIDValid: null,
      loading: false,
      wrong: false,
      errorLogin: false,
      error: false,
      companyError: false,
      errorEmail: false,
      hiddenEmail: null,
      emailSendError: false,
      userNotExist: false,
      emailSended: false,
      emailSendedRegister: false,
      login: {
        email: '',
        password: '',
      },
      sendEmail: {
        email: '',
      },
      form: {
        email: '',
        name: '',
        role: '',
        company: '',
        nif: '',
        enable: true,
        password: '',
        cpassword: '',
        status: '',
        selected: false,
      },
      options: [
        { value: '1', text: 'Gestão de topo / Dirigente superior' },
        { value: '2', text: 'Gestão intermédia / Dirigente intermédio' },
        { value: '3', text: 'Técnico(a) / Técnico(a) superior' },
        { value: '4', text: 'Outro' },
      ],
    };
  },
  computed: {
    strong() {
      if (!this.passwordValidLength) {
        return 0;
      }
      const passwordValidNumber = this.passwordValidNumber ? 1 : 0;
      const passwordValidCharacter = this.passwordValidCharacter ? 1 : 0;
      const passwordValidUpperCase = this.passwordValidUpperCase ? 1 : 0;

      return passwordValidNumber + passwordValidCharacter + passwordValidUpperCase;
    },
    passwordValid() {
      if (this.form.password === '') {
        return null;
      }

      const passwordValidNumber = this.passwordValidNumber ? 1 : 0;
      const passwordValidCharacter = this.passwordValidCharacter ? 1 : 0;
      const passwordValidUpperCase = this.passwordValidUpperCase ? 1 : 0;

      if (!this.passwordValidLength) {
        return false;
      }

      if (passwordValidNumber + passwordValidCharacter + passwordValidUpperCase >= 2) {
        return true;
      }
      return false;
    },
    passwordValidLength() {
      if (this.form.password === '') {
        return null;
      }
      return this.form.password.length >= 8;
    },
    passwordValidNumber() {
      if (this.form.password === '') {
        return null;
      }
      return /\d/.test(this.form.password);
    },
    passwordValidCharacter() {
      if (this.form.password === '') {
        return null;
      }
      return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.form.password);
    },
    passwordValidUpperCase() {
      if (this.form.password === '') {
        return null;
      }
      return /[A-Z]/.test(this.form.password) && /[a-z]/.test(this.form.password);
    },
    equalPasswords() {
      if (this.form.password === '' || this.form.cpassword === '') {
        return null;
      }
      return this.form.cpassword === this.form.password;
    },
  },
  created() {
    if (this.$store.getters.state.loggedIn) {
      this.$router.push({ name: 'dashboard' });
    }
  },
  methods: {
    maxLength(value) {
      return value.slice(0, 9);
    },
    validateVATID(value) {
      const nif = typeof value === 'string' ? value : value ? value.toString() : '';
      const validationSets = {
        one: ['1', '2', '3', '5', '6', '8'],
        two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99'],
      };

      const total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
      const modulo11 = (Number(total) % 11);

      const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;

      this.vatIDValid = checkDigit === Number(nif[8]) && nif.length === 9 && validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2));
    },
    async onSubmitSendEmail(evt) {
      evt.preventDefault();
      this.loading = true;

      const fields = JSON.parse(JSON.stringify(this.sendEmail));
      this.emailSendError = false;
      this.userNotExist = false;
      this.emailSended = false;

      await post('forgot-password', {
        email: fields.email,
      }).then((response) => {
        this.emailSended = true;
        this.$store.commit('setResetEmail', {
          resetEmail: fields.email,
        });
      }).catch((error) => {
        if (error.data.message === 'Error: User not found in the database. Please contact the support team.') {
          this.userNotExist = true;
        } else {
          this.emailSendError = true;
        }
      });
      this.loading = false;
    },
    async onSubmitSignIn(evt) {
      evt.preventDefault();
      this.loading = true;

      const fields = JSON.parse(JSON.stringify(this.login));
      this.wrong = false;
      this.errorLogin = false;
      await post('auth/login', {
        email: fields.email,
        password: fields.password,
      }).then((response) => {
        this.$store.commit('setLoggedIn', {
          token: response.data.access_token,
          user: response.data.user,
          configs: response.data.configs,
        });
        this.$router.push({ name: 'dashboard' });
      }).catch((error) => {
        if (error.status === 401) {
          this.wrong = true;
        } else {
          this.errorLogin = true;
        }
      });
      this.loading = false;
    },
    async onSubmitSignUp(evt) {
      evt.preventDefault();
      this.loading = true;

      const fields = JSON.parse(JSON.stringify(this.form));
      this.error = false;
      this.errorEmail = false;
      this.companyError = false;
      this.wrong = false;
      this.errorLogin = false;
      this.emailSended = false;
      this.hiddenEmail = null;
      this.emailSendedRegister = false;
      await post('auth/register', {
        name: fields.name,
        email: fields.email,
        password: fields.password,
        password_confirmation: fields.password,
        role: fields.role,
        enable: false,
        company: fields.company,
        vatID: fields.nif.toString(),
        contact: fields.selected,
      }).then(async (response) => {
        this.$store.commit('setResetEmail', {
          resetEmail: fields.email,
        });
        this.emailSendedRegister = true;
        /* await post('auth/login', {
            email: fields.email,
            password: fields.password,
          }).then((response) => {
            this.$store.commit('setLoggedIn', {
              token: response.data.access_token,
              user: response.data.user,
            });
            this.$router.push({ name: 'diagnostic' });
          }).catch((error) => {
            if (error.status === 401) {
              this.wrong = true;
            } else {
              this.errorLogin = true;
            }
          }); */
      }).catch((error) => {
        if (error.data?.email && error.data?.email[0] === 'The email has already been taken.') {
          this.errorEmail = true;
        } else if (error.data?.company) {
          this.companyError = true;
          this.$store.commit('setResetEmail', {
            resetEmail: fields.email,
          });
          this.hiddenEmail = error.data?.email;
        } else {
          this.error = true;
        }
      });
      this.loading = false;
    },
  },
};
</script>
