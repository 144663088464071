<template>
  <ul style="padding-left: 15px; margin-bottom: 15px">
    <li
      v-for="(_subStep, i) in subSteps.filter(el => {
        return el.dimension_id === dimension.id && el.parent_id === parent
      })"
      :key="i"
      style="font-size: 15px"
    >
      <b-row>
        <b-col cols="11">
          {{ _subStep.text }}
        </b-col>
        <b-col cols="1">
          <div v-if="_subStep.childs && _subStep.childs.length > 0">
            <div
              :id="`button-collapse-${_subStep.id}`"
              v-b-toggle="`collapse-${_subStep.id}`"
              class="button outlined no-border xs d-flex align-items-center justify-content-center"
              style="margin-bottom: 1rem;"
              @click="() => {
                $emit('goToParent', _subStep.id)
              }"
            >
              <b-icon
                scale="1.5"
                icon="chevron-down"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="_subStep.childs && _subStep.childs.length > 0">
        <b-col cols="12">
          <b-collapse
            :id="`collapse-${_subStep.id}`"
          >
            <points
              :sub-steps="_subStep.childs"
              :groupped-sub-steps="grouppedSubSteps"
              :dimension="dimension"
              :parent="_subStep.id"
              @goToParent="(value) => $emit('goToParent', value)"
            />
          </b-collapse>
        </b-col>
      </b-row>
    </li>
  </ul>
</template>
<script>
import Points from './Points.vue';

export default {
  name: 'Points',
  components: { Points },
  props: {
    subSteps: {
      type: Array,
      required: true,
    },
    grouppedSubSteps: {
      type: Array,
      required: true,
    },
    dimension: {
      type: Object,
      required: true,
    },
    parent: {
      type: Number,
      required: false,
    },
  },
};
</script>
