<template>
  <div class="question-container">
    <div>
      <div
        class="title"
      >
        Conhece o conceito i4.0?
      </div>
    </div>
    <c-choice
      :choices="choices"
      :not-enabled="$store.state.quiz.disabled"
      :on-click="getChoice"
      :selected="selected"
    />
  </div>
</template>

<script>
import Choice from './Choice.vue';

export default {
  Name: 'Step5',
  components: {
    'c-choice': Choice,
  },
  props: {
    quiz: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selected: this.$store.getters.companyState.concept || null,
      choices: [
        { label: 'Não, nunca ouvi falar', value: 1 },
        { label: 'Vagamente e não é importante para a empresa', value: 2 },
        { label: 'Superficialmente e pretendo saber mais para avaliar o seu potencial na empresa', value: 3 },
        { label: 'O suficiente para já termos alguns projetos piloto a decorrer', value: 4 },
        { label: 'Dominamos o conceito e já o aplicamos de forma transversal na empresa', value: 5 },
      ],
    };
  },
  methods: {
    getChoice(value) {
      this.$store.commit('setConcept', { concept: value, update: true, quiz: this.quiz });
    },
  },
};
</script>
