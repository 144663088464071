<template>
  <b-container
    fluid
    class="roadmaps-container"
  >
    <b-row>
      <b-col
        cols="12"
        xl="3"
      >
        <b-row
          v-for="roadmap in roadmaps.filter(el => el.steps && el.steps.length > 0)"
          :key="roadmap.code"
        >
          <b-col
            cols="12"
            xl="12"
          >
            <div
              class="roadmap-tag"
              :class="[roadmap.order === selectedRoadmap.order && 'active']"
              @click="() => {
                selectRoadmap(roadmap)
              }"
            >
              <img
                :alt="roadmap.label"
                height="36"
                :src="require(`@/assets/roadmaps/${roadmap.code}.svg`)"
              >
              {{ roadmap.label }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="loaded && selectedRoadmap"
            cols="9"
            class="mt-4"
          >
            <div
              class="button outlined no-border secondary sm"
              style=" height: 32px; padding: 0; align-items: center; justify-content: space-between; display: flex; width: 100%;"
              :class="showResourcesContent ? 'resource-button-active' : 'resource-button-inactive'"
              @click="() => {
                if(showResourcesContent) {
                  hideResources()
                } else {
                  showResources()

                }
              }"
            >
              <div>
                <b-icon
                  icon="file-text"
                  class="mr-3"
                  scale="1.5"
                />
                Recursos
              </div>

              <b-icon
                v-if="showResourcesContent"
                icon="x-circle"
                class="ml-auto"
                scale="1.3"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="loaded && selectedRoadmap && dimension && !showResourcesContent"
        cols="12"
        xl="9"
        class="mt-4 mt-xl-0"
      >
        <b-row>
          <b-col
            cols="12"
            xl="12"
          >
            <div
              class="roadmap-header"
              style="height: auto"
            >
              <b-row style="height: 100%">
                <b-col
                  cols="12"
                  xl="8"
                  order="2"
                  order-xl="1"
                >
                  <RoadmapPath
                    :step="step"
                    :virtual-step="virtualStep"
                    :steps="steps"
                    :colors="selectedRoadmap.colors"
                    @handleClickStep="updateStep"
                  />
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  class="d-flex flex-column align-items-center mt-4 mt-xl-0"
                  style="justify-content: center"
                  order="1"
                  order-xl="2"
                >
                  <Dim1
                    v-if="dimension.code === '1'"
                    :color="selectedRoadmap.colors.find(el => el.level === 5 - virtualStep + 1).color"
                    @handleClickDimension="updateDimension"
                  />
                  <Dim2
                    v-if="dimension.code === '2'"
                    :color="selectedRoadmap.colors.find(el => el.level === 5 - virtualStep + 1).color"
                    @handleClickDimension="updateDimension"
                  />
                  <Dim3
                    v-if="dimension.code === '3'"
                    :color="selectedRoadmap.colors.find(el => el.level === 5 - virtualStep + 1).color"
                    @handleClickDimension="updateDimension"
                  />
                  <Dim4
                    v-if="dimension.code === '4'"
                    :color="selectedRoadmap.colors.find(el => el.level === 5 - virtualStep + 1).color"
                    @handleClickDimension="updateDimension"
                  />
                  <Dim5
                    v-if="dimension.code === '5'"
                    :color="selectedRoadmap.colors.find(el => el.level === 5 - virtualStep + 1).color"
                    @handleClickDimension="updateDimension"
                  />
                  <Dim6
                    v-if="dimension.code === '6'"
                    :color="selectedRoadmap.colors.find(el => el.level === 5 - virtualStep + 1).color"
                    @handleClickDimension="updateDimension"
                  />
                  <div style="text-align: center">
                    <div
                      class="fs-11 text-dark-grey"
                      style="font-weight: 600"
                    >
                      Dimensão
                    </div>
                    <div
                      class="fs-14 text-body-color"
                      style="font-weight: bold"
                    >
                      {{ dimension.name }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="grouppedSubSteps && grouppedSubSteps.length > 0">
          <b-col
            cols="12"
          >
            <b-row class="roadmap-step-header">
              <b-col
                cols="12"
                md="8"
              >
                <div class="roadmap-step-bar">
                  <!-- :style="getRoadMapProgressBackgroundColor" -->
                  <div
                    class="roadmap-step-progress"
                    :style="[
                      getRoadMapProgressBackgroundColor(),
                      {width: (subStep * 100 )/ (grouppedSubSteps.length)+'%'}]"
                  >
                    {{ subStep }}/{{ grouppedSubSteps.length }}
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-center mt-4 mt-md-0 justify-content-md-end"
              >
                <b-button
                  variant="secondary"
                  class="button-primary rubik-regular fs-15 d-flex align-items-center"
                  style="margin-right: 8px; border-radius: 20px; padding-left: 28px; padding-right: 28px; height: 100%"
                  @click="prevPoint"
                >
                  Anterior
                </b-button>
                <b-button
                  variant="primary"
                  class="button-primary rubik-regular fs-15 d-flex align-items-center"
                  style="margin: 0px; border-radius: 20px; padding-left: 28px; padding-right: 28px; height: 100%"
                  @click="nextPoint"
                >
                  Seguinte
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-if="step"
            cols="12"
          >
            <div
              v-if="steps[step - 1].title "
              class="fs-14 text-body"
              style="font-weight: 600; margin-bottom: 8px"
            >
              {{ steps[step - 1].title }}:
            </div>
            <points
              ref="points"
              :sub-steps="subSteps"
              :groupped-sub-steps="grouppedSubSteps"
              :dimension="dimension"
              :parent="parent"
              @goToParent="goToParent"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="loaded && selectedRoadmap && showResourcesContent"
        cols="12"
        xl="9"
      >
        <b-row>
          <b-col
            cols="12"
            xl="12"
          >
            <b-row>
              <b-col
                v-for="(resource, i) in selectedRoadmap.resources"
                :key="i"
                cols="12"
                xl="6"
                style="padding: 12px"
              >
                <b-overlay
                  :show="loading && resourceId === resource.resource.id"
                  rounded="sm"
                  style="height: 100%"
                >
                  <resource
                    :resource="resource.resource"
                    :set-loading="(value) => {
                      if(value) {
                        resourceId = resource.resource.id
                        loading = value
                      } else {
                        resourceId = null
                        loading = value
                      }
                    }"
                  />
                </b-overlay>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      id="qrCode"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Pontos"
      hide-footer
      size="xl"
      @hidden="() => {
        qrCode = null
      }"
    >
      <div class="d-flex align-items-center justify-content-center">
        <img
          style="height: 80vh"
          alt="qrCode"
          :src="`${getImageFrom('qrcode.png', qrCode)}`"
        >
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import mime from 'mime';
import { get, post } from '../../../services/api';
import Dim1 from './DimensionsSVG/Dim1.vue';
import Dim2 from './DimensionsSVG/Dim2.vue';
import Dim3 from './DimensionsSVG/Dim3.vue';
import Dim4 from './DimensionsSVG/Dim4.vue';
import Dim5 from './DimensionsSVG/Dim5.vue';
import Dim6 from './DimensionsSVG/Dim6.vue';
import RoadmapPath from './DimensionsSVG/RoadmapPath.vue';
import Points from './Points.vue';
import Resource from './Resource.vue';

export default {
  name: 'Roadmaps',
  components: {
    Dim1,
    Dim2,
    Dim3,
    Dim4,
    Dim5,
    Dim6,
    Points,
    RoadmapPath,
    Resource,
  },
  props: {
    setLoading: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      svg: null,
      roadmaps: [],
      dimension: null,
      parent: null,
      loaded: false,
      step: 0,
      steps: [],
      subStep: 0,
      subSteps: [],
      grouppedSubSteps: [],
      selectedRoadmap: null,
      openChilds: [],
      showResourcesContent: false,
      loading: false,
      resourceId: null,
      qrCode: null,
    };
  },
  computed: {
    virtualStep() {
      if (this.steps.length === 5) {
        return this.step;
      }
      if (this.steps.length === 3) {
        switch (this.step) {
          case 1:
            return 1;
          case 2:
            return 3;
          case 3:
            return 5;
          default:
            return 0;
        }
      }
      return 0;
    },
  },
  watch: {
    selectedRoadmap(value) {
      post('results/analytics', {
        type: 'roadmap',
        result: 'roadmaps',
        event: 'view',
        roadmap: value.code,
        ip: null,
        user: this.$store.state.auth.loggedIn ? this.$store.state.auth.user.id : null,
      });
    },
    dimension(value) {
      const step = this.steps[this.step - 1];

      if (step) {
        post('results/analytics', {
          type: 'roadmap_step',
          result: 'roadmaps',
          event: 'view',
          roadmap: this.selectedRoadmap.code,
          dimension: value.code,
          step: step.code,
          ip: null,
          user: this.$store.state.auth.loggedIn ? this.$store.state.auth.user.id : null,
        });
      }
    },
  },
  async created() {
    this.setLoading(true);
    await get('results/roadmaps').then((response) => {
      this.roadmaps = response.data;
      this.loaded = true;
      this.setLoading(false);
      if (response.data && response.data.length > 0) {
        this.selectRoadmap(response.data.filter((el) => el.steps && el.steps.length > 0)[0]);
      }
    });
  },
  methods: {
    showQrCode(resource) {
      this.qrCode = resource.qr_code;

      this.$bvModal.show('qrCode');
    },
    async download(id) {
      this.loading = true;
      this.resourceId = id;
      await get(`results/roadmaps/resources/${id}`).then((response) => {
        const linkSource = `data:${mime.getType(response.data.document_path)};base64,${response.data.document}`;
        const downloadLink = document.createElement('a');
        const fileName = response.data.document_path;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
      this.resourceId = null;
      this.loading = false;
    },
    getDocument(resource) {
      if (resource.document) {
        this.download(resource.id);
      } else if (resource.link) {
        window.open(resource.link, '_blank');
      }
    },
    getImageFrom(name, base64) {
      return `data:${mime.getType(name)};base64,${base64}`;
    },
    showResources() {
      this.showResourcesContent = true;
    },
    hideResources() {
      this.showResourcesContent = false;
    },
    goToParent(parent) {
      const point = this.steps[this.step - 1].points.find((el) => el.id === parent);
      if (point) {
        this.dimension = point.dimension;
        this.subStep = this.grouppedSubSteps.findIndex((el) => el.parent_id === point.id) + 1;
        let reversedChilds = [...this.openChilds].reverse();
        if (reversedChilds.includes(point.id)) {
          let index = -1;
          reversedChilds = reversedChilds.filter((el, i) => {
            if (el === point.id) {
              index = i;
              return false;
            }

            if (el !== point.id && index === -1) {
              this.$root.$emit('bv::toggle::collapse', `collapse-${el}`);
              return false;
            }

            return true;
          });
          this.openChilds = reversedChilds.reverse();
          this.subStep -= 1;
        } else {
          this.openChilds.push(point.id);
        }
      }
    },
    updateStep(step) {
      this.step = step;
      this.subSteps = [];
      this.grouppedSubSteps = [];
      this.openChilds = [];
      if (this.steps[this.step - 1].points && this.steps[this.step - 1].points.length > 0) {
        this.subStep = 1;
        this.dimension = this.steps[this.step - 1].points[0].dimension;
        this.parent = this.steps[this.step - 1].points[0].parent_id;
        this.getSubSteps(this.steps[this.step - 1].points);
      }
    },
    updateDimension(dimension) {
      const point = this.steps[this.step - 1].points.find((el) => el.dimension.code.toString() === dimension.toString());

      if (point) {
        this.dimension = point.dimension;
        this.subStep = this.grouppedSubSteps.findIndex((el) => el.dimension.code.toString() === dimension.toString()) + 1;

        this.openChilds = [];
      }
    },
    getRoadMapProgressBackgroundColor() {
      return {
        background: `transparent linear-gradient(121deg, ${this.selectedRoadmap.colors[4].color} 0%, ${this.selectedRoadmap.colors[3].color} 25%, ${this.selectedRoadmap.colors[2].color} 50%, ${this.selectedRoadmap.colors[1].color} 77%, ${this.selectedRoadmap.colors[0].color} 100%) 0% 0% no-repeat padding-box`,
      };
    },
    selectRoadmap(roadmap) {
      this.selectedRoadmap = roadmap;
      this.step = 0;
      this.steps = [];
      this.subStep = 0;
      this.subSteps = [];
      this.grouppedSubSteps = [];
      this.openChilds = [];

      this.dimension = null;
      this.parent = null;
      if (roadmap.steps && roadmap.steps.length > 0) {
        this.step = roadmap.steps.findIndex((el) => el.points && el.points.length > 0) + 1;
        this.steps = roadmap.steps;
        this.normalize(this.steps);

        if (this.step > 0 && this.steps[this.step - 1] && this.steps[this.step - 1].points && this.steps[this.step - 1].points.length > 0) {
          this.subStep = 1;
          this.dimension = this.steps[this.step - 1].points[0].dimension;
          this.parent = this.steps[this.step - 1].points[0].parent_id;
          this.getSubSteps(this.steps[this.step - 1].points);
        }
      } else {
        this.step = 0;
      }
    },
    normalize(steps) {
      steps.forEach((step) => {
        const points = this.getPoints(step.points);
        step.points = points;
      });
    },
    getPoints(points) {
      const tempPoints = [];
      points.sort((a, b) => a.dimension_id - b.dimension_id).forEach((point) => {
        if (!tempPoints.find((el) => el.id === point.id)) {
          tempPoints.push(point);
        }
        if (point.childs && point.childs.length > 0) {
          this.getPoints(point.childs).forEach((childPoint) => {
            if (!tempPoints.find((el) => el.id === childPoint.id)) {
              tempPoints.push(childPoint);
            }
          });
        }
      });

      return tempPoints;
    },
    getSubSteps(points) {
      points.forEach((point) => {
        if (this.subSteps.filter((el) => el.dimension_id === point.dimension_id
        && el.parent_id === point.parent_id).length === 0
        ) {
          this.grouppedSubSteps.push(point);
        }
        this.subSteps.push(point);
        /*  if (point.childs && point.childs.length > 0) {
          this.getSubSteps(point.childs);
        } */
      });
    },
    nextPoint() {
      if (this.subStep <= this.grouppedSubSteps.length - 1) {
        this.subStep += 1;
        const prevDimension = this.dimension;

        this.dimension = this.grouppedSubSteps[this.subStep - 1].dimension;

        if (this.dimension && this.dimension.id !== prevDimension.id) {
          this.openChilds.forEach((point) => {
            this.$root.$emit('bv::toggle::collapse', `collapse-${point}`);
          });
          this.openChilds = [];
        }

        const prevParent = this.parent;
        this.parent = this.grouppedSubSteps[this.subStep - 1].parent_id;

        if (this.parent != null) {
          const point = this.steps[this.step - 1].points.find((el) => el.id === this.parent);
          if (point) {
            this.subStep = this.grouppedSubSteps.findIndex((el) => el.parent_id === point.id) + 1;
            this.parent = prevParent;
            document.getElementById(`button-collapse-${point.id}`).click();
          }
        }
      } else if (this.step <= this.steps.filter((el) => el.points && el.points.length > 0).length - 1) {
        this.step += 1;
        this.dimension = this.steps[this.step - 1].points[0].dimension;
        this.parent = this.steps[this.step - 1].points[0].parent_id;

        this.subStep = 1;
        this.subSteps = [];
        this.grouppedSubSteps = [];
        this.openChilds = [];
        this.getSubSteps(this.steps[this.step - 1].points);
      }
    },
    prevPoint() {
      if (this.subStep - 1 > 0) {
        this.subStep -= 1;
        const prevSubStep = this.subStep;
        this.dimension = this.grouppedSubSteps[this.subStep - 1].dimension;
        if (this.openChilds && this.openChilds.length > 0) {
          document.getElementById(`button-collapse-${[...this.openChilds].pop()}`).click();
          this.subStep = prevSubStep;
        } else {
          this.parent = this.grouppedSubSteps[this.subStep - 1].parent_id;
          if (this.parent != null) {
            this.prevPoint();
          }
        }
      } else if (this.step - 1 > this.steps.findIndex((el) => el.points && el.points.length > 0)) {
        this.step -= 1;

        this.subStep = 1;
        this.subSteps = [];
        this.grouppedSubSteps = [];
        this.openChilds = [];
        this.getSubSteps(this.steps[this.step - 1].points);

        this.subStep = this.grouppedSubSteps.length;
        this.dimension = this.grouppedSubSteps[this.subStep - 1].dimension;
        this.parent = this.grouppedSubSteps[this.subStep - 1].parent_id;
      }
    },
  },
};
</script>
