<template>
  <b-row :class="customClass">
    <b-col
      v-for="resource in resources"
      :key="resource.id"
      :md="12"
      :lg="fill ? 12 : resources.length % 2 === 0 && resources.length < 6 ? 6 : 4"
      cols="12"
      style="margin-bottom: 30px"
    >
      <resource
        :resource="resource"
        :set-loading="setLoading"
        :fill="fill"
        :show-sectors="showSectors"
      />
    </b-col>
  </b-row>
</template>
<script>
import Resource from './Resource.vue';

export default {
  name: 'Resources',
  components: { Resource },
  props: {
    resources: {
      type: Array,
      required: true,
    },
    setLoading: {
      type: Function,
      default: (value) => {
        this.loading = value;
      },
    },
    customClass: {
      type: String,
      default: '',
      required: false,
    },
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSectors: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
