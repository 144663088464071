var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.thumbnail ? 'padding: 0; background-color: transparent; height: 213px; width: 100%' : 'align-items: center; height: 100%')},[_c('div',{staticClass:"video-play",style:([
      _vm.thumbnail ?
        (_vm.videoShowing
          ? { height: 0, padding: 0 }
          : {height: '100%', padding: 0, 'background-color': 'transparent'}
        )
        : _vm.videoShowing
          ? { height: 0, padding: 0 }
          : {}
    ]),on:{"click":_vm.playVideo}},[(!_vm.videoShowing)?_c('img',{style:(_vm.thumbnail ? 'border-radius: 8px;' : ''),attrs:{"width":_vm.thumbnail ? '100%' : '90%',"height":_vm.thumbnail ? '100%' : 'auto',"alt":_vm.resource.label,"src":_vm.api+'storage/'+_vm.resource.thumbnail}}):_vm._e(),(!_vm.videoShowing)?_c('div',{staticClass:"play-button",style:(!_vm.thumbnail ? 'margin-bottom: 66px' : '')},[_c('b-icon',{attrs:{"icon":"play-fill","scale":"2"}})],1):_vm._e(),(!_vm.thumbnail)?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.resource.label)+" ")]):_vm._e()]),(_vm.videoShowing)?_c('div',{class:!_vm.thumbnail ? 'video-play' : '',style:(_vm.videoShowing ? !_vm.thumbnail ? '' : 'height: 213px; width: 100%' : 'height: 0; opacity: 0; width: 100%')},[_c('div',{style:(_vm.thumbnail ? 'width: 100%; height: 100%' : 'width: 90%; height: 86%')},[_c('youtube',{ref:("youtube-" + (_vm.resource.id)),staticClass:"resource-video",attrs:{"video-id":_vm.resource.link,"fit-parent":true,"resize":true,"player-vars":{
          autoplay: 1
        },"resize-delay":0}})],1),(!_vm.thumbnail)?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.resource.label)+" ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }