<template>
  <div class="question-container">
    <div>
      <div
        class="title"
      >
        {{ question }}
      </div>
      <div
        class="description"
      >
        {{ description }}
      </div>
    </div>
    <b-form-group
      class="question-responses checkboxes"
    >
      <b-form-checkbox-group
        id="checkbox-group-2"
        v-model="mutableSelectValues"
        name="flavour-2"
        class="checkboxes"
      >
        <div
          v-for="(choice, index) in choices"
          :key="question+''+index"
          :class="mutableSelectValues && mutableSelectValues.includes(choice.value) ? 'choice selected' : 'choice'"
        >
          <b-form-checkbox
            v-if="mutableSelectValues && mutableSelectValues.includes(choice.value)"
            class="checkbox-tooltip"
            :value="choice.value"
            :disabled="notEnabled"
          >
            {{ choice.label }}
            <b-form-group
              v-if="choice.free && mutableSelectValues.includes(choice.value)"
              class="question-responses checkboxes"
            >
              <div
                class="choice selected"
              >
                <b-form-input
                  id="checkbox-group-2"
                  :value="mutableNotes.find(el => el.value === choice.value).notes"
                  name="flavour-2"
                  :disabled="notEnabled"
                  class="checkboxes"
                  @change="value => {
                    updateResponse(value, index)
                  }"
                />
              </div>
            </b-form-group>
          </b-form-checkbox>
          <b-form-checkbox
            v-else
            :value="choice.value"
            class="checkbox-tooltip"
            :disabled="notEnabled"
          >
            {{ choice.label }}
          </b-form-checkbox>
          <div
            v-if="choice.tooltip && choice.tooltip.length > 0"
            class="buttons m-0 p-0"
          >
            <div
              :id="`tooltip-target-${index}`"
              :class="mutableSelectValues && mutableSelectValues.includes(choice.value) ? 'primary' : ''"
              class="button sm outlined no-border no-bg m-0 p-0"
              @click="() => {
                openLink(choice.tooltip_link)
              }"
            >
              <b-icon icon="info-circle" />
            </div>
          </div>
          <b-tooltip
            v-if="choice.tooltip && choice.tooltip.length > 0"
            :target="`tooltip-target-${index}`"
            triggers="hover"
            variant="secondary"
          >
            <p
              v-for="(desc, k) in choice.tooltip.split('\n')"
              :key="k"
              class="m-2"
            >
              <span
                class="tooltip-color-light"
                v-html="desc"
              />
            </p>
          </b-tooltip>
        </div>
      </b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'Multichoice',
  props: {
    question: String,
    description: String,
    choices: Array,
    onClick: Function,
    selected: Array,
    notEnabled: Boolean,
    responseGetted: Boolean,
  },
  data() {
    return {
      preventPOST: false,
      mutableSelectValues: this.selected.map((el) => el.value),
      mutableNotes: this.selected,
    };
  },

  watch: {
    mutableSelectValues(newValue) {
      if (!this.preventPOST) {
        const payload = [];
        newValue.forEach((element, index) => {
          const elementNote = this.mutableNotes.find((el) => el.value === element);
          payload.push({ value: element, notes: elementNote ? elementNote.notes : null });
        });
        this.onClick(payload);
        this.mutableNotes = payload;
      }
      this.preventPOST = false;
    },
    selected(newValue) {
      this.preventPOST = true;
      this.mutableSelectValues = newValue.map((el) => el.value);
      this.mutableNotes = newValue;
    },
  },

  methods: {
    updateResponse(value, index) {
      this.mutableNotes.find((el) => el.value === this.choices[index].value).notes = value;
      this.onClick(this.mutableNotes);
    },
    openLink(link) {
      if (link) {
        window.open(link, '_blank');
      }
    },
  },
};
</script>

<style lang="scss">
  .checkbox-tooltip.custom-control.custom-control-inline.custom-checkbox .custom-control-label {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:  space-between;
  }
  .tooltip-color-light a {
    text-decoration: none;
    color: #ffffff !important;
  }
</style>
