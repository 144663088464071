<template>
  <div class="question-container">
    <div>
      <div
        class="title"
      >
        O que motiva a sua empresa a abraçar os desafios da digitalização e i4.0?
      </div>
    </div>
    <c-multichoice
      :choices="choices"
      :not-enabled="$store.state.quiz.disabled"
      :on-click="getChoice"
      :selected="selected"
    />
  </div>
</template>

<script>
import Multichoice from './Multichoice.vue';

export default {
  Name: 'Step6',
  components: {
    'c-multichoice': Multichoice,
  },
  props: {
    quiz: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selected: this.$store.getters.companyState.causes || [],
      choices: [
        { label: 'Oportunidade para se diferenciar no mercado', value: 1 },
        { label: 'A empresa tem espírito inovador, pelo que tem de experimentar novos conceitos', value: 2 },
        { label: 'Faz parte da sua condição de líder de mercado', value: 3 },
        { label: 'Os requisitos de mercado e a pressão competitiva', value: 4 },
      ],
    };
  },
  methods: {
    getChoice(value) {
      this.$store.commit('setCauses', { causes: value, update: true, quiz: this.quiz });
    },
  },
};
</script>
