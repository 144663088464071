<template>
  <b-container
    class="project-container"
    fluid
  >
    <b-row class="shift-to-i40-banner">
      <b-container>
        <b-row align-v="center">
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            class="description"
          >
            <div class="title">
              SHIFTo4.0
            </div>
            <p>
              Com o objetivo de avaliar e testar o modelo de diagnóstico da maturidade digital foi desenvolvido um projeto piloto no qual participaram 7 entidades: ISQ, IAPMEI, Universidade de AVEIRO - UA, CTCV (Centro Tecnológico da Cerâmica e Vidro), MOBINOV - CEiiA (cluster automóvel), IPL (Instituto Politécnico de Leiria) e Universidade do Minho – UM, ), que disponibilizaram consultores e contactaram e diagnosticaram 64 empresas das quais 49 PME e 15 Grandes empresas.
            </p>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            class="device-youtube"
          >
            <div class="video-wrapper">
              <youtube
                ref="youtube"
                :video-id="videoId"
                :fit-parent="fitParent"
                :resize="resize"
                :resize-delay="resizeDelay"
                @playing="playing"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <b-row class="shift-to-i40-results">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="shift-to-i40-results-title">
              Resultados
            </div>
          </b-col>
          <b-col
            cols="12"
            offset-lg="1"
            lg="9"
          >
            <div class="shift-to-i40-results-description">
              De um universo de 64 empresas que voluntariamente participaram no exercício SHIFTo4.0, observou-se um nível médio de maturidade de 1.47. Regista-se ainda que os níveis de maturidade variaram entre o valor mínimo zero e o valor máximo de três. Estes resultados apontam um baixo nível de maturidade i4.0 por parte das empresas que participaram no estudo.
            </div>
          </b-col>
          <b-col
            cols="12"
            class="shift-to-i40-results-cards"
          >
            <img src="@/assets/Elementos/2 O Projeto/regiao.svg">
            <img src="@/assets/Elementos/2 O Projeto/nivel de maturidade medio.svg">
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <b-row class="shift-to-i40-more">
      <b-container>
        <b-row align-v="center">
          <b-col
            cols="12"
            md="3"
          >
            <div class="shift-to-i40-more-title">
              Resultados
            </div>
            <div class="shift-to-i40-more-description">
              Aceda ao relatório do SHIFTo4.0.
            </div>
            <div class="buttons">
              <a
                class="button primary"
                style="text-decoration: none;"
                href="/Relatório.pdf"
                target="_blanck"
              >
                VER RELATÓRIO <b-icon icon="chevron-right" />
              </a>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <img
              src="@/assets/Relatorio SHIFT to 4.0.jpg"
              width="100%"
            >
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Project',
  data() {
    return {
      display: 1,
      videoId: '4AjpRN2VpTE',
      resize: true,
      resizeDelay: 0,
      fitParent: true,
    };
  },

  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    setDisplay(value) {
      this.display = value;
    },
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log('\o/ we are watching!!!');
    },
  },
};
</script>
