<template>
  <div class="question-container step10">
    <div>
      <div
        class="title"
      >
        A sua empresa é membro de que associações empresariais, industriais ou clusters?
      </div>
    </div>
    <div class="clusters-container">
      <div class="buttons">
        <div
          :class="state == 1 ? 'button primary' : 'button secondary'"
          @click="setState(1)"
        >
          Sim
        </div>
        <div
          :class="state == 0 ? 'button primary' : 'button secondary'"
          @click="setState(0)"
        >
          Não
        </div>
      </div>
      <div
        v-if="state == 1"
        class="input-clusters"
      >
        <el-input
          v-model="selected"
          :disabled="$store.state.quiz.disabled"
          placeholder="Clusters"
        >
          <i
            slot="suffix"
            class="el-icon-edit el-input__icon"
          />
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  Name: 'Step10',
  props: {
    quiz: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      state: -1,
      selected: this.$store.getters.companyState.clusters || null,
      timeout: null,
    };
  },
  watch: {
    async selected(newValue) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = await setTimeout(async () => {
        this.getChoice(newValue);
      }, 500);
    },
  },
  methods: {
    setState(value) {
      if (!this.$store.state.quiz.disabled) {
        this.state = value;
      }
    },
    getChoice(value) {
      this.$store.commit('setClusters', { clusters: value, update: true, quiz: this.quiz });
    },
  },
};
</script>
