<template>
  <div
    class="document"
    :class="[thumbnail ? 'document-thumbnail' : '', fill ? 'document-fill' : '']"
    :style="thumbnail ? 'padding: 0; background-color: transparent' : ''"
  >
    <div
      v-if="showSectors"
      class="sectors-tag"
    >
      <div
        v-if="resource.industries.length > 0"
        class="tag"
      >
        {{ resource.industries[0].label }}
      </div>
      <div
        v-if="resource.industries.length > 1"
        class="tag"
      >
        {{ resource.industries[1].label }}
      </div>
      <div
        v-if="resource.industries.length > 2"
        :id="`tooltip-${resource.id}`"
        class="tag"
      >
        + {{ resource.industries.length - 2 }}
      </div>
      <b-popover
        :target="`tooltip-${resource.id}`"
        triggers="hover"
        placement="top"
      >
        {{ resource.industries.slice(2).map(el => el.label).join(", ") }}
      </b-popover>
      <!-- <div
        v-for="industry in resource.industries"
        :key="industry.id"
        class="tag"
      >
        {{ industry.label }}
      </div> -->
      <div
        v-if="resource.industries.length === 0"
        class="unvisible-tag"
      />
    </div>
    <div
      class="document-thumbnail"
      @click="openExternalLink"
    >
      <img
        :width="fill ? '80%' : (thumbnail ? '100%' : '90%')"
        :height="fill ? '80%' : (thumbnail ? '100%' : 'auto')"
        :style="thumbnail ? 'border-radius: 8px;' : ''"
        :alt="resource.label"
        :src="api+'storage/'+resource.thumbnail"
      >
      <div
        class="reader-button"
      >
        <b-icon
          icon="book"
          scale="2"
        />
      </div>
    </div>
    <div
      v-if="!thumbnail"
      class="label"
    >
      {{ resource.label }}
    </div>
    <div
      v-if="!thumbnail"
      class="download"
      @click="() => {
        getDocument(resource)
      }"
    >
      <b-icon
        icon="download"
        class="mr-2"
      />Download do PDF
    </div>
  </div>
</template>
<script>
import mime from 'mime';
import { get, post } from '../../../services/api';

export default {
  name: 'Document',
  props: {
    resource: {
      type: Object,
      required: true,
    },
    setLoading: {
      type: Function,
      default: (value) => {
        this.loading = value;
      },
    },
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
    thumbnail: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSectors: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      api: process.env.VUE_APP_BACKEND_URL.split('api/')[0],
    };
  },
  methods: {
    async download(id) {
      this.setLoading(true);
      await get(`results/resources/${id}`).then((response) => {
        const linkSource = `data:${mime.getType(response.data.document_path)};base64,${response.data.document}`;
        const downloadLink = document.createElement('a');
        const fileName = response.data.document_path;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        post('results/analytics', {
          type: 'resource',
          result: this.resource.resource_type.code,
          event: 'download',
          resource: id,
          ip: null,
          user: this.$store.state.auth.loggedIn ? this.$store.state.auth.user.id : null,
        });
      });
      this.setLoading(false);
    },
    getDocument(resource) {
      if (resource.document) {
        this.download(resource.id);
      } else if (resource.link) {
        window.open(resource.link, '_blank');
      }
    },
    getImageFrom(name, base64) {
      return `data:${mime.getType(name)};base64,${base64}`;
    },
    openExternalLink() {
      if (this.resource.external_link) {
        window.open(this.resource.external_link, '_blank');
      }
    },
  },
};
</script>
