<template>
  <div
    :style="thumbnail ? 'padding: 0; background-color: transparent; height: 213px; width: 100%' : 'align-items: center; height: 100%'"
  >
    <div
      class="video-play"
      :style="[
        thumbnail ?
          (videoShowing
            ? { height: 0, padding: 0 }
            : {height: '100%', padding: 0, 'background-color': 'transparent'}
          )
          : videoShowing
            ? { height: 0, padding: 0 }
            : {}
      ]"
      @click="playVideo"
    >
      <img
        v-if="!videoShowing"
        :width="thumbnail ? '100%' : '90%'"
        :height="thumbnail ? '100%' : 'auto'"
        :style="thumbnail ? 'border-radius: 8px;' : ''"
        :alt="resource.label"
        :src="api+'storage/'+resource.thumbnail"
      >
      <div
        v-if="!videoShowing"
        class="play-button"
        :style="!thumbnail ? 'margin-bottom: 66px' : ''"
      >
        <b-icon
          icon="play-fill"
          scale="2"
        />
      </div>
      <div
        v-if="!thumbnail"
        class="label"
      >
        {{ resource.label }}
      </div>
    </div>
    <div
      v-if="videoShowing"
      :style="videoShowing ? !thumbnail ? '' : 'height: 213px; width: 100%' : 'height: 0; opacity: 0; width: 100%'"
      :class="!thumbnail ? 'video-play' : ''"
    >
      <div
        :style="thumbnail ? 'width: 100%; height: 100%' : 'width: 90%; height: 86%'"
      >
        <youtube
          :ref="`youtube-${resource.id}`"
          class="resource-video"
          :video-id="resource.link"
          :fit-parent="true"
          :resize="true"
          :player-vars="{
            autoplay: 1
          }"
          :resize-delay="0"
        />
      </div>
      <div
        v-if="!thumbnail"
        class="label"
      >
        {{ resource.label }}
      </div>
    </div>
  </div>
</template>
<script>
import mime from 'mime';
import { post } from '../../../services/api';

export default {
  name: 'Video',
  props: {
    resource: {
      type: Object,
      required: true,
    },
    thumbnail: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      videoShowing: false,
      api: process.env.VUE_APP_BACKEND_URL.split('api/')[0],
    };
  },
  computed: {
    player() {
      return this.$refs[`youtube-${this.resource.id}`].player;
    },
  },

  methods: {
    getImageFrom(name, base64) {
      return `data:${mime.getType(name)};base64,${base64}`;
    },
    playVideo() {
      this.videoShowing = true;
      post('results/analytics', {
        type: 'resource',
        result: this.resource.resource_type.code,
        event: 'watch',
        resource: this.resource.id,
        ip: null,
        user: this.$store.state.auth.loggedIn ? this.$store.state.auth.user.id : null,
      });
      this.player.playVideo();
    },

  },
};
</script>
