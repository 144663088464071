<template>
  <div class="question-container step3">
    <div
      class="title"
    >
      Selecione {{ isPortugal ? 'a região' : 'o país' }} em que se instala a sua empresa
      <div
        v-if="isPortugal"
        class="buttons"
      >
        <div
          v-if="!$store.state.quiz.disabled"
          class="button primary"
          @click="chooseAnotherCountry"
        >
          Escolher outro país
        </div>
      </div>
    </div>

    <div
      v-if="isPortugal"
      class="map-info"
      style="width: 60vw"
    >
      <div class="step-content">
        <div class="map">
          <c-map :quiz="quiz" />
        </div>
        <div class="label">
          {{ region ? regions[region] : 'Nenhuma região selecionada' }}
        </div>
      </div>
    </div>
    <c-2
      v-else
      :quiz="quiz"
    />
  </div>
</template>

<script>
import Mapa from './Map/index.vue';
import Step2 from './Step2.vue';

export default {
  Name: 'Step3',
  components: {
    'c-map': Mapa,
    'c-2': Step2,
  },
  props: {
    quiz: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      region: this.$store.getters.region,
      isPortugal: this.$store.getters.companyState.country ? this.$store.getters.companyState.country == 'Portugal' : true,
      regions: {
        'R-1': 'Alto Minho',
        'R-2': 'Cávado',
        'R-3': 'Ave',
        'R-4': 'Área Metropolitana do Porto',
        'R-5': 'Alto Tâmega',
        'R-6': 'Tâmega e Sousa',
        'R-7': 'Douro',
        'R-8': 'Terras de Trás-os-Montes',
        'R-9': 'Oeste',
        'R-10': 'Região de Aveiro',
        'R-11': 'Região de Coimbra',
        'R-12': 'Região de Leiria',
        'R-13': 'Viseu Dão Lafões',
        'R-14': 'Beira Baixa',
        'R-15': 'Médio Tejo',
        'R-16': 'Beiras e Serra da Estrela',
        'R-17': 'Área Metroplotinada de Lisboa',
        'R-18': 'Alentejo Litoral',
        'R-19': 'Baixo Alentejo',
        'R-20': 'Lezíria do Tejo',
        'R-21': 'Alto Alentejo',
        'R-22': 'Alentejo Central',
        'R-23': 'Algarve',
        'R-24': 'Região Autónoma dos Açores',
        'R-25': 'Região Autónoma da Madeira',
      },
    };
  },
  watch: {
    '$store.state.company.region': function () {
      this.region = this.$store.state.company.region;
    },
    '$store.state.company.country': function (value) {
      this.isPortugal = value == 'Portugal';
    },
  },

  methods: {
    chooseAnotherCountry() {
      this.$store.commit('setCountry', { country: false, update: false, quiz: this.quiz });
      this.isPortugal = false;
    },
  },
};
</script>
