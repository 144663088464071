<template>
  <b-container
    fluid
    class="resources-container"
  >
    <b-row class="subheader">
      <b-col>
        Vídeos <span class="count">(Total: {{ resources.length }})</span>
      </b-col>
    </b-row>
    <div
      v-for="resourceType in types"
      :key="resourceType.code"
    >
      <b-row class="sub-subheader">
        <b-col v-if="resources.find(el => el.type === resourceType) && resources.find(el => el.type === resourceType).resource_type">
          {{ resources.find(el => el.type === resourceType).resource_type.label }}
        </b-col>
      </b-row>
      <resources
        :resources="resources.filter(el => el.format === 'video' && el.type === resourceType)"
        :set-loading="setLoading"
      />
    </div>
  </b-container>
</template>
<script>
import { get } from '../../../services/api';
import Resources from './Resources.vue';

export default {
  name: 'Videos',
  components: {
    Resources,
  },
  props: {
    setLoading: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      resources: [],
    };
  },
  computed: {
    types() {
      return [...new Set(this.resources.map((el) => (el.resource_type.code)))];
    },
  },
  async created() {
    this.setLoading(true);
    await Promise.all([
      this.getResources(),
    ]);
    this.setLoading(false);
  },
  methods: {
    async getResources() {
      await get('results/resources?appendThumbnail=true&appendDocument=true&formats=video', { all: 0 }).then((response) => {
        this.resources = response.data.map((el) => (
          {
            ...el,
            industries: el.industries.map((industry) => industry.roadmap),
          }
        ));
      });
    },
  },
};
</script>
