import { put } from '../../services/api';

export default {
  state: {
    step: parseInt(localStorage.getItem('company-step-register'), 10) || 1,
    prevState: parseInt(localStorage.getItem('company-prevStep-register'), 10) || 1,
    industry: null,
    country: false,
    region: null,
    employees: null,
    turnover: null,
    concept: null,
    causes: null,
    goals: null,
    clusters: null,
    status: null,
    economicSector: null,
  },
  mutations: {
    async setEconomicSector(state, { economicSector, update, quiz }) {
      state.economicSector = economicSector;
      localStorage.setItem('economicSector', economicSector);

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            economic_sector: economicSector,
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            economic_sector: economicSector,
          });
        }
      }
    },
    async setIndustry(state, { industry, update, quiz }) {
      state.industry = industry;
      localStorage.setItem('industry', industry);

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            industry,
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            industry,
          });
        }
      }
    },
    async setCountry(state, { country, update, quiz }) {
      state.country = country;

      localStorage.setItem('country', country);

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            country,
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            country,
          });
        }
      }
    },
    async setRegion(state, { region, update, quiz }) {
      state.region = region;

      localStorage.setItem('region', region);

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            region: region.substr(2),
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            region: region.substr(2),
          });
        }
      }
    },
    async setEmployees(state, { employees, update, quiz }) {
      state.employees = employees;

      localStorage.setItem('employees', employees);

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            employees,
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            employees,
          });
        }
      }
    },
    async setTurnover(state, { turnover, update, quiz }) {
      state.turnover = turnover;

      localStorage.setItem('turnover', turnover);

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            turnover,
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            turnover,
          });
        }
      }
    },
    async setConcept(state, { concept, update, quiz }) {
      state.concept = concept;

      localStorage.setItem('concept', concept);

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            know_the_concept: concept,
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            know_the_concept: concept,
          });
        }
      }
    },
    async setCauses(state, { causes, update, quiz }) {
      state.causes = causes;

      localStorage.setItem('causes', JSON.stringify(causes));

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            causes,
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            causes,
          });
        }
      }
    },
    async setGoals(state, { goals, update, quiz }) {
      state.goals = goals;

      localStorage.setItem('goals', JSON.stringify(goals));

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            goals,
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            goals,
          });
        }
      }
    },
    async setStatus(state, { status, update, quiz }) {
      state.status = status;

      localStorage.setItem('status', JSON.stringify(status));

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            status,
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            status,
          });
        }
      }
    },
    async setClusters(state, { clusters, update, quiz }) {
      state.clusters = clusters;

      localStorage.setItem('clusters', JSON.stringify(clusters));

      if (update) {
        if (quiz !== null) {
          await put(`quiz/company/${quiz}`, {
            clusters,
          });
        } else {
          const companyId = JSON.parse(localStorage.getItem('user')).company_id;
          await put(`/company/${companyId}`, {
            clusters,
          });
        }
      }
    },
    nextState(state) {
      if (state.step < 9) {
        localStorage.setItem('company-prevStep-register', state.step);
        state.prevStep = state.step;
        state.step += 1;
        localStorage.setItem('company-step-register', state.step);
      }
    },
    prevState(state) {
      if (state.step > 1) {
        localStorage.setItem('company-prevStep-register', state.step);
        state.prevStep = state.step;
        state.step -= 1;
        localStorage.setItem('company-step-register', state.step);
      }
    },
    setStep(state, step) {
      state.step = step;
      localStorage.setItem('company-step-register', state.step);
      localStorage.setItem('company-prevStep-register', state.step);
    },
  },
  getters: {
    step: (state) => (state, {
      step: state.step,
    }),
    companyState: (state) => state,
    region: (state) => state.region,
    country: (state) => state.country,
    industry: (state) => state.industry,
    employees: (state) => state.employees,
    turnover: (state) => state.turnover,
    concept: (state) => state.concept,
    causes: (state) => state.causes,
    goals: (state) => state.goals,
    status: (state) => state.status,
    economicSector: (state) => state.economicSector,
    clusters: (state) => state.clusters,
  },
};
