<template>
  <div
    v-if="links && state"
    class="dashboard-content"
    :style="{'margin-top': '0px'}"
  >
    <component
      :is="links.find(e => e.state === state).component"
      :set-state="setState"
      :links="links"
      :state="state"
    />
  </div>
</template>
<script>
import DashboardHeader from './DashboardHeader.vue';

export default {
  name: 'Content',
  components: { DashboardHeader },
  props: {
    state: {
      type: Number,
      default: 1,
    },
    links: {
      type: Array,
      default: null,
    },
    setState: {
      type: Function,
      default: () => {},
    },
    sidebarHeight: {
      type: Number,
      default: 0,
    },
  },

  created() {
    this.links.forEach((link) => {
      this.$options.components[`${link.component}`] = () => import(`./${link.component}.vue`);
    });
  },
};
</script>
