<template>
  <div class="question-container">
    <div>
      <div
        class="title"
      >
        Indique como classifica a sua empresa, por setor económico, de forma a tornar possível, mais tarde, diferenciar os resultados
      </div>
    </div>
    <c-choice
      :choices="choices"
      :not-enabled="$store.state.quiz.disabled"
      :on-click="getChoice"
      :selected="selected"
    />
  </div>
</template>

<script>
import Choice from './Choice.vue';

export default {
  Name: 'Step9',
  components: {
    'c-choice': Choice,
  },
  props: {
    quiz: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selected: this.$store.getters.companyState.economicSector || null,
      choices: [
        { label: 'Agricultura, produção animal, caça e actividades dos serviços relacionados', value: 1 },
        { label: 'Silvicultura e exploração florestal', value: 2 },
        { label: 'Pesca e aquicultura', value: 3 },
        { label: 'Extracção de hulha e lenhite', value: 5 },
        { label: 'Extracção de petróleo bruto e gás natural', value: 6 },
        { label: 'Extracção e preparação de minérios metálicos', value: 7 },
        { label: 'Outras indústrias extractivas', value: 8 },
        { label: 'Actividades dos serviços relacionados com as indústrias extractivas', value: 9 },
        { label: 'Indústrias alimentares', value: 10 },
        { label: 'Indústria das bebidas', value: 11 },
        { label: 'Indústria do tabaco', value: 12 },
        { label: 'Fabricação de têxteis', value: 13 },
        { label: 'Indústria do vestuário', value: 14 },
        { label: 'Indústria do couro e dos produtos do couro', value: 15 },
        { label: 'Indústrias da madeira e da cortiça e suas obras, excepto mobiliário; fabricação de obras de cestaria e de espartaria', value: 16 },
        { label: 'Fabricação de pasta, de papel, cartão e seus artigos', value: 17 },
        { label: 'Impressão e reprodução de suportes gravados', value: 18 },
        { label: 'Fabricação de coque, de produtos petrolíferos refinados e de aglomerados de combustíveis', value: 19 },
        { label: 'Fabricação de produtos químicos e de fibras sintéticas ou artificiais, excepto produtos farmacêuticos', value: 20 },
        { label: 'Fabricação de produtos farmacêuticos de base e de preparações farmacêuticas', value: 21 },
        { label: 'Fabricação de artigos de borracha e de matérias plásticas', value: 22 },
        { label: 'Fabricação de outros produtos minerais não metálicos', value: 23 },
        { label: 'Indústrias metalúrgicas de base', value: 24 },
        { label: 'Fabricação de produtos metálicos, excepto máquinas e equipamentos', value: 25 },
        { label: 'Fabricação de equipamentos informáticos, equipamento para comunicações e produtos electrónicos e ópticos', value: 26 },
        { label: 'Fabricação de equipamento eléctrico', value: 27 },
        { label: 'Fabricação de máquinas e de equipamentos, n.e.', value: 28 },
        { label: 'Fabricação de veículos automóveis, reboques, semi-reboques e componentes para veículos automóveis', value: 29 },
        { label: 'Fabricação de outro equipamento de transporte', value: 30 },
        { label: 'Fabricação de mobiliário e de colchões', value: 31 },
        { label: 'Outras indústrias transformadoras', value: 32 },
        { label: 'Reparação, manutenção e instalação de máquinas e equipamentos', value: 33 },
        { label: 'Electricidade, gás, vapor, água quente e fria e ar frio', value: 35 },
        { label: 'Captação, tratamento e distribuição de água', value: 36 },
        { label: 'Recolha, drenagem e tratamento de águas residuais', value: 37 },
        { label: 'Recolha, tratamento e eliminação de resíduos; valorização de materiais', value: 38 },
        { label: 'Descontaminação e actividades similares', value: 39 },
        { label: 'Promoção imobiliária (desenvolvimento de projectos de edifícios); construção de edifícios', value: 41 },
        { label: 'Engenharia civil', value: 42 },
        { label: 'Actividades especializadas de construção', value: 43 },
        { label: 'Comércio, manutenção e reparação, de veículos automóveis e motociclos', value: 45 },
        { label: 'Comércio por grosso (inclui agentes), excepto de veículos automóveis e motociclos', value: 46 },
        { label: 'Comércio a retalho, excepto de veículos automóveis e motociclos', value: 47 },
        { label: 'Transportes terrestres e transportes por oleodutos ou gasodutos', value: 49 },
        { label: 'Transportes por água', value: 50 },
        { label: 'Transportes aéreos', value: 51 },
        { label: 'Armazenagem e actividades auxiliares dos transportes(inclui manuseamento)', value: 52 },
        { label: 'Actividades postais e de courier', value: 53 },
        { label: 'Alojamento', value: 55 },
        { label: 'Restauração e similares', value: 56 },
        { label: 'Actividades de edição', value: 58 },
        { label: 'Actividades cinematográficas, de vídeo, de produção de programas de televisão, de gravação de som e de edição de música', value: 59 },
        { label: 'Actividades de rádio e de televisão', value: 60 },
        { label: 'Telecomunicações', value: 61 },
        { label: 'Consultoria e programação informática e actividades relacionadas', value: 62 },
        { label: 'Actividades dos serviços de informação', value: 63 },
        { label: 'Actividades de serviços financeiros, excepto seguros e fundos de pensões', value: 64 },
        { label: 'Seguros, resseguros e fundos de pensões, excepto segurança social obrigatória', value: 65 },
        { label: 'Actividades auxiliares de serviços financeiros e dos seguros', value: 66 },
        { label: 'Actividades imobiliárias', value: 68 },
        { label: 'Actividades jurídicas e de contabilidade', value: 69 },
        { label: 'Actividades das sedes sociais e de consultoria para a gestão', value: 70 },
        { label: 'Actividades de arquitectura, de engenharia e técnicas afins; actividades de ensaios e de análises técnicas', value: 71 },
        { label: 'Actividades de Investigação científica e de desenvolvimento', value: 72 },
        { label: 'Publicidade, estudos de mercado e sondagens de opinião', value: 73 },
        { label: 'Outras actividades de consultoria, científicas, técnicas e similares', value: 74 },
        { label: 'Actividades veterinárias', value: 75 },
        { label: 'Actividades de aluguer', value: 77 },
        { label: 'Actividades de emprego', value: 78 },
        { label: 'Agências de viagem, operadores turísticos, outros serviços de reservas e actividades relacionadas', value: 79 },
        { label: 'Actividades de investigação e segurança', value: 80 },
        { label: 'Actividades relacionadas com edifícios, plantação e manutenção de jardins', value: 81 },
        { label: 'Actividades de serviços administrativos e de apoio prestados às empresas', value: 82 },
        { label: 'Administração Pública e Defesa; Segurança Social Obrigatória', value: 84 },
        { label: 'Educação', value: 85 },
        { label: 'Actividades de saúde humana', value: 86 },
        { label: 'Actividades de apoio social com alojamento', value: 87 },
        { label: 'Actividades de apoio social sem alojamento', value: 88 },
        { label: 'Actividades de teatro, de música, de dança e outras actividades artísticas e literárias', value: 90 },
        { label: 'Actividades das bibliotecas, arquivos, museus e outras actividades culturais', value: 91 },
        { label: 'Lotarias e outros jogos de aposta', value: 92 },
        { label: 'Actividades desportivas, de diversão e recreativas', value: 93 },
        { label: 'Actividades das organizações associativas', value: 94 },
        { label: 'Reparação de computadores e de bens de uso pessoal e doméstico', value: 95 },
        { label: 'Outras actividades de serviços pessoais', value: 96 },
        { label: 'Actividades das famílias empregadoras de pessoal doméstico', value: 97 },
        { label: 'Actividades de produção de bens e serviços pelas famílias para uso próprio', value: 98 },
        { label: 'Actividades dos organismos internacionais e outras instituições extraterritoriais', value: 99 },
      ],
    };
  },
  methods: {
    getChoice(value) {
      this.$store.commit('setEconomicSector', { economicSector: value, update: true, quiz: this.quiz });
    },
  },
};
</script>
