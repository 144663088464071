<template>
  <div class="countries-list">
    <div>
      <div
        v-for="country in countries.slice(0,countries.length/3)"
        :key="country.name"
      >
        <div
          class="country"
          @click="setCountry(country.name)"
        >
          <div
            v-if="countrySelected==country.name"
            class="active"
          >
            {{ country.name }} <b-icon-check />
          </div>
          <div v-else>
            {{ country.name }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        v-for="country in countries.slice(countries.length/3, 2*countries.length/3)"
        :key="country.name"
      >
        <div
          class="country"
          @click="setCountry(country.name)"
        >
          <div
            v-if="countrySelected==country.name"
            class="active"
          >
            {{ country.name }} <b-icon-check />
          </div>
          <div v-else>
            {{ country.name }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        v-for="country in countries.slice(2*countries.length/3, countries.length)"
        :key="country.name"
      >
        <div
          class="country"
          @click="setCountry(country.name)"
        >
          <div
            v-if="countrySelected==country.name"
            class="active"
          >
            {{ country.name }} <b-icon-check />
          </div>
          <div v-else>
            {{ country.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countries from 'countries-list';

export default {
  Name: 'Step2',
  props: {
    quiz: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      countries: Object.values(countries.countries),
      countrySelected: this.$store.getters.companyState.country,
    };
  },
  methods: {
    setCountry(name) {
      if (!this.$store.state.quiz.disabled) {
        this.$store.commit('setCountry', { country: name, update: true, quiz: this.quiz });
        this.countrySelected = name;
      }
    },
  },
};
</script>
