<template>
  <div class="question-container">
    <div>
      <div
        class="title"
      >
        Selecione a indústria em que se enquadra
      </div>
    </div>

    <div class="question-responses">
      <div class="step1">
        <c-card
          v-for="(industry, index) in industries"
          :key="index"
          :disabled="$store.state.quiz.disabled"
          :text="industry.text"
          :value="index"
          :icon="industry.icon"
          :quiz="quiz"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue';

export default {
  Name: 'Step1',
  components: {
    'c-card': Card,
  },
  props: {
    quiz: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      industries: {
        1: {
          text: 'Agricultura, produção animal, caça, floresta e pesca',
          icon: 'tractor',
        },
        2: {
          text: 'Indústrias extractivas',
          icon: 'hard-hat',
        },
        3: {
          text: 'Indústrias transformadoras',
          icon: 'industry',
        },
        4: {
          text: 'Electricidade, gás, vapor, água quente e fria e ar frio',
          icon: 'charging-station',
        },
        5: {
          text: 'Captação, tratamento e distribuição de água; saneamento, gestão de resíduos e despoluição',
          icon: 'faucet',
        },
        6: {
          text: 'Construção',
          icon: 'hammer',
        },
        7: {
          text: 'Comércio por grosso e a retalho; reparação de veículos automóveis e motociclos',
          icon: 'wrench',
        },
        8: {
          text: 'Transportes e armazenagem',
          icon: 'truck',
        },
        9: {
          text: 'Alojamento, restauração e similares',
          icon: 'hotel',
        },
        10: {
          text: 'Actividades de informação e de comunicação',
          icon: 'tty',
        },
        11: {
          text: 'Actividades financeiras e de seguros',
          icon: 'piggy-bank',
        },
        12: {
          text: 'Actividades imobiliárias',
          icon: 'home',
        },
        13: {
          text: 'Actividades de consultoria, científicas, técnicas e similares',
          icon: 'flask',
        },
        14: {
          text: 'Actividades administrativas e dos serviços de apoio',
          icon: 'hands-helping',
        },
        15: {
          text: 'Administração Pública e Defesa; Segurança Social Obrigatória',
          icon: 'shield-alt',
        },
        16: {
          text: 'Educação',
          icon: 'university',
        },
        17: {
          text: 'Actividades de saúde humana e apoio social',
          icon: 'heartbeat',
        },
        18: {
          text: 'Actividades artísticas, de espectáculos, desportivas e recreativas',
          icon: 'paint-brush',
        },
        19: {
          text: 'Outras actividades de serviços',
          icon: 'taxi',
        },
        20: {
          text: 'Actividades das famílias empregadoras de pessoal doméstico e actividades de produção das famílias para uso próprio',
          icon: 'users',
        },
        21: {
          text: 'Actividades dos organismos internacionais e outras instituições extra-territoriais',
          icon: 'globe',
        },
      },
    };
  },
};
</script>
