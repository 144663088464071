<template>
  <div class="header">
    <div class="header-brand">
      <div
        class="logo"
        @click="$router.push({name: 'home'})"
      >
        <img src="@logos/s2f.svg">
      </div>
    </div>
    <div class="header-info">
      <span class="title">{{ title }}</span>
      <div
        class="button"
      >
        <b-dropdown
          v-if="$store.state.auth.loggedIn"
          id="dropdown-profile"
          class="navbar-person"
          no-flip
          @click="$router.push({name: 'diagnostic'})"
        >
          <template #button-content>
            <b-icon
              icon="person-circle"
            />
            <div>{{ $store.state.auth.user.name && `Olá, ${$store.state.auth.user.name.split(' ')[0]}!` }}</div>
          </template>
          <b-dropdown-item
            @click="$router.push({name: 'diagnostic'})"
          >
            <b-icon
              class="mr-2"
              icon="building"
            />Área privada
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$store.state.auth.loggedIn"
            class="logout"
            @click="$store.commit('logout')"
          >
            <b-icon
              icon="box-arrow-in-right"
              class="mr-2"
            />Terminar sessão
          </b-dropdown-item>
        </b-dropdown>
      <!--       <div>
        <b-icon
          v-if="$store.state.auth.user"
          icon="person"
        /> Olá, {{ $store.state.auth.user.name.split(" ")[0] }}
        <a
          v-if="$store.state.auth.loggedIn"
          @click="$store.commit('logout')"
        >Terminar sessão</a>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  created() {
    if (!this.$store.state.auth.loggedIn) {
      this.$router.push({ name: 'auth' });
    }
  },
};
</script>
