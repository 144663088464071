import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import company from './modules/company';
import dashboard from './modules/dashboard';
import quiz from './modules/quiz';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    company,
    quiz,
    dashboard,
  },
});

export default store;
