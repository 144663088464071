<template>
  <div
    v-if="show"
    class="footer"
  >
    <b-container>
      <b-row align-v="start">
        <b-col
          cols="6"
          class="logos"
        >
          <img
            src="../../assets/logosfooter.svg"
          >
        </b-col>
        <b-col
          cols="6"
          class="info"
        >
          <div
            class="more mb-1"
            style="font-size: 12px;"
          >
            Mais Informações
          </div>
          <div class="mb-3 mailto">
            <a href="mailto:geral@shift2future.pt">geral@shift2future.pt</a>
          </div>
          <!--  <div class="social-networks">
            <img
              src="../../assets/linkedin.svg"
            >
            <img
              src="../../assets/twitter.svg"
            >
            <img
              src="../../assets/facebook.svg"
            >
            <img
              src="../../assets/instagram.svg"
            >
            <img
              src="../../assets/youtube.svg"
            >
          </div> -->
        </b-col>
      </b-row>
      <div class="divider" />
      <b-row class="copyright-privacy">
        <b-col cols="8">
          <span>Copyright SHIFT2Future © 2021. Todos os direitos reservados | <b><a
            href="https://www.isq.pt/politica-de-privacidade/"
            target="_blank"
          >Utilização e privacidade</a></b></span>
        </b-col>
        <!--  <b-col
          cols="4"
          class="social-media"
        >
          <b-icon icon="linkedin" />
          <b-icon icon="twitter" />
          <b-icon icon="facebook" />
          <b-icon icon="instagram" />
          <b-icon icon="youtube" />
        </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      route: null,
      show: true,
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        this.show = true;
        if (to.path.includes('diagnostico/quiz')) {
          this.show = false;
        }
        if (to.path.includes('interesse')) {
          this.show = false;
        }
        if (to.path.includes('coming_soon')) {
          this.show = false;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.route = this.$router.currentRoute.path;
  },
};
</script>
