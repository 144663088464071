<template>
  <div :style="cssVars">
    <svg
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      width="320"
      :height="height"
      viewBox="0 0 250 320"
    >
      <g>
        <path
          class="cls-2 dimension_1"
          d="M150.594,125.458l.48-.277,74.359-42.931c-20.36-31.447-55.024-52.757-94.789-54.717V113.918c7.947,1.423,14.94,5.612,19.95,11.54Z"
        />
        <path
          class="cls-2 dimension_4"
          d="M98.693,169.279l-.48,.277L23.855,212.488c20.359,31.447,55.024,52.756,94.789,54.716v-86.385c-7.948-1.423-14.94-5.611-19.951-11.54Z"
        />
        <path
          class="cls-2 dimension_5"
          d="M92.676,158.896c-1.306-3.602-2.033-7.479-2.033-11.528s.726-7.926,2.033-11.528l-.463-.268L17.845,92.636c-8.429,16.413-13.201,35.013-13.201,54.732s4.772,38.319,13.201,54.732l74.368-42.937,.463-.268Z"
        />
        <path
          class="cls-2 dimension_3"
          d="M150.594,169.279c-5.01,5.928-12.003,10.117-19.95,11.54v86.385c39.765-1.96,74.429-23.27,94.789-54.716l-74.359-42.931-.48-.277Z"
        />
        <path
          class="cls-2 dimension_2"
          d="M231.443,92.637l-74.369,42.937-.463,.268c1.307,3.602,2.033,7.479,2.033,11.528s-.726,7.925-2.033,11.528l.463,.268,74.369,42.937c8.429-16.413,13.201-35.012,13.201-54.732s-4.772-38.32-13.201-54.732Z"
        />
      </g>
      <path
        class="cls-1 dimension_6"
        d="M0,68.478l98.213,56.703,.48,.277c5.01-5.929,12.003-10.117,19.95-11.54V0C68.701,1.999,25.151,28.823,0,68.478Z"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'Dim1',
  props: {
    height: {
      type: Number,
      default: 200,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        '--color': this.color,
      };
    },
  },
  mounted() {
    const dim1 = document.querySelectorAll('.dimension_1');
    dim1.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 1);
    }));

    const dim2 = document.querySelectorAll('.dimension_2');
    dim2.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 2);
    }));

    const dim3 = document.querySelectorAll('.dimension_3');
    dim3.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 3);
    }));

    const dim4 = document.querySelectorAll('.dimension_4');
    dim4.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 4);
    }));

    const dim5 = document.querySelectorAll('.dimension_5');
    dim5.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 5);
    }));

    const dim6 = document.querySelectorAll('.dimension_6');
    dim6.forEach((tile) => tile.addEventListener('click', () => {
      this.$emit('handleClickDimension', 6);
    }));
  },
};
</script>
<style lang="scss" scoped>
  .cls-1,
  .cls-2 {
    fill: var(--color);
    cursor: pointer;

    &:hover {
      fill: var(--color);
      opacity: .32;
    }
  }

  .cls-1 {
    animation: blinkingFill 2s ease-in-out;
  }

  .cls-3 {
    fill: #A8AAAA;
    cursor: pointer;

    &:hover {
      fill: var(--color);
      opacity: 1;
    }
  }

  .cls-2 {
    opacity: .32;
    cursor: pointer;

    &:hover {
      fill: var(--color);
      opacity: 1;
    }
  }

  @keyframes blinkingFill {
    0%		{ fill: var(--color);}
    25%		{ fill: #A8AAAA;}
    50%		{ fill: var(--color)}
    75%		{ fill: #A8AAAA;}
    100%	{ fill: var(--color)}
  }
</style>
