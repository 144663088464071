<template>
  <b-container
    fluid
    class="resources-container"
  >
    <b-row class="subheader">
      <b-col>
        Vídeos <span class="count">(Total: {{ resources.filter(el => el.format === 'video').length }})</span>
      </b-col>
    </b-row>
    <resources
      custom-class="videos"
      :resources="resources.filter(el => el.format === 'video')"
      :set-loading="setLoading"
    />
    <b-row class="subheader">
      <b-col>
        Documentos <span class="count">(Total: {{ resources.filter(el => el.format === 'document').length }})</span>
      </b-col>
    </b-row>
    <resources
      custom-class="documents"
      :resources="resources.filter(el => el.format === 'document')"
      :set-loading="setLoading"
    />
    <b-row class="subheader">
      <b-col>
        Links <span class="count">(Total: {{ resources.filter(el => el.format === 'link').length }})</span>
      </b-col>
    </b-row>
    <resources
      :resources="resources.filter(el => el.format === 'link')"
      :set-loading="setLoading"
    />
  </b-container>
</template>
<script>
import { get } from '../../../services/api';
import VideoResource from './Video.vue';
import DocumentResource from './Document.vue';
import LinkResource from './Link.vue';
import Resources from './Resources.vue';

export default {
  name: 'AllResources',
  components: {
    VideoResource,
    DocumentResource,
    LinkResource,
    Resources,
  },
  props: {
    setLoading: {
      type: Function,
      default: () => {},
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      resources: [],
    };
  },
  watch: {
    filters(value) {
      this.getResources();
    },
  },
  async created() {
    this.setLoading(true);
    await Promise.all([
      this.getResources(),
    ]);
    this.setLoading(false);
  },
  methods: {
    async getResources() {
      let filters = [];

      Object.keys(this.filters).forEach((filter) => {
        if (this.filters[filter].length > 0) {
          filters.push(`${filter}=${this.filters[filter].join(',')}`);
        }
      });

      filters = filters.join('&');

      await get(`results/resources?appendThumbnail=true&appendDocument=true&${filters}`, { all: 0 }).then((response) => {
        this.resources = response.data.map((el) => (
          {
            ...el,
            industries: el.industries.map((industry) => industry.roadmap),
          }
        ));
      });
    },
  },
};
</script>
