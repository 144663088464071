<template>
  <div
    class="question-container h-100p w-100p"
  >
    <div
      v-if="question || description"
      class="question-header"
    >
      <div
        class="title"
        style="margin-bottom: 100px;"
      >
        {{ question }}
      </div>
      <div
        class="description"
      >
        {{ description }}
      </div>
    </div>
    <div
      v-if="responses"
      class="question-responses question-responses-slider"
      :class="inline ? 'd-flex justify-content-between h-100p' : ''"
    >
      <div
        class="h-100p"
        style="display:flex;"
      >
        <div
          v-if="choices.every(el => el.label)"
          style="flex: 5"
        />
        <div
          style="display: flex; justify-content: space-between; flex-wrap: wrap; align-items: center; flex-direction: row; flex: 7; margin-bottom: 15px; margin-right: 8px;"
        >
          <div
            v-for="(label, value, i) in choices[0].value.label"
            :key="i"
            style="flex: 1; text-align: center;"
          >
            {{ label }}
          </div>
        </div>
      </div>
      <div
        v-for="(choice, index) in choices"
        :key="index"
        class="radio-box-group-slider"
      >
        <div
          v-if="choice.label"
          class="label"
        >
          {{ choice.label }}
        </div>
        <div
          class="choice-radio-box"
        >
          <div
            v-if="selected"
          >
            <c-radio-box
              :selected="selected[index]"
              :index="index"
              :options="choice.value.ids"
              :labels="choice.value.label"
              :on-click="getChoice"
              :not-enabled="notEnabled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioBox from './RadioBox.vue';

export default {
  name: 'RadioBoxGroup',
  components: {
    'c-radio-box': RadioBox,
  },
  props: {
    question: String,
    description: String,
    choices: Array,
    onClick: Function,
    selected: Array,
    notEnabled: Boolean,
    responseGetted: Boolean,
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      responses: this.selected,
      timeout: null,
      preventPOST: false,
    };
  },
  watch: {
    selected(newValue) {
      this.responses = newValue;
      this.preventPOST = true;
    },
  },
  /*  created() {
    if (!this.notEnabled && !this.preventPOST) {
      this.onClick(this.selected);
    }
  }, */
  methods: {
    async getChoice(value, index) {
      this.responses[index] = value;

      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = await setTimeout(async () => {
        this.onClick(this.responses);
      }, 500);
    },
  },

};
</script>
