<template>
  <div
    v-if="!region"
    class="svg-map-container"
  >
    <div>
      <radio-svg-map
        v-if="!$store.state.quiz.disabled || $router.currentRoute.name == 'interest'"
        v-model="regionSelected"
        :map="Ilhas"
        class="ilha"
      />
      <radio-svg-map
        v-else-if="$store.state.quiz.disabled"
        :value="regionSelected"
        :map="Ilhas"
        class="ilha"
      />
    </div>
    <div>
      <radio-svg-map
        v-if="!$store.state.quiz.disabled || $router.currentRoute.name == 'interest'"
        v-model="regionSelected"
        :map="Continente"
      />
      <radio-svg-map
        v-else-if="$store.state.quiz.disabled"
        :value="regionSelected"
        :map="Continente"
      />
    </div>
  </div>
  <div
    v-else
    class="svg-map-container"
  >
    <div>
      <radio-svg-map
        :value="regionSelected"
        :map="Ilhas"
        class="ilha"
      />
    </div>
    <div>
      <radio-svg-map
        :value="regionSelected"
        :map="Continente"
      />
    </div>
  </div>
</template>
<script>

import { RadioSvgMap } from 'vue-svg-map';
import Continente from './continente';
import Ilhas from './ilhas';

export default {
  name: 'Map',
  components: {
    RadioSvgMap,
  },
  props: {
    quiz: {
      type: Number,
      default: null,
    },
    interestFunction: {
      type: Function,
      default: () => {},
    },
    region: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      preventPOST: true,
      Continente,
      Ilhas,
      regions: {
        'R-1': 'Alto Minho',
        'R-2': 'Cávado',
        'R-3': 'Ave',
        'R-4': 'Área Metropolitana do Porto',
        'R-5': 'Alto Tâmega',
        'R-6': 'Tâmega e Sousa',
        'R-7': 'Douro',
        'R-8': 'Terras de Trás-os-Montes',
        'R-9': 'Oeste',
        'R-10': 'Região de Aveiro',
        'R-11': 'Região de Coimbra',
        'R-12': 'Região de Leiria',
        'R-13': 'Viseu Dão Lafões',
        'R-14': 'Beira Baixa',
        'R-15': 'Médio Tejo',
        'R-16': 'Beiras e Serra da Estrela',
        'R-17': 'Área Metroplotinada de Lisboa',
        'R-18': 'Alentejo Litoral',
        'R-19': 'Baixo Alentejo',
        'R-20': 'Lezíria do Tejo',
        'R-21': 'Alto Alentejo',
        'R-22': 'Alentejo Central',
        'R-23': 'Algarve',
        'R-24': 'Região Autónoma dos Açores',
        'R-25': 'Região Autónoma da Madeira',
      },
      regionSelected: this.$store.getters.region,
    };
  },
  watch: {
    regionSelected(newRegion) {
      if (this.quiz) {
        if (!this.preventPOST && newRegion && !this.$store.state.quiz.disabled) {
          this.$store.commit('setRegion', { region: newRegion, update: true, quiz: this.quiz });
        }
      } else if (this.interestFunction) {
        this.interestFunction(newRegion);
      }
    },
    region(value) {
      this.$nextTick().then(() => {
        this.regionSelected = value;
      });
    },
  },
  created() {
    if (this.quiz) {
      this.regionSelected = Object.keys(this.regions).find((key) => key === this.$store.getters.region);
      if (!this.preventPOST && !this.$store.state.quiz.disabled) {
        this.$store.commit('setRegion', { region: this.regionSelected, update: true, quiz: this.quiz });
      }
      this.preventPOST = false;
    } else if (this.region) {
      this.regionSelected = this.region;
    }
  },

};
</script>

<style scoped src="vue-svg-map/dist/index.css"></style>
