<template>
  <div
    v-if="dimensions"
    class="dimensions-sidebar"
  >
    <div
      class="title"
    >
      DIMENSÕES
    </div>
    <div
      v-for="(dimension, index) in getDimensionsKeys()"
      :key="'dim1'+index"
    >
      <div
        v-if="dimension_store == index && responses"
        class="dimension active"
      >
        <div
          @click="navigateTo(index)"
        >
          <div class="dimension-header">
            <span
              class="dimension-title"
            >
              {{ dimensions[dimension].name }}
            </span>
            <span>
              {{ parseInt(question_store)+1 }}/{{ dimensions[dimension]
                .questions
                .filter(el => el.required)
                .length
                + responses.filter(
                  response => dimensions[response.dimension_id] == dimensions[dimension])
                  .map(el => el.question_code)
                  .filter(el => dimensions[dimension].questions.filter(el => !el.required).map(el => el.code)
                    .includes(el))
                  .length }}
            </span>
          </div>
          <div class="description">
            {{ dimensions[dimension].description }}
          </div>
          <div class="buttons justify-content-start mb-0">
            <div
              v-if="(dimension_store === 1 || dimension_store === 3) && !notEnabled"
              class="button danger xs mb-0 align-items-center"
              @click="nextDimension"
            >
              <b-icon
                icon="trash"
                class="mr-2"
              />Ignorar dimensão
            </div>
            <div
              v-if="!notEnabled"
              class="button warning xs mb-0 align-items-center"
              @click="clearResponses"
            >
              <i class="bi bi-eraser mr-2" />
              Limpar respostas
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="responses"
        class="dimension"
      >
        <div
          @click="navigateTo(index)"
        >
          <b-row>
            <b-col
              cols="10"
            >
              {{ dimensions[dimension].name }}
            </b-col>
            <b-col
              cols="2"
            >
              {{ countResponses[dimension-1] }}/{{ dimensions[dimension]
                .questions
                .filter(el => el.required)
                .length +
                + responses.filter(
                  response => dimensions[response.dimension_id] == dimensions[dimension])
                  .map(el => el.question_code)
                  .filter(el => dimensions[dimension].questions.filter(el => !el.required).map(el => el.code)
                    .includes(el))
                  .length }}
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <div
      class="button secondary"
      @click="goToCompanyQuestions"
    >
      Questões gerais
    </div>
    <div
      v-if="!$store.state.quiz.disabled"
      class="button secondary"
      @click="submit"
    >
      Submeter questionário
    </div>
  </div>
</template>

<script>
import { get } from '../../services/api';

export default {
  name: 'DimensionsSideBar',
  props: {
    dimensions: Object,
    navigateTo: Function,
    getDimensionsKeys: Function,
    dimension_store: [Number, String],
    question_store: [Number, String],
    notEnabled: Boolean,
    nextDimension: Function,
    clearResponses: Function,
    submit: Function,
  },
  data() {
    return {
      countResponses: [0, 0, 0, 0, 0, 0],
      responses: null,
    };
  },
  watch: {
    '$store.getters.dimension': async function () {
      await this.getCountResponses();
    },
    '$store.getters.question': async function () {
      await this.getCountResponses();
    },
  },
  async created() {
    await this.$store.dispatch('setDimensions', this.dimensions);
    await this.getCountResponses();
  },
  methods: {
    async getCountResponses() {
      const temp = [0, 0, 0, 0, 0, 0];

      await get('response', {
        quiz: this.$store.getters.quiz,
        count: true,
      }).then((response) => {
        this.responses = [];
        Object.entries(response.data).forEach(([key, value]) => {
          this.responses.push(value[0]);
          temp[value[0].code - 1] += 1;
        });
        this.$store.dispatch('setResponses', this.responses);

        /*  const nonRequired = this.responses.map((el) => el.question_code);
        let total = 0;
        Object.entries(this.dimensions).forEach(([, dimension]) => {
          total += dimension.questions
            .filter((el) => !el.required).map((el) => el.code).filter((el) => nonRequired.includes(el)).length;
        }); */

        // this.$store.commit('setTotalQuestions', this.$store.getters.totalQuestions + total);
      });

      this.countResponses = temp;
    },
    goToCompanyQuestions() {
      const id = this.$store.getters.quiz;
      this.$store.commit('setDisabled', false);
      this.$store.commit('setQuiz', id);
      this.$router.push({
        name: 'company_quiz',
        params: {
          id,
        },
      });
    },
  },
};
</script>
